import { useContext } from 'react'
import { SpecialRateList } from '../../types/types'
import createDataContext from '../createDataContext'

const { Context, Provider } = createDataContext<SpecialRateList[] | undefined>(
  undefined,
)

export const SpecialRateListResultsContextProvider = Provider

export const useContextSpecialRateResults = () => {
  const { state, setState } = useContext(Context)
  return {
    specialRateResults: state,
    setSpecialRateResults: setState,
  }
}
