/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Suspense, useCallback, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom'
import {
  CRMRegistrationUrl,
  carResultsUrl,
  crmRegistrationReferralUrl,
  crmRegistrationSuccessShowQrUrl,
  crmRegistrationSuccessUrl,
  rootUrl,
  routesMap,
} from './routes/routes'
import { GlobalContextProvider } from './context/GlobalContextProvider'
import { useTranslation } from 'react-i18next'
import { useCurrentLanguage } from './hooks/useCurrentLanguage/useCurrentLanguage'
import { useLocalStorage } from './hooks/useLocalStorage/useLocalStorage'
import { LocalStorageKey, WebviewMessageType } from './models/models'
import {
  defaultFormValue,
  FormItem,
} from './hooks/useSurveyFormData/useSurveyFormData'
import {
  getWithExpiry,
  saveLocalStorage,
  setWithExpiry,
} from './utils/localstorageUtils'
import { getSurveyFormData } from 'services/newFunnel'
import { initFacebookPixel } from './helpers/facebookPixel'
import { useGoogleAnalyticsPageView } from './helpers/googleAnalytics/useGoogleAnalyticsPageView'
import { usePageRedirect } from './routes/usePageRedirect/usePageRedirect'
import { LogoutModal } from './components/LogoutModal/LogoutModal'
import { useAddUtmTagsToApiCall } from './hooks/useAddUtmTagsToApiCall/useAddUtmTagsToApiCall'
import { Token, WebviewMessageData } from './types/types'
import getCurrentEnvironment from 'helpers/environments'
import { encryptValue } from 'utils/encryptionUtils'
import { CityFirst } from '_revamp/components/molecules'

declare global {
  interface Window {
    Moengage: any
  }
}

const Routes = () => {
  const location = useLocation()
  const [currentStep, setCurrentStep] = useLocalStorage<number>(
    LocalStorageKey.CurrentStep,
    -1,
  )
  const [, setToken] = useLocalStorage<Token | null>(
    LocalStorageKey.Token,
    null,
  )
  const formData: FormItem | null = getSurveyFormData()
  const enableRedirectPage =
    getCurrentEnvironment.featureToggles.enableRedirectPage

  const handleChunkFileLoadFailure = useCallback((error: any) => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry<string>(LocalStorageKey.ChunkLoadFailed)) {
        setWithExpiry<string>(LocalStorageKey.ChunkLoadFailed, 'true', 10000)
        window.location.reload()
      }
    }
  }, [])

  const handleMessageFromWebview = (
    event: MessageEvent<WebviewMessageData<Token>>,
  ) => {
    const dataFromNative = event.data
    const dataValueFromNative = dataFromNative.value
    if (
      dataFromNative.type === WebviewMessageType.Token &&
      dataValueFromNative.idToken
    ) {
      setToken(dataValueFromNative)
    }
  }

  const removeConsoleProd = () => {
    if (getCurrentEnvironment.name === 'production') {
      console.log = () => {}
      console.error = () => {}
      console.debug = () => {}
    }
  }

  useEffect(() => {
    removeConsoleProd()
    if (!(formData?.age && 'isDataValid' in formData?.age)) {
      saveLocalStorage(
        LocalStorageKey.SurveyForm,
        encryptValue(JSON.stringify(defaultFormValue)),
      )
    }
    if (currentStep >= 8) {
      setCurrentStep(8) // 8 is the last survey form question page index for now
    }
    window.addEventListener('error', handleChunkFileLoadFailure)
    window.addEventListener('message', handleMessageFromWebview)
    return () => {
      window.removeEventListener('error', handleChunkFileLoadFailure)
      window.removeEventListener('message', handleMessageFromWebview)
    }
  }, [])

  const linkTagLoader = () => {
    const linkTagCanonical = document.querySelector('link[rel="canonical"]')
    if (linkTagCanonical && linkTagCanonical instanceof HTMLLinkElement) {
      linkTagCanonical.href = window.location.href
    }

    const linkTagAlternate = document.querySelector('link[rel="alternate"]')
    if (linkTagAlternate && linkTagAlternate instanceof HTMLLinkElement) {
      linkTagAlternate.href = window.location.href
    }
  }

  useEffect(() => {
    if (!location.pathname.includes('mobil-baru')) window.scrollTo(0, 0)
    linkTagLoader()
  }, [location])

  useGoogleAnalyticsPageView()
  usePageRedirect()
  useAddUtmTagsToApiCall()
  return (
    <Suspense fallback={''}>
      <Switch>
        {routesMap.map(({ path, component }, index) => (
          <Route
            path={path}
            key={index}
            component={component}
            exact
            sensitive={false}
          />
        ))}
        {enableRedirectPage && (
          <Redirect from={'/car-results'} to={carResultsUrl} />
        )}
        <Redirect from={'/var/home'} to={rootUrl} />
        <Redirect
          from={'/event/:giiasName/claimticket-form'}
          to={CRMRegistrationUrl}
        />
        <Redirect
          from={'/event/:giiasName/claimticket'}
          to={crmRegistrationReferralUrl}
        />
        <Redirect
          from={'/event/:giiasName/claimticket-success'}
          to={crmRegistrationSuccessUrl}
        />
        <Redirect
          from={'/event/:giiasName/claimticket-viewqr'}
          to={{
            pathname: crmRegistrationSuccessShowQrUrl,
            search: location.search,
          }}
        />
      </Switch>
    </Suspense>
  )
}

// const initHotjar = () => {
//   const hjid = 2532707
//   const hjsv = 6
//   hotjar.initialize(hjid, hjsv)
// }

export const App = () => {
  const { i18n } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useCurrentLanguage()

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
    setCurrentLanguage(currentLanguage)
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      // initHotjar()
      initFacebookPixel()
    }
    // initDatadog()
  }, [])

  return (
    <GlobalContextProvider>
      <Router>
        <CityFirst />
        <Routes />
        <LogoutModal />
      </Router>
    </GlobalContextProvider>
  )
}
