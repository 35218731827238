import React, { ComponentType, FunctionComponent } from 'react'

const PreApprovalStartPage = React.lazy(
  () => import('../pages/PreApprovalStartPage/PreApprovalStartPage'),
)
const PreApprovalQuestionFlow = React.lazy(
  () => import('../pages/PreApprovalQuestionFlow/PreApprovalQuestionFlow'),
)
const EkycPage = React.lazy(() => import('../pages/EKYC/EkycPage'))
const ImagePreviewPage = React.lazy(
  () => import('../pages/ImageUpload/ImagePreviewPage/ImagePreviewPage'),
)
const ImageQualityCheckPage = React.lazy(
  () =>
    import('../pages/ImageUpload/ImageQualityCheckPage/ImageQualityCheckPage'),
)
const OcrSuccessPage = React.lazy(
  () => import('../pages/ImageUpload/SuccessPage/SuccessPage'),
)
const OcrFailurePage = React.lazy(
  () => import('../pages/ImageUpload/FailurePage/FailurePage'),
)

const PreApprovalVerifyKTPPage = React.lazy(
  () => import('../pages/PreApprovalVerifyKTPPage/PreApprovalVerifyKTPPage'),
)
const CameraPage = React.lazy(() => import('../pages/CameraPage/CameraPage'))
const QRScannerPage = React.lazy(
  () => import('pages/QRScannerPage/QRScannerPage'),
)
const BankSelectionPage = React.lazy(
  () => import('../pages/BankSelectionPage/BankSelectionPage'),
)
const EkycFailurePage = React.lazy(() => import('../pages/EKYC/FailurePage'))
const PreApprovalFailurePage = React.lazy(
  () => import('../pages/PreApprovalQuestionFlow/FailurePage'),
)
const PreApprovalConfirmationPage = React.lazy(
  () =>
    import('../pages/PreApprovalConfirmationPage/PreApprovalConfirmationPage'),
)
const BrickLinkFail = React.lazy(
  () => import('../pages/BrickLink/BrickLinkFail'),
)
const BrickLinkCalcRequestPage = React.lazy(
  () => import('../pages/BrickLink/BrickLinkCalcRequestPage'),
)
const BrickLinkSMSSendingPage = React.lazy(
  () => import('../pages/BrickLink/BrickLinkSMSSendingPage'),
)
const EditIncomePage = React.lazy(
  () => import('../pages/EditIncomePage/EditIncomePage'),
)

const ImageCropPage = React.lazy(
  () => import('../pages/ImageCropPage/ImageCropPage'),
)
const HomePageSeva = React.lazy(() => import('_revamp/pages/index'))
const CarResultSeva = React.lazy(() => import('_revamp/pages/mobil-baru/index'))
const NewFunnelLoanCalculatorSevaPage = React.lazy(
  () =>
    import(
      '../pages/NewFunnelLoanCalculatorSevaPage/NewFunnelLoanCalculatorSevaPage'
    ),
)
const NewFunnelLoanCalculatorSevaPageSpecialRate = React.lazy(
  () =>
    import(
      '../pages/NewFunnelLoanCalculatorSevaPage/NewFunnelLoanCalculatorSevaPageSpecialRate'
    ),
)

const LoginPageSeva = React.lazy(() => import('_revamp/pages/masuk-akun'))

const RegisterPageSeva = React.lazy(() => import('_revamp/pages/daftar-akun'))

const LoginThankYouPage = React.lazy(
  () => import('pages/LoginThankYouPage/LoginThankYouPage'),
)

const RegisterThankYouPage = React.lazy(
  () => import('pages/RegisterThankYouPage/RegisterThankYouPage'),
)

const OTPInputPageSeva = React.lazy(
  () => import('../pages/OTPInputPageSeva/OTPInputPageSeva'),
)
const OTPInputPageSevaRegister = React.lazy(
  () => import('../pages/OTPInputPageSeva/OTPInputPageSevaRegister'),
)
const IsAlreadyPreApprovedPageSeva = React.lazy(
  () => import('../pages/IsAlreadyPreApproved/IsAlreadyPreApproved'),
)
const AccountSevaPage = React.lazy(
  () => import('_revamp/pages/akun/profil/index'),
)
const RegisterSalesman = React.lazy(
  () => import('../pages/RegisterSalesman/RegisterSalesman'),
)
const CRMRegistration = React.lazy(
  () => import('_revamp/pages/event/[giiasName]/claimticket-form'),
)
const CrmRegistrationReferralPage = React.lazy(
  () => import('_revamp/pages/event/[giiasName]/claimticket'),
)
const CrmRegistrationSuccessPage = React.lazy(
  () => import('_revamp/pages/event/[giiasName]/claimticket-success'),
)

const CrmCheckinSuccessPage = React.lazy(
  () => import('_revamp/pages/event/[giiasName]/checkin-success'),
)
const CrmCheckin = React.lazy(
  () => import('_revamp/pages/event/[giiasName]/checkin'),
)
const CrmCheckinRegister = React.lazy(
  () => import('_revamp/pages/event/[giiasName]/checkin-form'),
)

const UploadIdSalesman = React.lazy(
  () => import('../pages/RegisterSalesman/components/UploadIdSalesman'),
)
const ImageSalesmanPage = React.lazy(
  () => import('../pages/ImageCropPage/ImageCropPageSalesman'),
)
const RegisterSalsemanSuccessPage = React.lazy(
  () =>
    import('../pages/RegisterSalesmanSuccessPage/RegisterSalesmanSuccessPage'),
)
const RegisterSalsemanSuccessPageSms = React.lazy(
  () =>
    import(
      '../pages/RegisterSalesmanSuccessPage/RegisterSalesmanSuccessPageSms'
    ),
)
const SPKDahsboard = React.lazy(() => import('pages/SPKDashboard/SPKDashboard'))
const NewRegularCalculatorPage = React.lazy(
  () => import('../pages/NewRegularCalculatorPage/NewRegularCalculatorPage'),
)
const SPKSuccess = React.lazy(
  () => import('pages/SPKSuccessPage/SPKSuccessPage'),
)
const SPKForm = React.lazy(() => import('pages/SPKSalesForm/SPKSalesForm'))
const SPKInputPhonePage = React.lazy(
  () => import('pages/SPKInputPhoneNumber/SPKInputPhonePage'),
)
const SpkOtpPage = React.lazy(
  () => import('pages/OTPInputPageSeva/OTPInputPageSevaSpk'),
)
const NewCalculatorGiias = React.lazy(
  () =>
    import(
      'pages/TransactionFlowCalculatorGIIAS/TransactionFlowGIIASCalculator'
    ),
)
const SalesOtpPage = React.lazy(
  () => import('pages/OTPInputPageSeva/OTPInputPageSevaSales'),
)
const VidaTimeoutPage = React.lazy(
  () => import('pages/VidaTimeoutPage/VidaTimeoutPage'),
)
const TemanSevaDashboard = React.lazy(
  () => import('pages/TemanSevaDashboard/TemanSevaDashboard'),
)
const TemanSevaRegisterPage = React.lazy(
  () => import('pages/TemanSevaRegisterPage/TemanSevaRegisterPage'),
)
const TemanSevaRegisterSuccessPage = React.lazy(
  () =>
    import('pages/TemanSevaRegisterSuccessPage/TemanSevaRegisterSuccessPage'),
)
const TemanSevaOnboardingPage = React.lazy(
  () => import('pages/TemanSevaOnboarding/TemanSevaOnboarding'),
)
const CarVariantListPage = React.lazy(
  () => import('_revamp/pages/mobil-baru/[brand]/[variant]'),
)
const SalesDashboardPAPage = React.lazy(
  () => import('pages/SalesDashboardPA/SalesDashboardPA'),
)
const PAAmbassadorFormPage = React.lazy(
  () => import('pages/SalesDashboardPA/PAAFormSpk'),
)
const TemanSevaDashboardScheme1Page = React.lazy(
  () => import('pages/TemanSevaDashboard/CommissionScheme/Scheme1'),
)
const TemanSevaDashboardScheme2Page = React.lazy(
  () => import('pages/TemanSevaDashboard/CommissionScheme/Scheme2'),
)
const TemanSevaDashboardScheme3Page = React.lazy(
  () => import('pages/TemanSevaDashboard/CommissionScheme/Scheme3'),
)
const TemanSevaDashboardScheme4Page = React.lazy(
  () => import('pages/TemanSevaDashboard/CommissionScheme/Scheme4'),
)
const TemanSevaMobilBaruPage = React.lazy(
  () => import('pages/TemanSevaMobilBaru/TemanSevaMobilBaru'),
)
const TemanSevaFasilitasDanaPage = React.lazy(
  () => import('pages/TemanSevaFasilitasDana/TemanSevaFasilitasDana'),
)
const PreApprovalStartExternalPage = React.lazy(
  () => import('pages/PreApprovalStartPage/PreApprovalStartPageExternal'),
)
const PreApprovalQuestionFlowExternalPage = React.lazy(
  () => import('pages/PreApprovalQuestionFlow/PreApprovalQuestionFlowExternal'),
)
const PreApprovalVerifyKTPExternalPage = React.lazy(
  () =>
    import(
      '../pages/PreApprovalVerifyKTPPage/PreApprovalVerifyKTPPageExternal'
    ),
)
const CameraExternalPage = React.lazy(
  () => import('../pages/CameraPage/CameraPageExternal'),
)
const ImageCropExternalPage = React.lazy(
  () => import('../pages/ImageCropPage/ImageCropPageExternal'),
)
const ImageQualityCheckExternalPage = React.lazy(
  () =>
    import(
      '../pages/ImageUpload/ImageQualityCheckPage/ImageQualityCheckPageExternal'
    ),
)
const OcrSuccessExternalPage = React.lazy(
  () => import('../pages/ImageUpload/SuccessPage/SuccessPageExternal'),
)
const OcrFailureExternalPage = React.lazy(
  () => import('../pages/ImageUpload/FailurePage/FailurePageExternal'),
)
const EkycExternalPage = React.lazy(
  () => import('../pages/EKYC/EkycPageExternal'),
)
const PreApprovalFailureExternalPage = React.lazy(
  () => import('../pages/PreApprovalQuestionFlow/FailurePageExternal'),
)
const EkycFailureExternalPage = React.lazy(
  () => import('../pages/EKYC/FailurePageExternal'),
)
const VidaTimeoutExternalPage = React.lazy(
  () => import('pages/VidaTimeoutPage/VidaTimeoutPageExternal'),
)
const PreApprovalSuccessExternalPage = React.lazy(
  () =>
    import(
      'pages/PreApprovalSuccessPageExternal/PreApprovalSuccessPageExternal'
    ),
)
const TemanSevaAccountActivityPage = React.lazy(
  () =>
    import('pages/TemanSevaAccountActivityPage/TemanSevaAccountActivityPage'),
)
const RefinancingPage = React.lazy(
  () => import('pages/RefinancingPage/RefinancingPage'),
)
const RefinancingFormPage = React.lazy(
  () => import('pages/RefinancingFormPage/RefinancingFormPage'),
)
const RefinancingSuccessPage = React.lazy(
  () => import('pages/RefinancingFormPage/Success/SuccessPage'),
)
const RegisterUntungSeru = React.lazy(
  () => import('pages/UntungSeru/RegisterUntungSeru'),
)
const VerifyOTPUntungSeru = React.lazy(
  () => import('pages/UntungSeru/VerifyOTPUntungSeru'),
)
const FormUntungSeru = React.lazy(
  () => import('pages/UntungSeru/FormUntungSeru'),
)
// const FormSuccessUntungSeru = React.lazy(
//   () => import('pages/UntungSeru/FormSuccess'),
// )
const RegisterOLX = React.lazy(
  () => import('_revamp/pages/collaborations/olx-landing/RegisterOLX'),
)
const VerifyOTPOLX = React.lazy(
  () => import('_revamp/pages/collaborations/olx-landing/VerifyOTPOLX'),
)
const FormOLX = React.lazy(
  () => import('_revamp/pages/collaborations/olx-landing/FormOLX'),
)
const FormSuccessOLX = React.lazy(
  () => import('_revamp/pages/collaborations/olx-landing/FormSuccess'),
)
const OfflineLeadsFormPage = React.lazy(
  () => import('pages/OfflineLeadsFormPage/OfflineLeadsFormPage'),
)
const OfflineLeadsSuccessPage = React.lazy(
  () => import('pages/OfflineLeadsSuccessPage/OfflineLeadsSuccessPage'),
)
const PAAmbassadorLinkPage = React.lazy(
  () => import('pages/SalesDashboardPA/PAALink'),
)
const PtbcFormPage = React.lazy(() => import('pages/PtbcFormPage/PtbcFormPage'))
const JumpaPayPage = React.lazy(() => import('pages/JumpaPayPage/JumpaPayPage'))
const PtbcOtpPage = React.lazy(
  () => import('pages/PtbcOtpPage/PtbcOTPInputPage'),
)
const PtbcOtpThankYouPage = React.lazy(
  () => import('pages/PtbcOtpThankYouPage/PtbcOtpThankYouPage'),
)

const OTPLeadsPage = React.lazy(() => import('pages/PromoV2/VerifyOTP'))
const FormLeadsPage = React.lazy(() => import('pages/PromoV2/FormPromo'))
const SuccessLeadsPage = React.lazy(() => import('pages/PromoV2/FormSuccess'))
const OTPPromoPage = React.lazy(() => import('pages/PromoV2/VerifyOTP'))
const FormPromoPage = React.lazy(() => import('pages/Promo/FormPromo'))
const SuccessPromoPage = React.lazy(() => import('pages/Promo/FormSuccess'))
const LoanCalculatorPage = React.lazy(
  () =>
    import(
      '_revamp/pages/kalkulator-kredit/[cityName]/[brand]/[model]/[variant]/index'
    ),
)
const LandingZenixPage = React.lazy(
  () => import('pages/RaffleZenix/FormLanding'),
)
const FormZenixPage = React.lazy(() => import('pages/RaffleZenix/FormPromo'))
const SuccessZenixPage = React.lazy(
  () => import('pages/RaffleZenix/FormSuccess'),
)
const CreditQualificationPage = React.lazy(
  () => import('_revamp/pages/kualifikasi-kredit'),
)
const WaitingCreditQualificationPage = React.lazy(
  () => import('_revamp/pages/kualifikasi-kredit/process'),
)
const CreditQualificationReviewPage = React.lazy(
  () => import('_revamp/pages/kualifikasi-kredit/review'),
)
const SuccessIAPage = React.lazy(
  () => import('_revamp/pages/instant-approval/result/success'),
)
const RejectedIAPage = React.lazy(
  () => import('_revamp/pages/instant-approval/result/rejected'),
)
const DeleteAccountPage = React.lazy(
  () => import('_revamp/pages/akun/profil/hapus-akun/index'),
)
const DeleteAccountReasonPage = React.lazy(
  () => import('_revamp/pages/akun/profil/hapus-akun/alasan/index'),
)
const DeleteAccountSuccessPage = React.lazy(
  () => import('_revamp/pages/akun/profil/hapus-akun/sukses/index'),
)

const LandingKtpPage = React.lazy(
  () => import('_revamp/pages/akun/profil/ktp/index'),
)

const CameraKtpPage = React.lazy(() => import('_revamp/pages/ktp/upload'))

const VerifyKtpPage = React.lazy(() => import('_revamp/pages/ktp/verifikasi'))

const FormKtpPage = React.lazy(() => import('_revamp/pages/ktp/edit'))

const SuccessKtpPage = React.lazy(
  () => import('_revamp/pages/akun/profil/ktp/SuccessPage'),
)
const SuccessChangeKtpPage = React.lazy(
  () => import('_revamp/pages/akun/profil/ktp/SuccessChangeKtpPage'),
)

const PreviewKtpPage = React.lazy(
  () => import('_revamp/pages/akun/profil/ktp/PreviewKtp'),
)

const LeasingCompanyOptionPage = React.lazy(
  () => import('_revamp/pages/instant-approval/perusahaan-pembiayaan'),
)

const CreditQualificationResultPage = React.lazy(
  () => import('_revamp/pages/kualifikasi-kredit/result/index'),
)

const KtpReviewPage = React.lazy(() => import('_revamp/pages/ktp/review/index'))

const InstantApprovalReviewPage = React.lazy(
  () => import('_revamp/pages/instant-approval/review/index'),
)

const GiiasLandingPage = React.lazy(
  () => import('_revamp/pages/collaborations/giias-landing/index'),
)
const RidwanHanifLandingPage = React.lazy(
  () => import('_revamp/pages/collaborations/ridwan-hanif-landing/index'),
)
const MultiCreditQualificationPage = React.lazy(
  () => import('_revamp/pages/kualifikasi-kredit/multi'),
)
const MultiResultCreditQualificationPage = React.lazy(
  () => import('_revamp/pages/kualifikasi-kredit/multi-result'),
)
const NotFoundPage = React.lazy(() => import('_revamp/pages/404/index'))
const InternalServerErrorPage = React.lazy(
  () => import('_revamp/pages/500/index'),
)

export interface PageRoutes {
  path: string
  component: React.LazyExoticComponent<
    ComponentType<FunctionComponent<Record<string, unknown>>>
  >
}
export const rootUrl = '/'
export const homeUrl = '/home'
export const homePhoneUrl = '/home/phone'
export const phoneUrl = '/phone'
export const variantDetailsUrl = '/variant-details/:id'
export const newFunnelVariantDetailsUrl = '/new-variant-details/:id'
export const newFunnelVariantDetailsUrlTorq = '/new-variant-details-torq/:id'
export const otpInputPage = '/otp'
export const appDownloadUrl = '/appDownload'
export const questionsUrl = '/questions'
export const carResultsUrl = '/mobil-baru'
export const carResultsUrlId = '/mobil-baru/:id'
export const variantListOldDesignUrl = '/var/mobil-baru/:brand/:model'
// export const variantListUrlId = '/mobil-baru/:brand/:model/:id/:tab?'
// export const variantListPriceUrl = '/mobil-baru/:brand/:model/:tab'
export const variantListUrl = '/mobil-baru/:brand/:model/:tab?' // variant list page V2
export const loginUrl = '/login'
export const preApprovalStartUrl = '/pre-approval-start'
export const preApprovalStartUrlWithType = '/pre-approval-start/:paFlowType'
export const preApprovalQuestionFlowUrl = '/pre-approval-question-flow'
export const preApprovalQuestionFlowUrlWithType =
  '/pre-approval-question-flow/:paFlowType'
export const basicCheckFailureUrl = '/pre-approval-basic-check'
export const preApprovalFailureUrl = '/pre-approval-check'
export const startEkycUrl = '/ekyc'
export const startEkycUrlWithType = '/ekyc/:paFlowType'
export const preApprovalVerifyKTPUrl = '/pre-approval-verify-ktp'
export const preApprovalVerifyKTPUrlWithType =
  '/pre-approval-verify-ktp/:paFlowType'
export const cameraUrl = '/camera'
export const cameraUrlWithType = '/camera/:paFlowType'
export const qrScannerUrl = '/qr-scanner'
export const imagePreviewUrl = '/image-preview'
export const imageQualityCheckUrl = '/image-quality-check'
export const imageQualityCheckUrlWithType = '/image-quality-check/:paFlowType'
export const ocrSuccessUrl = '/ocr-success'
export const ocrSuccessUrlWithType = '/ocr-success/:paFlowType'
export const ocrFailureUrl = '/ocr-fail'
export const ocrFailureUrlWithType = '/ocr-fail/:paFlowType'
export const bankSelectionUrl = '/bank-selection'
export const bankSelectionUrlWithType = '/bank-selection/:paFlowType'
export const brickLinkOK = '/link-brick-success'
export const brickLinkOKWithType = '/link-brick-success/:paFlowType'
export const brickLinkERR = '/link-brick-fail'
export const preApprovalSMSUrl = '/pre-approval-sms-sending'
export const editIncomePageUrl = '/edit-income'
export const imageCropPageUrl = '/image-crop'
export const imageCropPageUrlWithType = '/image-crop/:paFlowType'
export const preApprovalConfirmationUrl = '/pac/:customerId'
export const preApprovalConfirmationUrlNew = '/pac/new/:leadId'
export const newFunnelLoanCalculatorUrl =
  '/mobil-baru/:brand/:model/:variant/kalkulator-kredit'
export const newFunnelLoanCalculatorUrlSpecialRate =
  '/mobil-baru/:brand/:model/:variant/kalkulator-kredit-promo'
export const contactUsUrl = '/contact-us'
export const termsAndConditions = '/terms-conditions'
export const privacyPolicy = '/privacy-policy'
export const newFunnelLoanCalculatorSevaUrl =
  '/new-funnel-loan-calculator-seva/:id'
export const LoginSevaUrl = '/masuk-akun'
export const RegisterSevaUrl = '/daftar-akun'
export const LoginThankYouUrl = '/masuk-akun/berhasil'
export const RegisterThankYouUrl = '/daftar-akun/berhasil'
export const loginOtpInputPageSevaUrl = '/masuk-akun/otp'
export const registerOtpInputPageSevaUrl = '/daftar-akun/otp'
export const blogUrl = '/blog'
export const AlreadyPreApprovedSevaUrl = '/pre-approval-check-fail'
export const AccountSevaUrl = '/akun/:id'

// GIIAS Routes
export const CRMRegistrationUrl = '/event/:giiasName/claimpromospesial-form'
export const crmRegistrationReferralUrl = '/event/:giiasName/claimpromospesial'
export const crmRegistrationSuccessUrl =
  '/event/:giiasName/claimpromospesial-success'
export const crmRegistrationSuccessShowQrUrl =
  '/event/:giiasName/claimpromospesial-viewqr'
export const RegisterSalesmanSevaUrl = '/event/giiasjkt2023/saregistration'
export const UploadIdSalesmanUrl = '/camera-upload-id'
export const imageSalesmanPageUrl = '/image-salesman'
export const RegisterSalesmanSuccessUrl =
  '/event/giiasjkt2023/saregistration-success'
export const RegisterSalesmanSuccessSmsUrl =
  '/event/giiasjkt2023/saregistration-viewqr'
export const CrmCheckinUrl = '/event/:giiasName/checkin'
export const CrmCheckinRegisterUrl = '/event/:giiasName/checkin-form'
export const CrmCheckinSuccessUrl = '/event/:giiasName/checkin-success/:id'
export const SalesDashboardUrl = '/event/giias/sales-dashboard'
// Url OTP is not used anymore, replaced by Modal OTP
export const CRMCheckInOtpUrl = '/event/giiasjkt2023/checkin-replacement-otp'
export const CRMRegistrationOtpUrl = '/event/giiasjkt2023/claimpromospesial-otp'

export const NewRegularCalculatorUrl =
  '/mobil-baru/:brand/:model/:variant/temp-regular-calculator'
export const SalesSPKForm = '/event/giias/spk-form'
export const SalesSPKSuccessUrl = '/event/giias/spk-success'
export const SpkInputPhonePageUrl = '/event/giias/spk-phone'
export const SpkOtpUrl = '/event/giias/spk-otp'
export const newCalculatorwGiiasUrl =
  '/mobil-baru/:brand/:model/:variant/kalkulator-kredit-giias'
export const registerSalesmanOtpInputPageSevaUrl =
  '/event/giiasjkt2023/saregistration-otp'
export const vidaTimeoutUrl = '/vida-timeout-fail'
// export const HomepageOldDesignUrl = '/var/home'
export const CarResultPageOldDesignUrl = '/var/mobil-baru'
export const TemanSevaDashboardUrl = '/teman-seva/dashboard'
export const TemanSevaRegisterUrl = '/teman-seva/register'
export const TemanSevaRegisterSuccessUrl = '/teman-seva/register-success'
export const TemanSevaOnboardingUrl = '/teman-seva/onboarding'
export const SalesDashboardPAUrl = '/sales-dashboard'
export const PAAmbassadorFormUrl = '/paaform'
export const TemanSevaDashboardScheme1Url = '/teman-seva/dashboard/skema-1'
export const TemanSevaDashboardScheme2Url = '/teman-seva/dashboard/skema-2'
export const TemanSevaDashboardScheme3Url = '/teman-seva/dashboard/skema-3'
export const TemanSevaDashboardScheme4Url = '/teman-seva/dashboard/skema-4'
export const TemanSevaAccountActivityUrl =
  '/teman-seva/dashboard/aktivitas-akun'
export const TemanSevaMobilBaruUrl = '/teman-seva/dashboard/mobil-baru'
export const TemanSevaFasilitasDanaUrl = '/teman-seva/dashboard/fasilitas-dana'

export const PreApprovalStartExternalUrl = '/partner/pre-approval-start/:id'
export const PreApprovalQuestionFlowExternalUrl =
  '/partner/pre-approval-question-flow'
export const preApprovalVerifyKTPExternalUrl =
  '/partner/pre-approval-verify-ktp'
export const cameraExternalUrl = '/partner/camera'
export const imageCropPageExternalUrl = '/partner/image-crop'
export const imageQualityCheckExternalUrl = '/partner/image-quality-check'
export const ocrSuccessExternalUrl = '/partner/ocr-success'
export const ocrFailureExternalUrl = '/partner/ocr-fail'
export const startEkycExternalUrl = '/partner/ekyc'
export const preApprovalSuccessExternalUrl = '/partner/pre-approval-success'
export const basicCheckFailureExternalUrl = '/partner/pre-approval-basic-check'
export const preApprovalFailureExternalUrl = '/partner/pre-approval-check'
export const vidaTimeoutExternalUrl = '/partner/vida-timeout-fail'
export const refinancingUrl = '/fasilitas-dana'
export const refinancingUrlCode = '/fasilitas-dana/:id'
export const refinancingFormUrl = '/fasilitas-dana/form'
export const refinancingSuccessUrl = '/fasilitas-dana/form/success'
export const untungSeruUrlRegister = '/landing-promo'
export const untungSeruUrlVerifyOTP = '/landing-promo/otp'
export const formUntungSeruURL = '/landing-promo/form'
export const formSuccessUntungSeruURL = '/landing-promo/form/success'
export const OLXUrlRegister = '/mobil-baru-olx'
export const OLXUrlVerifyOTP = '/mobil-baru-olx/otp'
export const formOLXURL = '/mobil-baru-olx/form'
export const formSuccessOLXURL = '/mobil-baru-olx/form/success'
export const offlineLeadsFormUrl = '/booth/form'
export const offlineLeadsSuccessUrl = '/booth/form/success'
export const paAmbassadorLinkUrl = '/pa/ia/:orderId'
export const ptbcFormUrl = '/partnership/PTBC'
export const ptbcOtpUrl = '/partnership/PTBC/otp'
export const ptbcOtpThankYouUrl = '/partnership/PTBC/berhasil'
export const jumpaPayUrl = '/layanan-surat-kendaraan'
export const formPromoURL = '/landing-promo-seva'
export const otpPromoURL = '/landing-promo-seva/otp'
export const successPromoURL = '/landing-promo-seva/success'
export const formLeadsURL = '/landing-seva-leads'
export const otpLeadsURL = '/landing-seva-leads/otp'
export const successLeadsURL = '/landing-seva-leads/success'
export const landingZenixURL = '/form/zenix-hybrid'
export const formZenixURL = '/form/zenix-hybrid/start'
export const successZenixURL = '/form/zenix-hybrid/success'
export const loanCalculatorDefaultUrl = '/kalkulator-kredit'
export const loanCalculatorWithCityUrl = '/kalkulator-kredit/:cityName'
export const loanCalculatorWithCityBrandUrl =
  '/kalkulator-kredit/:cityName/:brand'
export const loanCalculatorWithCityBrandModelUrl =
  '/kalkulator-kredit/:cityName/:brand/:model'
export const loanCalculatorWithCityBrandModelVariantUrl =
  '/kalkulator-kredit/:cityName/:brand/:model/:variant'
export const creditQualificationUrl = '/kualifikasi-kredit'
export const waitingCreditQualificationUrl = '/instant-approval/process'
export const successIAUrl = '/instant-approval/result-success'
export const rejectedIAUrl = '/instant-approval/result'
export const creditQualificationUrlSuccess =
  '/kualifikasi-kredit/result-success'
export const creditQualificationUrlRejected = '/kualifikasi-kredit/result' // need to change this endpoint
export const creditQualificationResultUrl = '/kualifikasi-kredit/result'
export const creditQualificationReviewUrl = '/kualifikasi-kredit/review'
export const profileUrl = '/akun/profil'
export const deleteAccountUrl = '/akun/profil/hapus-akun'
export const deleteAccountReasonUrl = '/akun/profil/hapus-akun/alasan'
export const deleteAccountSuccessUrl = '/akun/profil/hapus-akun/sukses'
export const landingKtpUrl = '/akun/profil/landing-ktp'
export const cameraKtpUrl = '/ktp/upload'
export const verifyKtpUrl = '/ktp/verifikasi'
export const formKtpUrl = '/ktp/edit'
export const successKtpUrl = '/akun/profil/ktp/success'
export const successChangeKtpUrl = '/akun/profil/ktp/success-change-ktp'
export const previewKtpUrl = '/akun/profil/ktp/preview'
export const leasingCompanyOption = '/instant-approval/perusahaan-pembiayaan'
export const ktpReviewUrl = '/ktp/review'
export const instantApprovalReviewPage = '/instant-approval/review'

export const uploadKtpSpouseQueryParam = '?ktpType=spouse'
export const giiasLandingPage = '/promo-giias'
export const ridwanHanifLandingPage = '/ridwan-hanif'
export const multiCreditQualificationPageUrl = '/kualifikasi-kredit/multi'
export const multiResultCreditQualificationPageUrl =
  '/kualifikasi-kredit/multi/result'
export const notFoundUrl = '/404'
export const internalServerErrorUrl = '/500'
export const noMatchedPath = '*'

const routesMap: PageRoutes[] = [
  {
    path: rootUrl,
    component: HomePageSeva,
  },
  {
    path: carResultsUrl,
    component: CarResultSeva,
  },
  {
    path: carResultsUrlId,
    component: CarResultSeva,
  },
  {
    path: variantListUrl,
    component: CarVariantListPage, // change to V2 page component
  },
  {
    path: preApprovalStartUrl,
    component: PreApprovalStartPage,
  },
  {
    path: preApprovalStartUrlWithType,
    component: PreApprovalStartPage,
  },
  {
    path: preApprovalQuestionFlowUrl,
    component: PreApprovalQuestionFlow,
  },
  {
    path: preApprovalQuestionFlowUrlWithType,
    component: PreApprovalQuestionFlow,
  },
  {
    path: startEkycUrl,
    component: EkycPage,
  },
  {
    path: startEkycUrlWithType,
    component: EkycPage,
  },
  {
    path: preApprovalVerifyKTPUrl,
    component: PreApprovalVerifyKTPPage,
  },
  {
    path: preApprovalVerifyKTPUrlWithType,
    component: PreApprovalVerifyKTPPage,
  },
  {
    path: cameraUrl,
    component: CameraPage,
  },
  {
    path: cameraUrlWithType,
    component: CameraPage,
  },
  {
    path: qrScannerUrl,
    component: QRScannerPage,
  },
  {
    path: imagePreviewUrl,
    component: ImagePreviewPage,
  },
  {
    path: imageQualityCheckUrl,
    component: ImageQualityCheckPage,
  },
  {
    path: imageQualityCheckUrlWithType,
    component: ImageQualityCheckPage,
  },
  {
    path: ocrSuccessUrl,
    component: OcrSuccessPage,
  },
  {
    path: ocrSuccessUrlWithType,
    component: OcrSuccessPage,
  },
  {
    path: ocrFailureUrl,
    component: OcrFailurePage,
  },
  {
    path: ocrFailureUrlWithType,
    component: OcrFailurePage,
  },
  {
    path: bankSelectionUrl,
    component: BankSelectionPage,
  },
  {
    path: bankSelectionUrlWithType,
    component: BankSelectionPage,
  },
  {
    path: preApprovalConfirmationUrl,
    component: PreApprovalConfirmationPage,
  },
  {
    path: preApprovalConfirmationUrlNew,
    component: PreApprovalConfirmationPage,
  },
  {
    path: preApprovalFailureUrl,
    component: EkycFailurePage,
  },
  {
    path: brickLinkOK,
    component: BrickLinkCalcRequestPage,
  },
  {
    path: brickLinkOKWithType,
    component: BrickLinkCalcRequestPage,
  },
  {
    path: brickLinkERR,
    component: BrickLinkFail,
  },
  {
    path: preApprovalSMSUrl,
    component: BrickLinkSMSSendingPage,
  },
  {
    path: basicCheckFailureUrl,
    component: PreApprovalFailurePage,
  },
  {
    path: editIncomePageUrl,
    component: EditIncomePage,
  },
  {
    path: imageCropPageUrl,
    component: ImageCropPage,
  },
  {
    path: imageCropPageUrlWithType,
    component: ImageCropPage,
  },
  {
    path: newFunnelLoanCalculatorUrl,
    component: NewRegularCalculatorPage,
  },
  {
    path: newFunnelLoanCalculatorUrlSpecialRate,
    component: NewFunnelLoanCalculatorSevaPageSpecialRate,
  },
  {
    path: LoginSevaUrl,
    component: LoginPageSeva,
  },
  {
    path: RegisterSevaUrl,
    component: RegisterPageSeva,
  },
  {
    path: LoginThankYouUrl,
    component: LoginThankYouPage,
  },
  {
    path: RegisterThankYouUrl,
    component: RegisterThankYouPage,
  },
  {
    path: loginOtpInputPageSevaUrl,
    component: OTPInputPageSeva,
  },
  {
    path: registerOtpInputPageSevaUrl,
    component: OTPInputPageSevaRegister,
  },
  {
    path: AlreadyPreApprovedSevaUrl,
    component: IsAlreadyPreApprovedPageSeva,
  },
  {
    path: AccountSevaUrl,
    component: AccountSevaPage,
  },
  {
    path: AlreadyPreApprovedSevaUrl,
    component: IsAlreadyPreApprovedPageSeva,
  },
  {
    path: RegisterSalesmanSevaUrl,
    component: RegisterSalesman,
  },
  {
    path: UploadIdSalesmanUrl,
    component: UploadIdSalesman,
  },
  {
    path: CRMRegistrationUrl,
    component: CRMRegistration,
  },
  {
    path: crmRegistrationReferralUrl,
    component: CrmRegistrationReferralPage,
  },
  {
    path: crmRegistrationSuccessUrl,
    component: CrmRegistrationSuccessPage,
  },
  {
    path: crmRegistrationSuccessShowQrUrl,
    component: CrmRegistrationSuccessPage,
  },
  {
    path: CrmCheckinUrl,
    component: CrmCheckin,
  },
  {
    path: imageSalesmanPageUrl,
    component: ImageSalesmanPage,
  },
  {
    path: RegisterSalesmanSuccessUrl,
    component: RegisterSalsemanSuccessPage,
  },
  {
    path: RegisterSalesmanSuccessSmsUrl,
    component: RegisterSalsemanSuccessPageSms,
  },
  {
    path: CrmCheckinRegisterUrl,
    component: CrmCheckinRegister,
  },
  {
    path: CrmCheckinSuccessUrl,
    component: CrmCheckinSuccessPage,
  },
  // {
  //   path: SalesmanRegistrationSuccess,
  //   component: SalesmanRegistrationSuccessPage,
  // },
  {
    path: SalesDashboardUrl,
    component: SPKDahsboard,
  },
  {
    path: NewRegularCalculatorUrl,
    component: NewFunnelLoanCalculatorSevaPage,
  },
  {
    path: SalesSPKSuccessUrl,
    component: SPKSuccess,
  },
  {
    path: SalesSPKForm,
    component: SPKForm,
  },
  {
    path: SpkInputPhonePageUrl,
    component: SPKInputPhonePage,
  },
  {
    path: SpkOtpUrl,
    component: SpkOtpPage,
  },
  {
    path: newCalculatorwGiiasUrl,
    component: NewCalculatorGiias,
  },
  {
    path: registerSalesmanOtpInputPageSevaUrl,
    component: SalesOtpPage,
  },
  {
    path: vidaTimeoutUrl,
    component: VidaTimeoutPage,
  },
  {
    path: TemanSevaDashboardUrl,
    component: TemanSevaDashboard,
  },
  {
    path: TemanSevaRegisterUrl,
    component: TemanSevaRegisterPage,
  },
  {
    path: TemanSevaRegisterSuccessUrl,
    component: TemanSevaRegisterSuccessPage,
  },
  {
    path: TemanSevaOnboardingUrl,
    component: TemanSevaOnboardingPage,
  },
  {
    path: SalesDashboardPAUrl,
    component: SalesDashboardPAPage,
  },
  {
    path: PAAmbassadorFormUrl,
    component: PAAmbassadorFormPage,
  },
  {
    path: TemanSevaDashboardScheme1Url,
    component: TemanSevaDashboardScheme1Page,
  },
  {
    path: TemanSevaDashboardScheme2Url,
    component: TemanSevaDashboardScheme2Page,
  },
  {
    path: TemanSevaDashboardScheme3Url,
    component: TemanSevaDashboardScheme3Page,
  },
  {
    path: TemanSevaDashboardScheme4Url,
    component: TemanSevaDashboardScheme4Page,
  },
  {
    path: TemanSevaMobilBaruUrl,
    component: TemanSevaMobilBaruPage,
  },
  {
    path: TemanSevaFasilitasDanaUrl,
    component: TemanSevaFasilitasDanaPage,
  },
  {
    path: PreApprovalStartExternalUrl,
    component: PreApprovalStartExternalPage,
  },
  {
    path: PreApprovalQuestionFlowExternalUrl,
    component: PreApprovalQuestionFlowExternalPage,
  },
  {
    path: preApprovalVerifyKTPExternalUrl,
    component: PreApprovalVerifyKTPExternalPage,
  },
  {
    path: cameraExternalUrl,
    component: CameraExternalPage,
  },
  {
    path: imageCropPageExternalUrl,
    component: ImageCropExternalPage,
  },
  {
    path: imageQualityCheckExternalUrl,
    component: ImageQualityCheckExternalPage,
  },
  {
    path: ocrSuccessExternalUrl,
    component: OcrSuccessExternalPage,
  },
  {
    path: ocrFailureExternalUrl,
    component: OcrFailureExternalPage,
  },
  {
    path: startEkycExternalUrl,
    component: EkycExternalPage,
  },
  {
    path: basicCheckFailureExternalUrl,
    component: PreApprovalFailureExternalPage,
  },
  {
    path: preApprovalFailureExternalUrl,
    component: EkycFailureExternalPage,
  },
  {
    path: vidaTimeoutExternalUrl,
    component: VidaTimeoutExternalPage,
  },
  {
    path: preApprovalSuccessExternalUrl,
    component: PreApprovalSuccessExternalPage,
  },
  {
    path: TemanSevaAccountActivityUrl,
    component: TemanSevaAccountActivityPage,
  },
  {
    path: refinancingUrl,
    component: RefinancingPage,
  },
  {
    path: refinancingFormUrl,
    component: RefinancingFormPage,
  },
  {
    path: refinancingSuccessUrl,
    component: RefinancingSuccessPage,
  },
  {
    path: refinancingUrlCode,
    component: RefinancingPage,
  },
  {
    path: offlineLeadsFormUrl,
    component: OfflineLeadsFormPage,
  },
  {
    path: offlineLeadsSuccessUrl,
    component: OfflineLeadsSuccessPage,
  },
  {
    path: paAmbassadorLinkUrl,
    component: PAAmbassadorLinkPage,
  },
  {
    path: untungSeruUrlRegister,
    component: RegisterUntungSeru,
  },
  {
    path: untungSeruUrlVerifyOTP,
    component: VerifyOTPUntungSeru,
  },
  {
    path: formUntungSeruURL,
    component: FormUntungSeru,
  },
  // {
  //   path: formSuccessUntungSeruURL,
  //   component: FormSuccessUntungSeru,
  // },
  {
    path: OLXUrlRegister,
    component: RegisterOLX,
  },
  {
    path: OLXUrlVerifyOTP,
    component: VerifyOTPOLX,
  },
  {
    path: formOLXURL,
    component: FormOLX,
  },
  {
    path: formSuccessOLXURL,
    component: FormSuccessOLX,
  },
  {
    path: ptbcFormUrl,
    component: PtbcFormPage,
  },
  {
    path: ptbcOtpUrl,
    component: PtbcOtpPage,
  },
  {
    path: ptbcOtpThankYouUrl,
    component: PtbcOtpThankYouPage,
  },
  {
    path: jumpaPayUrl,
    component: JumpaPayPage,
  },
  {
    path: formPromoURL,
    component: FormPromoPage,
  },
  {
    path: otpPromoURL,
    component: OTPPromoPage,
  },
  {
    path: successPromoURL,
    component: SuccessPromoPage,
  },
  {
    path: formLeadsURL,
    component: FormLeadsPage,
  },
  {
    path: otpPromoURL,
    component: OTPPromoPage,
  },
  {
    path: otpLeadsURL,
    component: OTPLeadsPage,
  },
  {
    path: successLeadsURL,
    component: SuccessLeadsPage,
  },
  {
    path: loanCalculatorDefaultUrl,
    component: LoanCalculatorPage,
  },
  {
    path: loanCalculatorWithCityUrl,
    component: LoanCalculatorPage,
  },
  {
    path: loanCalculatorWithCityBrandUrl,
    component: LoanCalculatorPage,
  },
  {
    path: loanCalculatorWithCityBrandModelUrl,
    component: LoanCalculatorPage,
  },
  {
    path: loanCalculatorWithCityBrandModelVariantUrl,
    component: LoanCalculatorPage,
  },
  {
    path: landingZenixURL,
    component: LandingZenixPage,
  },
  {
    path: formZenixURL,
    component: FormZenixPage,
  },
  {
    path: successZenixURL,
    component: SuccessZenixPage,
  },
  {
    path: creditQualificationUrl,
    component: CreditQualificationPage,
  },
  {
    path: waitingCreditQualificationUrl,
    component: WaitingCreditQualificationPage,
  },
  // { === change route url before enable this page ===
  //   path: creditQualificationUrlRejected,
  //   component: CreditQualificationPageRejected,
  // },
  {
    path: successIAUrl,
    component: SuccessIAPage,
  },
  {
    path: rejectedIAUrl,
    component: RejectedIAPage,
  },
  {
    path: creditQualificationResultUrl,
    component: CreditQualificationResultPage,
  },
  {
    path: creditQualificationReviewUrl,
    component: CreditQualificationReviewPage,
  },
  {
    path: deleteAccountUrl,
    component: DeleteAccountPage,
  },
  {
    path: deleteAccountReasonUrl,
    component: DeleteAccountReasonPage,
  },
  {
    path: deleteAccountSuccessUrl,
    component: DeleteAccountSuccessPage,
  },
  {
    path: landingKtpUrl,
    component: LandingKtpPage,
  },
  {
    path: cameraKtpUrl,
    component: CameraKtpPage,
  },
  {
    path: verifyKtpUrl,
    component: VerifyKtpPage,
  },
  {
    path: formKtpUrl,
    component: FormKtpPage,
  },
  {
    path: successKtpUrl,
    component: SuccessKtpPage,
  },
  {
    path: previewKtpUrl,
    component: PreviewKtpPage,
  },
  {
    path: successChangeKtpUrl,
    component: SuccessChangeKtpPage,
  },
  {
    path: leasingCompanyOption,
    component: LeasingCompanyOptionPage,
  },
  {
    path: ktpReviewUrl,
    component: KtpReviewPage,
  },
  {
    path: instantApprovalReviewPage,
    component: InstantApprovalReviewPage,
  },
  {
    path: giiasLandingPage,
    component: GiiasLandingPage,
  },
  {
    path: ridwanHanifLandingPage,
    component: RidwanHanifLandingPage,
  },
  {
    path: multiCreditQualificationPageUrl,
    component: MultiCreditQualificationPage,
  },
  {
    path: multiResultCreditQualificationPageUrl,
    component: MultiResultCreditQualificationPage,
  },
  {
    path: notFoundUrl,
    component: NotFoundPage,
  },
  {
    path: internalServerErrorUrl,
    component: InternalServerErrorPage,
  },
  {
    path: noMatchedPath,
    component: NotFoundPage,
  },
]
export { routesMap }
