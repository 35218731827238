import { useContext } from 'react'
import createDataContext from '../createDataContext'

const { Context, Provider } = createDataContext<string | undefined>(undefined)

export const QRScannerContextProvider = Provider

export const useQRScannerContext = () => {
  const { state, setState } = useContext(Context)
  return {
    qrScanner: state,
    setQrScanner: setState,
  }
}
