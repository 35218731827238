import { LocalStorageKey } from '../../models/models'
import { getLocalStorage, saveLocalStorage } from 'utils/localstorageUtils'
import { CityOtrOption } from 'pages/component/CityOtrDropDown/CityOtrDropDown'

export const defaultCity = {
  cityName: 'Jakarta Pusat',
  cityCode: 'jakarta',
  province: 'DKI Jakarta',
  id: '118',
}

export const getCity = () => {
  const cityTemp = localStorage.getItem(LocalStorageKey.CityOtr)

  if (
    cityTemp &&
    (!cityTemp.includes('cityName') || !cityTemp.includes('id'))
  ) {
    saveCity(defaultCity)
    return defaultCity
  } else {
    return (
      getLocalStorage<CityOtrOption>(LocalStorageKey.CityOtr) ?? defaultCity
    )
  }
}

export const getCityWithoutDefault = () => {
  const cityTemp = localStorage.getItem(LocalStorageKey.CityOtr)
  if (
    cityTemp &&
    (!cityTemp.includes('cityName') || !cityTemp.includes('id'))
  ) {
    saveCity(defaultCity)
    return defaultCity
  } else {
    return getLocalStorage<CityOtrOption>(LocalStorageKey.CityOtr)
  }
}

export const saveCity = (city: CityOtrOption) => {
  saveLocalStorage(LocalStorageKey.CityOtr, JSON.stringify(city))
}

export const isCurrentCityJakartaPusatOrSurabaya = () => {
  return (
    getCity().cityName.toLowerCase() === 'jakarta pusat' ||
    getCity().cityName.toLowerCase() === 'surabaya'
  )
}
