import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconSecure: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3796 5.00945C19.3663 4.43309 20.5926 4.40088 21.6106 4.95897C24.3606 6.46664 26.2204 7.39093 29.0379 7.60768C30.7236 7.73736 32.25 9.08894 32.25 10.9557V22.4121C32.25 22.9275 32.1276 23.4438 31.8808 23.911C30.5883 26.3567 29.2999 28.2347 27.6791 29.8916C26.0639 31.5429 24.1615 32.9305 21.6959 34.4397C20.6466 35.082 19.3249 35.0617 18.2955 34.3979C13.4089 31.247 10.8125 28.8344 8.13884 23.9342C7.87872 23.4575 7.75 22.9277 7.75 22.3988V10.9368C7.75 9.08516 9.25708 7.72552 10.9474 7.60054C13.8482 7.38605 15.817 6.50635 18.3796 5.00945ZM20.4087 7.15114C20.1765 7.02384 19.885 7.02539 19.6406 7.16815C16.9215 8.75645 14.5707 9.83945 11.1318 10.0937C10.5879 10.1339 10.25 10.5482 10.25 10.9368V22.3988C10.25 22.5236 10.2805 22.6397 10.3334 22.7368C12.7579 27.1804 15.0171 29.3093 19.6503 32.2968C19.8771 32.4431 20.165 32.4456 20.3907 32.3074C22.7684 30.8521 24.4786 29.5884 25.8919 28.1435C27.2997 26.7043 28.4558 25.0412 29.6704 22.7429C29.7211 22.6471 29.75 22.5334 29.75 22.4121V10.9557C29.75 10.5677 29.4086 10.1436 28.8461 10.1003C25.4577 9.83965 23.1958 8.67908 20.4087 7.15114Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8128 16.0504C25.3373 16.4993 25.3986 17.2883 24.9496 17.8128L19.8141 23.8128C19.59 24.0747 19.2677 24.2323 18.9234 24.2486C18.5791 24.2649 18.2434 24.1383 17.9956 23.8987L15.1312 21.1295C14.6348 20.6496 14.6215 19.8583 15.1013 19.3619C15.5811 18.8656 16.3725 18.8522 16.8688 19.3321L18.7784 21.1782L23.0504 16.1872C23.4993 15.6627 24.2883 15.6014 24.8128 16.0504Z"
        fill={color}
      />
    </svg>
  )
}
