import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconDownPayment: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1433 8.33975C14.646 7.8666 15.4371 7.89058 15.9103 8.39329L19.7579 12.4815L24.1417 8.34123C24.6436 7.86722 25.4348 7.88982 25.9088 8.39172C26.3828 8.89362 26.3602 9.68475 25.8583 10.1588L20.5642 15.1588C20.3229 15.3867 20.0009 15.5092 19.6691 15.4995C19.3374 15.4897 19.0231 15.3484 18.7956 15.1067L14.0898 10.1067C13.6166 9.60399 13.6406 8.8129 14.1433 8.33975Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7338 8.97011C12.1663 9.5082 12.0807 10.295 11.5426 10.7275C9.3657 12.4772 7.78503 14.859 7.01846 17.5447C6.25188 20.2303 6.33715 23.0877 7.26251 25.7228C8.18786 28.358 9.90775 30.6413 12.1851 32.2581C14.4625 33.8749 17.1853 34.7455 19.9782 34.75C22.7712 34.7545 25.4967 33.8926 27.7793 32.2832C30.0619 30.6738 31.7892 28.3961 32.723 25.7639C33.6569 23.1317 33.7513 20.2747 32.9934 17.5865C32.2355 14.8984 30.6625 12.5115 28.4913 10.7548C27.9546 10.3206 27.8715 9.53352 28.3057 8.99683C28.7399 8.46013 29.527 8.37706 30.0637 8.81128C32.6371 10.8933 34.5014 13.7222 35.3996 16.9081C36.2979 20.0941 36.1859 23.4802 35.0791 26.5998C33.9723 29.7194 31.9252 32.419 29.2199 34.3264C26.5147 36.2339 23.2843 37.2553 19.9742 37.25C16.6641 37.2446 13.4371 36.2128 10.7379 34.2966C8.03881 32.3805 6.00043 29.6743 4.90371 26.5511C3.80699 23.428 3.70594 20.0415 4.61447 16.8585C5.52299 13.6755 7.39638 10.8526 9.97645 8.7789C10.5145 8.34641 11.3014 8.43202 11.7338 8.97011Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2C20.6904 2 21.25 2.55964 21.25 3.25V13.25C21.25 13.9404 20.6904 14.5 20 14.5C19.3096 14.5 18.75 13.9404 18.75 13.25V3.25C18.75 2.55964 19.3096 2 20 2Z"
        fill={color}
      />
      <path
        d="M16 16.25H13.1429C12.8398 16.25 12.5491 16.3649 12.3347 16.5695C12.1204 16.7741 12 17.0516 12 17.3409V27.1587C12 27.448 12.1204 27.7255 12.3347 27.93C12.5491 28.1346 12.8398 28.2495 13.1429 28.2495C13.446 28.2495 13.7367 28.1346 13.951 27.93C14.1653 27.7255 14.2857 27.448 14.2857 27.1587V23.8861H15.8651L17.8343 27.6463C17.9014 27.7745 17.9943 27.8889 18.1076 27.9828C18.221 28.0767 18.3527 28.1485 18.4951 28.1938C18.6375 28.2392 18.7879 28.2574 18.9377 28.2473C19.0875 28.2372 19.2337 28.199 19.368 28.135C19.5023 28.071 19.6221 27.9823 19.7205 27.8741C19.819 27.7659 19.8941 27.6402 19.9416 27.5042C19.9892 27.3683 20.0082 27.2248 19.9976 27.0818C19.9871 26.9388 19.9471 26.7993 19.88 26.6711L18.112 23.297C18.8503 22.8602 19.4191 22.2053 19.7321 21.4319C20.0451 20.6585 20.0852 19.8087 19.8463 19.0116C19.6074 18.2145 19.1025 17.5135 18.4084 17.0151C17.7142 16.5167 16.8686 16.2481 16 16.25ZM14.2857 18.4317H16C16.4547 18.4317 16.8907 18.6041 17.2122 18.911C17.5337 19.2179 17.7143 19.6341 17.7143 20.068C17.7143 20.502 17.5337 20.9182 17.2122 21.2251C16.8907 21.5319 16.4547 21.7043 16 21.7043H14.2857V18.4317Z"
        fill={color}
      />
      <path
        d="M25.1431 18.5356H22.2859C21.9828 18.5356 21.6921 18.6506 21.4778 18.8552C21.2635 19.0598 21.1431 19.3372 21.1431 19.6266V29.4447C21.1431 29.7341 21.2635 30.0115 21.4778 30.2161C21.6921 30.4207 21.9828 30.5356 22.2859 30.5356C22.589 30.5356 22.8797 30.4207 23.094 30.2161C23.3084 30.0115 23.4288 29.7341 23.4288 29.4447V26.172H25.1431C26.2039 26.172 27.2213 25.7697 27.9715 25.0537C28.7216 24.3376 29.1431 23.3665 29.1431 22.3538C29.1431 21.3412 28.7216 20.37 27.9715 19.654C27.2213 18.9379 26.2039 18.5356 25.1431 18.5356ZM25.1431 23.9902H23.4288V20.7175H25.1431C25.5977 20.7175 26.0338 20.8899 26.3552 21.1967C26.6767 21.5036 26.8574 21.9198 26.8574 22.3538C26.8574 22.7878 26.6767 23.204 26.3552 23.5109C26.0338 23.8178 25.5977 23.9902 25.1431 23.9902Z"
        fill={color}
      />
    </svg>
  )
}
