import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconIncome: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4401 14.7501H17.4401C16.1667 14.7501 14.2773 15.5082 12.2104 17.7351C10.1783 19.9246 8.16303 23.3535 6.68939 28.1253C5.86696 30.7884 8.01567 33.7501 11.5024 33.7501H29.3778C32.8645 33.7501 35.0132 30.7884 34.1908 28.1253C32.7172 23.3535 30.7019 19.9246 28.6698 17.7351C26.6029 15.5082 24.7135 14.7501 23.4401 14.7501ZM23.4401 12.2501H17.4401C13.0106 12.2501 7.41725 17.2959 4.3007 27.3876C2.88081 31.9854 6.69037 36.2501 11.5024 36.2501H29.3778C34.1898 36.2501 37.9994 31.9854 36.5795 27.3876C33.4629 17.2959 27.8696 12.2501 23.4401 12.2501Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4701 4.46164C11.7923 4.06525 12.3232 3.90478 12.8111 4.05637C15.873 5.0079 18.1747 5.44025 20.4363 5.43224C22.7014 5.42421 25.007 4.97433 28.0811 4.05271C28.5682 3.90668 29.0953 4.07029 29.414 4.46652C29.7328 4.86274 29.7798 5.41258 29.5328 5.85711L25.0884 13.8571C24.7531 14.4606 23.9921 14.678 23.3886 14.3428C22.7851 14.0075 22.5677 13.2465 22.903 12.643L25.9113 7.22797C23.9711 7.68264 22.2176 7.92594 20.4452 7.93222C18.6744 7.9385 16.9219 7.70814 14.9809 7.24958L17.9772 12.643C18.3125 13.2465 18.0951 14.0075 17.4916 14.3428C16.8881 14.678 16.1271 14.4606 15.7918 13.8571L11.3474 5.85711C11.0993 5.41058 11.1479 4.85804 11.4701 4.46164Z"
        fill={color}
      />
      <path
        d="M16.9997 19.0001H14.857C14.6297 19.0001 14.4117 19.0863 14.251 19.2397C14.0902 19.3931 13.9999 19.6012 13.9999 19.8182V27.1816C13.9999 27.3986 14.0902 27.6067 14.251 27.7601C14.4117 27.9135 14.6297 27.9997 14.857 27.9997C15.0843 27.9997 15.3023 27.9135 15.4631 27.7601C15.6238 27.6067 15.7141 27.3986 15.7141 27.1816V24.7271H16.8986L18.3754 27.5473C18.4257 27.6434 18.4953 27.7292 18.5804 27.7997C18.6654 27.8701 18.7641 27.9239 18.871 27.9579C18.9778 27.992 19.0905 28.0056 19.2029 27.998C19.3152 27.9905 19.4248 27.9618 19.5256 27.9138C19.6263 27.8658 19.7162 27.7993 19.79 27.7181C19.8638 27.637 19.9201 27.5427 19.9558 27.4407C19.9914 27.3388 20.0057 27.2311 19.9978 27.1239C19.9899 27.0167 19.9599 26.912 19.9096 26.8159L18.5836 24.2853C19.1373 23.9577 19.5639 23.4666 19.7986 22.8865C20.0334 22.3065 20.0634 21.6691 19.8843 21.0713C19.7051 20.4734 19.3265 19.9477 18.8059 19.5739C18.2853 19.2001 17.6512 18.9986 16.9997 19.0001ZM15.7141 20.6364H16.9997C17.3407 20.6364 17.6677 20.7657 17.9088 20.9958C18.1499 21.226 18.2854 21.5381 18.2854 21.8636C18.2854 22.1891 18.1499 22.5012 17.9088 22.7314C17.6677 22.9615 17.3407 23.0908 16.9997 23.0908H15.7141V20.6364Z"
        fill={color}
      />
      <path
        d="M23.9999 20.0001H21.8571C21.6297 20.0001 21.4117 20.0958 21.251 20.2663C21.0902 20.4368 20.9999 20.668 20.9999 20.9092V29.091C20.9999 29.3321 21.0902 29.5633 21.251 29.7338C21.4117 29.9043 21.6297 30.0001 21.8571 30.0001C22.0844 30.0001 22.3024 29.9043 22.4632 29.7338C22.6239 29.5633 22.7142 29.3321 22.7142 29.091V26.3637H23.9999C24.7956 26.3637 25.5586 26.0285 26.1212 25.4318C26.6838 24.8351 26.9999 24.0257 26.9999 23.1819C26.9999 22.338 26.6838 21.5287 26.1212 20.932C25.5586 20.3353 24.7956 20.0001 23.9999 20.0001ZM23.9999 24.5455H22.7142V21.8182H23.9999C24.3409 21.8182 24.6679 21.9619 24.9091 22.2176C25.1502 22.4734 25.2856 22.8202 25.2856 23.1819C25.2856 23.5435 25.1502 23.8904 24.9091 24.1461C24.6679 24.4018 24.3409 24.5455 23.9999 24.5455Z"
        fill={color}
      />
    </svg>
  )
}
