import React, { HTMLAttributes } from 'react'
import styles from '_revamp/styles/atoms/skeleton.module.scss'
import Shimmer from '_revamp/assets/illustration/placeholder.gif'

type SkeletonProps = {
  width: number | string
  height?: number | string
  className?: string
} & Pick<HTMLAttributes<HTMLImageElement>, 'style'>

export default ({ width, height, className = '', style }: SkeletonProps) => {
  return (
    <img
      src={Shimmer}
      className={`${className} ${styles.skeleton}`}
      width={width}
      height={height}
      alt="placeholder"
      style={{ width, height, ...style }}
    />
  )
}
