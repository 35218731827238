import clsx from 'clsx'
import React from 'react'
import styles from '_revamp/styles/atoms/progressBar.module.scss'

interface ProgressBarProps {
  percentage: number
  colorPrecentage?: string
}

export const ProgressBar = ({
  percentage,
  colorPrecentage,
}: ProgressBarProps) => {
  return (
    <div className={styles.container}>
      <div
        style={{ width: percentage + '%' }}
        className={clsx({
          [styles.indicator]: true,
          [styles.colorPercentage]: colorPrecentage,
        })}
      />
    </div>
  )
}

interface ProgressBarProps {
  percentage: number
}
