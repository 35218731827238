import React, { HTMLAttributes } from 'react'
import { colors } from 'styles/colors'
import { IconPromo } from '_revamp/components/atoms/icon'
import styles from '_revamp/styles/molecules/labelCard/promo/promo.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
  regulerText?: string
  boldText?: string
  carBrand?: string
}

export default ({
  regulerText = 'Tersedia ',
  boldText = 'promo',
  className,
  carBrand,
  ...restProps
}: Props) => {
  return (
    <>
      <div className={`${styles.container} ${className}`} {...restProps}>
        <IconPromo
          height={16}
          width={16}
          color={colors.white}
          alt={`SEVA Promo Icon Car Card ${carBrand?.replace('-', ' ')}`}
        />
        <span className={styles.microReguler}>
          {regulerText}
          <span className={styles.microBold}>{boldText}</span>
        </span>
      </div>
    </>
  )
}
