import { useContext } from 'react'
import patchDataContext from '../patchDataContext/patchDataContext'

export interface WebAnnouncementType {
  isOpen: boolean
}

export const initData = {
  isOpen: true,
}

const { Context, Provider } = patchDataContext<WebAnnouncementType>(initData)

export const WebAnnouncementContextProvider = Provider

export const useWebAnnouncementContext = () => {
  const { state, setState, patchState } = useContext(Context)

  return {
    webAnnouncement: state,
    setWebAnnouncement: setState,
    patchWebAnnouncement: patchState,
  }
}
