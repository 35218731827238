import React, { HTMLAttributes } from 'react'
import { SurveyFormProvider } from './surveyFormContext/surveyFormContext'
import { RecommendationsContextProvider } from './recommendationsContext/recommendationsContext'
import { CarModelDetailsContextProvider } from './carModelDetailsContext/carModelDetailsContext'
import { CarVariantDetailsContextProvider } from './carVariantDetailsContext/carVariantDetailsContext'
import { ContactFormProvider } from './contactFormContext/contactFormContext'
import { LoanPermutationResponseContextProvider } from './loanPermutationContext/loanPermutationContext'
import { LastOtpSentTimeContextProvider } from './lastOtpSentTimeContext/lastOtpSentTimeContext'
import { FunnelQueryContextProvider } from './funnelQueryContext/funnelQueryContext'
import { FinancialQueryContextProvider } from './financialQueryContext/financialQueryContext'
import { CarModelBasicDetailsContextProvider } from './carModelBasicDetailsContext/carModelBasicDetailsContext'
import { FunnelFormContextProvider } from './funnelFormContext/funnelFormContext'
import { FinancialFormContextProvider } from './financialFormContext/financialFormContext'
import { QuestionFlowContextProvider } from './questionFlowContext/questionFlowContext'
import { PopularCarsContextProvider } from './popularCarsContext/popularCarsContext'
import { CurrentLanguageContextProvider } from './currentLanguageContext/currentLanguageContext'
import { GalleryContextProvider } from './galleryContext/galleryContext'
import { CurrenCityOtrContextProvider } from './currentCityOtrContext/currentCityOtrContext'
import { NewLoanPermutationContextProvider } from './newLoanPermutationContext/newLoanPermutationContext'
import { CarModelContextProvider } from './carModelContext/carModelContext'
import { PreApprovalButtonContextProvider } from './preApprovalButtonContext/preApprovalButtonContext'
import { SideMenuContextProvider } from './sideMenuContext/sideMenuContext'
import { SideMenuListContextProvider } from './sideMenuListContext/sideMenuListContext'
import { SpecialRateListResultsContextProvider } from './specialRateResultsContext/specialRateResultsContext'
import { ModalContextProvider } from './modalContext/modalContext'
import { WebAnnouncementContextProvider } from './webAnnouncementContext/webAnnouncementContext'
import { QRScannerContextProvider } from './qrScannerContext/qrScannerContext'
import { MobileWebTopMenusContextProvider } from './mobileWebTopMenuContext/mobileWebTopMenuContext'
import { LastOtpSentTimeContextProviderRevamp } from '_revamp/utils/context/lastOtpSentTimeContext'
import { MultiUnitQueryContextProvider } from './multiUnitQueryContext/multiUnitQueryContext'

const providers: Array<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  React.JSXElementConstructor<React.PropsWithChildren<any>>
> = [
  SurveyFormProvider,
  // LoanCalculatorProvider,
  RecommendationsContextProvider,
  CarModelDetailsContextProvider,
  CarVariantDetailsContextProvider,
  ContactFormProvider,
  LoanPermutationResponseContextProvider,
  LastOtpSentTimeContextProvider,
  FunnelQueryContextProvider,
  FinancialQueryContextProvider,
  CarModelBasicDetailsContextProvider,
  FunnelFormContextProvider,
  FinancialFormContextProvider,
  QuestionFlowContextProvider,
  PopularCarsContextProvider,
  CurrentLanguageContextProvider,
  GalleryContextProvider,
  CurrenCityOtrContextProvider,
  NewLoanPermutationContextProvider,
  CarModelContextProvider,
  PreApprovalButtonContextProvider,
  SideMenuContextProvider,
  SideMenuListContextProvider,
  SpecialRateListResultsContextProvider,
  ModalContextProvider,
  WebAnnouncementContextProvider,
  QRScannerContextProvider,
  MobileWebTopMenusContextProvider,
  LastOtpSentTimeContextProviderRevamp,
  MultiUnitQueryContextProvider,
]

export const GlobalContextProvider = (props: HTMLAttributes<HTMLElement>) => {
  return (
    <>
      {providers.reduceRight((accumulator, Component) => {
        return <Component>{accumulator}</Component>
      }, props.children)}
    </>
  )
}
