import { PropsIcon } from '_revamp/utils/types'
import React from 'react'

const IconQRCodeScanner = ({ height, width, color }: PropsIcon) => {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 6.5V9.5H6.5V6.5H9.5ZM11 5H5V11H11V5ZM8 16V19H5V16H8ZM11 13H5V19H11V13ZM14.5 9.5V12.5H11.5V9.5H14.5ZM19 5H13V11H19V5ZM8.5 17.5H10V19H13V13L8.5 17.5ZM10 19H16V16H14.5V14.5L10 19ZM16 13H17.5V14.5H16V13ZM13 16H14.5V17.5H13V16ZM14.5 17.5H16V19H14.5V17.5ZM16 16H17.5V17.5H16V16ZM17.5 14.5H19V16H17.5V14.5ZM17.5 17.5H19V19H17.5V17.5ZM22 7H20V4H17V2H22V7ZM22 22V17H20V20H17V22H22ZM2 22H7V20H4V17H2V22ZM2 2V7H4V4H7V2H2Z"
        fill={color || 'black'}
      />
    </svg>
  )
}

export default IconQRCodeScanner
