import React, { useState } from 'react'
import styles from '_revamp/styles/atoms/selection.module.scss'
import clsx from 'clsx'
import { SelectionOptionProps, SelectionProps } from '_revamp/utils/types'

const Selection = <TValue extends unknown>({
  title,
  options,
  onChange,
  value,
  allowUnselect = true,
  errorMessage: errorMsg,
}: SelectionProps<TValue>) => {
  const [selected, setSelected] = useState<SelectionOptionProps<TValue> | null>(
    null,
  )

  const controlledInput = {
    value: value === undefined ? selected : value,
    onChange: (
      selected: SelectionOptionProps<TValue> | null,
      value: TValue | null,
    ) => {
      onChange === undefined ? setSelected(selected) : onChange(selected, value)
    },
  }

  return (
    <div className={styles.wrapper}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <div className={styles.selectBoxWrapper}>
        {options.map((option, index) => (
          <button
            className={clsx(styles.selectBox, {
              [styles.selected]: option === controlledInput.value,
              [styles.disabled]: option.isDisabled,
            })}
            type="button"
            key={index}
            onClick={() => {
              if (allowUnselect) {
                if (option === controlledInput.value) {
                  controlledInput.onChange(null, null)
                } else {
                  controlledInput.onChange(option, option.value)
                }
              } else {
                controlledInput.onChange(option, option.value)
              }
            }}
          >
            {option.label}
          </button>
        ))}
      </div>
      {errorMsg ? <div className={styles.errorMsg}>{errorMsg}</div> : null}
    </div>
  )
}

export default Selection
