import React from 'react'
import LogoSeva from '_revamp/assets/images/event/seva-header.svg'
import LogoGIIAS from '_revamp/assets/images/event/logo-giias.svg'
import LogoAstraFinancial from '_revamp/assets/images/event/logo-astra-financial.svg'

const HeaderLogoGIIAS = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '0 16px',
        backgroundColor: '#fff',
      }}
    >
      <img src={LogoSeva} alt="Seva" width={53.59} height={'auto'} />
      <img
        src={LogoAstraFinancial}
        alt="Astra Financial"
        width={76}
        height={'auto'}
      />
      <img src={LogoGIIAS} alt="GIIAS" width={81} height={'auto'} />
    </div>
  )
}

export default HeaderLogoGIIAS
