import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconTime: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0002 6.58337C12.5903 6.58337 6.5835 12.5902 6.5835 20C6.5835 27.4099 12.5903 33.4167 20.0002 33.4167C27.41 33.4167 33.4168 27.4099 33.4168 20C33.4168 12.5902 27.41 6.58337 20.0002 6.58337ZM4.0835 20C4.0835 11.2095 11.2096 4.08337 20.0002 4.08337C28.7907 4.08337 35.9168 11.2095 35.9168 20C35.9168 28.7906 28.7907 35.9167 20.0002 35.9167C11.2096 35.9167 4.0835 28.7906 4.0835 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0002 12.0834C20.6905 12.0834 21.2502 12.643 21.2502 13.3334V20.8156L24.2174 23.7828C24.7055 24.271 24.7055 25.0624 24.2174 25.5506C23.7292 26.0387 22.9378 26.0387 22.4496 25.5506L19.1163 22.2173C18.8819 21.9828 18.7502 21.6649 18.7502 21.3334V13.3334C18.7502 12.643 19.3098 12.0834 20.0002 12.0834Z"
        fill={color}
      />
    </svg>
  )
}
