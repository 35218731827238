import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  SalesDashboardPAUrl,
  TemanSevaDashboardUrl,
  TemanSevaOnboardingUrl,
} from 'routes/routes'
import { temanSevaUrlPath } from 'services/temanseva'
import { CustomerInfoSeva, MobileWebTopMenuType } from 'types/types'
import { trackBurgerMenuClick } from 'helpers/amplitude/seva20Tracking'
import { getToken } from 'utils/api'
import {
  IconAccount,
  IconApplication,
  IconHistory,
  IconWishlist,
} from '_revamp/components/atoms/icon'
import { MenuItem } from '_revamp/components/atoms/menuItem'
import styles from '_revamp/styles/molecules/menuList.module.scss'
import { savePageBeforeProfile } from 'utils/logoutUtils'
import { trackEventCountly } from 'helpers/countly/countly'
import { CountlyEventNames } from 'helpers/countly/eventNames'
import { saveSessionStorage } from 'utils/sessionstorageUtils'
import { SessionStorageKey } from 'models/models'
import { customAxiosGet } from 'utils/customAxiosMethod'

type MenuListProps = {
  menuList?: MobileWebTopMenuType[]
  customerDetail?: CustomerInfoSeva
  enableSalesDashboardButton: boolean
  pageOrigination?: string
}

export const MenuList: React.FC<MenuListProps> = ({
  menuList,
  customerDetail,
  enableSalesDashboardButton,
  pageOrigination,
}): JSX.Element => {
  const [isLogin] = React.useState(!!getToken())
  const [isTemanSeva, setIsTemanSeva] = React.useState(false)
  const history = useHistory()

  const renderIcon = (menuName: string) => {
    if (menuName === 'Akun Saya') {
      return <IconAccount height={20} width={20} color="#246ED4" />
    } else if (menuName === 'Wishlist') {
      return <IconHistory height={20} width={20} color="#246ED4" />
    } else if (menuName === 'Riwayat Pengajuan') {
      return <IconWishlist height={20} width={20} color="#246ED4" />
    }

    return null
  }

  const checkTemanSeva = async () => {
    if (customerDetail) {
      const temanSeva = await customAxiosGet(temanSevaUrlPath.profile, {
        headers: { Authorization: getToken()?.idToken },
      })
      setIsTemanSeva(temanSeva.id ? true : false)
    }
  }

  React.useEffect(() => {
    if (customerDetail) {
      checkTemanSeva()
    }
  }, [customerDetail])

  const handleTemanSeva = () => {
    if (isTemanSeva) {
      history.push(TemanSevaDashboardUrl)
    } else {
      history.push(TemanSevaOnboardingUrl)
    }
  }

  const handleClickMenu = (menuUrl: string, menuName: string) => {
    trackBurgerMenuClick({
      Page_Origination_URL: window.location.href,
      Menu: menuName,
    })

    trackEventCountly(CountlyEventNames.WEB_HAMBURGER_MENU_CLICK, {
      PAGE_ORIGINATION: pageOrigination,
      PAGE_DIRECTION_URL: menuUrl.includes('www')
        ? menuUrl
        : 'https://' + window.location.hostname + menuUrl,
    })
    if (menuName === 'Teman SEVA') {
      handleTemanSeva()
    } else {
      if (menuUrl === '/akun/profil') {
        savePageBeforeProfile(window.location.pathname)
        trackEventCountly(CountlyEventNames.WEB_HAMBURGER_ACCOUNT_CLICK, {
          PAGE_ORIGINATION: pageOrigination,
          SOURCE_SECTION: 'Bottom',
        })
        if (pageOrigination) {
          saveSessionStorage(
            SessionStorageKey.PageReferrerProfilePage,
            pageOrigination,
          )
        }
      }
      window.location.href = menuUrl
    }
  }

  return (
    <div className={styles.container}>
      {menuList?.map((menuItem) => {
        if (menuItem.menuName === 'Akun' && !isLogin) {
          return null
        } else {
          return (
            <div className={styles.menuContainer} key={menuItem.menuName}>
              {menuItem.menuLevel === 1 && (
                <div className={styles.menuWrapper}>
                  <p className={styles.mainMenu}>{menuItem.menuName}</p>
                </div>
              )}

              {menuItem.subMenu.length > 0 &&
                menuItem.subMenu.map((sub) => {
                  if (sub.subMenu.length > 0) {
                    return (
                      <MenuItem item={sub} pageOrigination={pageOrigination} />
                    )
                  } else {
                    const icon = renderIcon(sub.menuName)
                    return (
                      <div
                        className={styles.parentMenu}
                        onClick={() =>
                          handleClickMenu(sub.menuUrl as string, sub.menuName)
                        }
                      >
                        {icon && (
                          <div className={styles.iconContainer}>{icon}</div>
                        )}
                        <div className={styles.menu}>{sub.menuName}</div>
                      </div>
                    )
                  }
                })}

              {menuItem.menuName === 'Akun' && enableSalesDashboardButton && (
                <div
                  className={styles.parentMenu}
                  onClick={() =>
                    handleClickMenu(SalesDashboardPAUrl, 'Sales Dashboard')
                  }
                >
                  <div className={styles.iconContainer}>
                    <IconApplication
                      height={20}
                      width={20}
                      color="#246ED4"
                      className={styles.salesDashboardIcon}
                    />
                  </div>
                  <div className={styles.menu}>Sales Dashboard</div>
                </div>
              )}
            </div>
          )
        }
      })}
    </div>
  )
}
