import React from 'react'
import styles from '../../../styles/atoms/tabV2.module.scss'
import { TabItemProps } from '_revamp/utils/types/props'

export const TabV2 = ({
  label,
  value,
  isActive,
  onClickHandler,
  dataTestId,
  onPage,
}: TabItemProps) => {
  const renderLabel = () => {
    if (typeof label === 'string') {
      return label
    } else {
      const alt = label.props.children[0].props.alt
      const updatedAlt = `Logo ${alt} Rekomendasi`
      return (
        <div className={styles.tabBrand}>
          {React.cloneElement(label.props.children[0], {
            alt: updatedAlt,
          })}
          {alt}
        </div>
      )
    }
  }
  return onPage === 'PDP' ? (
    <div
      className={`${styles.container} ${isActive && styles.containerActive}`}
      role="button"
      onClick={() => onClickHandler && onClickHandler(value)}
      data-testid={dataTestId}
    >
      <h3 className={`${styles.text} ${isActive && styles.textActive}`}>
        {renderLabel()}
      </h3>
    </div>
  ) : (
    <div
      className={`${styles.containerTabV2LC} ${
        isActive && styles.containerActive
      }`}
      role="button"
      onClick={() => onClickHandler && onClickHandler(value)}
      data-testid={dataTestId}
    >
      <h3 className={`${styles.text} ${isActive && styles.textActive}`}>
        {label}
      </h3>
    </div>
  )
}
