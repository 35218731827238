import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconLockFill: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#52627A',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11948_423794)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 4.66663C3.5 3.73837 3.86875 2.84813 4.52513 2.19175C5.1815 1.53537 6.07174 1.16663 7 1.16663C7.92826 1.16663 8.8185 1.53537 9.47487 2.19175C10.1313 2.84813 10.5 3.73837 10.5 4.66663H11.0833C11.3928 4.66663 11.6895 4.78954 11.9083 5.00834C12.1271 5.22713 12.25 5.52387 12.25 5.83329V11.6666C12.25 11.976 12.1271 12.2728 11.9083 12.4916C11.6895 12.7104 11.3928 12.8333 11.0833 12.8333H2.91667C2.60725 12.8333 2.3105 12.7104 2.09171 12.4916C1.87292 12.2728 1.75 11.976 1.75 11.6666V5.83329C1.75 5.52387 1.87292 5.22713 2.09171 5.00834C2.3105 4.78954 2.60725 4.66663 2.91667 4.66663H3.5ZM7 2.33329C7.61884 2.33329 8.21233 2.57913 8.64992 3.01671C9.0875 3.4543 9.33333 4.04779 9.33333 4.66663H4.66667C4.66667 4.04779 4.9125 3.4543 5.35008 3.01671C5.78767 2.57913 6.38116 2.33329 7 2.33329ZM8.16667 8.16663C8.16666 8.37141 8.11275 8.57259 8.01036 8.74994C7.90796 8.92729 7.76068 9.07457 7.58333 9.17696V9.91663C7.58333 10.0713 7.52187 10.2197 7.41248 10.3291C7.30308 10.4385 7.15471 10.5 7 10.5C6.84529 10.5 6.69692 10.4385 6.58752 10.3291C6.47812 10.2197 6.41667 10.0713 6.41667 9.91663V9.17696C6.19427 9.04854 6.02045 8.85033 5.92218 8.61306C5.82391 8.37579 5.80667 8.11273 5.87314 7.86467C5.93961 7.6166 6.08607 7.3974 6.28981 7.24106C6.49355 7.08472 6.74319 6.99997 7 6.99996C7.30942 6.99996 7.60617 7.12288 7.82496 7.34167C8.04375 7.56046 8.16667 7.85721 8.16667 8.16663Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11948_423794">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
