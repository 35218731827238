import React, { ButtonHTMLAttributes } from 'react'
import styles from '_revamp/styles/atoms/button.module.scss'
import { IconLoading } from '../icon'
import { colors } from 'styles/colors'
import clsx from 'clsx'

export enum ButtonVersion {
  Secondary = 'Secondary',
  SecondaryDark = 'SecondaryDark',
  PrimaryDarkBlue = 'PrimaryDarkBlue',
  Outline = 'Outline',
  Disable = 'Disable',
  Default = 'Default',
}

export enum ButtonSize {
  Big = 'Big',
  Small = 'Small',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  version: ButtonVersion
  size: ButtonSize
  secondaryClassName?: any
  loading?: boolean
}

export default ({
  version,
  size = ButtonSize.Big,
  children,
  secondaryClassName,
  loading,
  ...props
}: ButtonProps) => {
  const buttonVersion = () => {
    switch (version) {
      case ButtonVersion.Secondary:
        return styles.secondary
      case ButtonVersion.SecondaryDark:
        return styles.secondaryDark
      case ButtonVersion.PrimaryDarkBlue:
        return styles.primaryDarkBlue
      case ButtonVersion.Outline:
        return styles.outline
      case ButtonVersion.Disable:
        return styles.disable
      case ButtonVersion.Default:
        return styles.default
      default:
        return styles.secondary
    }
  }

  const buttonSize = size === ButtonSize.Big ? styles.big : styles.small

  const loadingColor =
    version === ButtonVersion.Secondary ? colors.primaryDarkBlue : colors.white

  return (
    <button
      disabled={loading || buttonVersion() === ButtonVersion.Disable}
      className={clsx(buttonVersion(), buttonSize, secondaryClassName)}
      {...props}
    >
      {loading ? (
        <div className={clsx(styles.iconLoading)}>
          <IconLoading width={14} height={14} color={loadingColor} />
        </div>
      ) : (
        children
      )}
    </button>
  )
}
