import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconFuel: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  alt,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        d="M22.5342 36H5.83376C4.82307 36 4 35.1823 4 34.1716V6.82841C4 5.82067 4.82005 5 5.83376 5H22.5342C23.5448 5 24.3679 5.81766 24.3679 6.82841V34.1716C24.3649 35.1808 23.5433 36 22.5342 36ZM6.26951 33.7371H22.0953V7.26443H6.26951V33.7371Z"
        fill={color}
      />
      <path
        d="M20.2188 19.3796H8.14508V9.13653H20.2188V19.3796ZM10.4148 17.1168H17.9495V11.3991H10.4148V17.1168Z"
        fill={color}
      />
      <path
        d="M31.757 5.53095L35.4608 12.0874L33.4833 13.1977L29.7795 6.64127L31.757 5.53095Z"
        fill={color}
      />
      <path
        d="M30.9918 33.0808C28.2457 33.0808 26.0112 30.8511 26.0112 28.1147V21.703C26.0112 21.0573 25.5088 20.5535 24.8689 20.5535L23.2106 20.552V18.2891H24.8673C25.7873 18.2891 26.6451 18.6436 27.2881 19.2877C27.9266 19.9289 28.2837 20.7888 28.2837 21.7015V28.1131C28.2837 29.6036 29.5016 30.8165 30.995 30.8165C31.7333 30.8165 32.4475 30.5269 32.9498 30.0245C33.4521 29.5236 33.729 28.8448 33.729 28.1116L33.7305 13.8389H36V28.1132C36 29.4528 35.4886 30.6959 34.5596 31.6252C33.6276 32.5499 32.3263 33.0808 30.9918 33.0808Z"
        fill={color}
      />
      <path
        d="M32.7728 17.0474C30.9995 17.0474 29.5531 15.6067 29.5531 13.8371C29.5531 12.0691 30.998 10.6268 32.7728 10.6268C33.8167 10.6268 34.7972 11.1322 35.4024 11.9815C35.531 12.1596 35.6414 12.3557 35.7277 12.5578C35.9062 12.9682 36 13.3996 36 13.8371C35.9955 15.6082 34.5505 17.0474 32.7728 17.0474ZM32.7728 12.8911C32.2508 12.8911 31.8226 13.3166 31.8226 13.8385C31.8226 14.359 32.2493 14.7859 32.7728 14.7859C33.2993 14.7859 33.7259 14.3604 33.7259 13.8385C33.7259 13.7148 33.6987 13.5896 33.6427 13.4614C33.6155 13.401 33.5852 13.3437 33.5489 13.2954C33.3674 13.042 33.0799 12.8911 32.7728 12.8911Z"
        fill={color}
      />
      <path
        d="M14.1703 32.3536C11.7375 32.3536 9.7556 30.3803 9.7556 27.9517C9.7556 26.5457 10.4289 25.0884 11.7043 23.7429C12.6091 22.7895 13.5169 22.2041 13.5532 22.1815L14.2038 21.7666L14.8317 22.2192C14.8695 22.2464 15.7546 22.8906 16.6442 23.8636C17.9273 25.2696 18.5779 26.6469 18.5779 27.9519C18.5854 30.3777 16.6033 32.3536 14.1703 32.3536ZM14.1371 24.5647C13.2414 25.307 12.0264 26.6134 12.0264 27.9515C12.0264 29.1282 12.9872 30.0907 14.1719 30.0907C15.3566 30.0907 16.3173 29.1327 16.3173 27.9515C16.3158 26.7597 15.0403 25.3688 14.1371 24.5647Z"
        fill={color}
      />
    </svg>
  )
}
