import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconQuestion: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9999 32.4242C26.8617 32.4242 32.4242 26.8617 32.4242 19.9999C32.4242 13.1382 26.8617 7.57568 19.9999 7.57568C13.1382 7.57568 7.57568 13.1382 7.57568 19.9999C7.57568 26.8617 13.1382 32.4242 19.9999 32.4242ZM19.9999 34.9242C28.2424 34.9242 34.9242 28.2424 34.9242 19.9999C34.9242 11.7575 28.2424 5.07568 19.9999 5.07568C11.7575 5.07568 5.07568 11.7575 5.07568 19.9999C5.07568 28.2424 11.7575 34.9242 19.9999 34.9242Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9913 14.5552C17.5769 15.2244 17.4611 16.1521 17.5154 16.8158C17.5718 17.5038 17.0597 18.1073 16.3717 18.1637C15.6836 18.22 15.0801 17.7079 15.0238 17.0199C14.9422 16.0247 15.0795 14.5088 15.8658 13.239C16.2718 12.5834 16.8583 11.9843 17.673 11.5883C18.4867 11.1928 19.4504 11.0375 20.5529 11.1457L20.6135 11.1517L20.6733 11.1635C21.3953 11.3063 22.3736 11.6813 23.2102 12.3835C24.0738 13.1084 24.8094 14.209 24.875 15.7228C24.9145 16.635 24.5776 17.4738 24.0661 18.1165C23.7602 18.5009 23.3964 18.8974 23.0566 19.2677C23.0113 19.317 22.9665 19.3659 22.9223 19.4142C22.5299 19.8429 22.1649 20.2502 21.8544 20.6621C21.2096 21.5175 20.9895 22.1483 21.0889 22.688C21.214 23.367 20.765 24.0187 20.0861 24.1438C19.4071 24.2689 18.7554 23.8199 18.6303 23.1409C18.3338 21.5313 19.1024 20.1597 19.8581 19.1573C20.2479 18.6402 20.6861 18.1546 21.0781 17.7263C21.1242 17.676 21.1694 17.6266 21.2139 17.5781C21.5634 17.1969 21.8627 16.8704 22.11 16.5597C22.3052 16.3144 22.3869 16.0527 22.3773 15.831C22.3477 15.1468 22.0373 14.663 21.6029 14.2984C21.1637 13.9297 20.6245 13.713 20.2446 13.6279C19.5547 13.5693 19.0865 13.6809 18.7659 13.8367C18.4365 13.9969 18.1857 14.2412 17.9913 14.5552Z"
        fill={color}
      />
      <path
        d="M21.3247 26.2544C21.3247 27.0183 20.7054 27.6376 19.9415 27.6376C19.1775 27.6376 18.5582 27.0183 18.5582 26.2544C18.5582 25.4905 19.1775 24.8712 19.9415 24.8712C20.7054 24.8712 21.3247 25.4905 21.3247 26.2544Z"
        fill={color}
      />
    </svg>
  )
}
