import elementId from 'helpers/elementIds'
import React from 'react'
import { IconLockFill, IconACC } from '_revamp/components/atoms/icon'
import styles from '_revamp/styles/organism/landingIA.module.scss'
import ISOIcon from '_revamp/assets/icon/iso.webp'
import AstraFinancialIcon from '_revamp/assets/icon/Logo-Astra-Financial.webp'
import TAFIcon from '_revamp/assets/icon/Logo-TAF.png'

export const FooterStakeholder = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.iconWrapper} style={{ gap: 4 }}>
        <IconLockFill width={10} height={10} />
        <span>Kami menjamin datamu aman dan terlindungi</span>
      </div>
      <div className={styles.iconWrapper}>
        <img
          src={ISOIcon}
          width={26}
          height={27}
          alt="CBQA ISO 27001"
          datatest-id={elementId.Footer.LogoISO}
        />
        <IconACC width={40} height={40} />
        <img src={TAFIcon} width={40} height={40} alt="TAF" />
        <img
          src={AstraFinancialIcon}
          width={114}
          height={40}
          alt="Astra Financial"
        />
      </div>
    </div>
  )
}
