import { API } from '../utils/api'
import endpoints from '../helpers/endpoints'
import { FunnelQuery } from '../context/funnelQueryContext/funnelQueryContext'
import { AxiosRequestConfig } from 'axios'
import { LocalStorageKey, PaymentType, QueryKeys } from '../models/models'
import {
  CustomerRegister,
  FinalDpValidationPayload,
  LoanCalculatorAsuransiKombinasiPayloadType,
  LoanCalculatorIncludePromoPayloadType,
  NewFunnelLoanPermutationsRequest,
  SpecialRateRequest,
} from '../types/types'
import { getCity } from 'hooks/useCurrentCityOtr/useCurrentCityOtr'
import {
  decryptValue,
  encryptedPrefix,
  encryptValue,
} from 'utils/encryptionUtils'
import { defaultFormValue } from 'hooks/useSurveyFormData/useSurveyFormData'

export const getSurveyFormData = () => {
  let item = localStorage.getItem(LocalStorageKey.SurveyForm)
  if (item?.includes(encryptedPrefix)) {
    item = decryptValue(item)
  }

  // if decryption failed, overwrite existing data with default value
  if (item === '') {
    localStorage.setItem(
      LocalStorageKey.SurveyForm,
      encryptValue(JSON.stringify(defaultFormValue)),
    )
    return defaultFormValue
  }

  return item ? JSON.parse(item) : ''
}

export const getNewFunnelRecommendations = (
  funnelQuery: FunnelQuery,
  surveyForm = false,
  useKeySearch = true,
) => {
  const params = new URLSearchParams()
  const {
    downPaymentAmount,
    downPaymentPercentage,
    monthlyInstallment,
    downPaymentType,
    brand,
    bodyType,
    sortBy,
    carModel,
    monthlyIncome,
    tenure,
    age,
    priceRangeGroup,
  } = funnelQuery

  if (surveyForm) {
    const surveyFormData = getSurveyFormData()
    const totalIncome = surveyFormData?.totalIncome?.value
    const age = surveyFormData?.age?.value
    totalIncome && params.append('monthlyIncome', totalIncome as string)
    age && params.append('age', age as string)
  }
  const isDpSelected = downPaymentAmount || downPaymentPercentage
  const isMonthlySelected = monthlyInstallment
  if (isDpSelected) {
    params.append('recommendationType', PaymentType.DownPayment)
    params.append('dpType', downPaymentType as string)
    downPaymentAmount &&
      params.append('maxDpAmount', downPaymentAmount as string)
    downPaymentPercentage &&
      params.append('maxDpPercentage', downPaymentPercentage as string)
  }
  if (isMonthlySelected) {
    params.append('recommendationType', PaymentType.MonthlyInstallment)
    params.append('maxMonthlyInstallment', monthlyInstallment as string)
  }
  if (funnelQuery.paymentType === 'carModel' && useKeySearch === true) {
    params.append('search', carModel as string)
  }
  brand && brand.length > 0 && params.append('brand', brand.join('/'))
  bodyType &&
    bodyType.length > 0 &&
    params.append('bodyType', bodyType.join('/'))

  sortBy && params.append('sortBy', sortBy as string)

  monthlyIncome && params.append('monthlyIncome', monthlyIncome as string)
  age && params.append('age', age as string)
  tenure && params.append('tenure', tenure as string)
  priceRangeGroup && params.append('priceRangeGroup', priceRangeGroup as string)

  getCity().cityCode && params.append('city', getCity().cityCode as string)
  getCity().id && params.append('cityId', getCity().id as string)

  return API.get(endpoints.newFunnelRecommendation, { params })
}

export const getNewFunnelRecommendationsByQueries = (
  {
    bodyType,
    brand,
  }: {
    bodyType?: string[]
    brand?: string[]
  },
  config?: AxiosRequestConfig,
) => {
  const params = new URLSearchParams()
  bodyType && params.append(QueryKeys.CarBodyType, bodyType.join('/'))
  brand && params.append(QueryKeys.CarBrand, brand.join('/'))
  getCity().cityCode && params.append('city', getCity().cityCode as string)
  getCity().id && params.append('cityId', getCity().id as string)

  return API.get(endpoints.newFunnelRecommendation, {
    ...config,
    params,
  })
}

export const getNewFunnelCityRecommendations = (
  data: {
    modelName: string
    city: string
  },
  config?: AxiosRequestConfig,
) => {
  return API.post(endpoints.newFunnelCityRecommendation, data, config)
}

export const getNewFunnelAllRecommendations = (
  config?: AxiosRequestConfig,
  customCity?: string,
  surveyForm = true,
) => {
  const params = new URLSearchParams()
  if (surveyForm) {
    const surveyFormData = getSurveyFormData()
    const totalIncome = surveyFormData?.totalIncome?.value
    const age = surveyFormData?.age?.value
    totalIncome && params.append('monthlyIncome', totalIncome as string)
    age && params.append('age', age as string)
  }

  getCity().cityCode && params.append('city', getCity().cityCode as string)
  getCity().id && params.append('cityId', getCity().id as string)
  if (customCity) {
    params.set('city', customCity as string)
  }

  return API.get(endpoints.newFunnelRecommendation, {
    ...config,
    params,
  })
}

export const getNewFunnelRecommendationsByCity = (
  cityId: string,
  city: string,
  config?: AxiosRequestConfig,
  financial?: {
    monthlyIncome: string
    age: string
  },
) => {
  const params = new URLSearchParams()
  if (financial) {
    params.append('age', financial.age)
    params.append('monthlyIncome', financial.monthlyIncome)
  }

  params.append('cityId', cityId as string)
  params.append('city', city as string)

  return API.get(endpoints.newFunnelRecommendation, {
    ...config,
    params,
  })
}

export const getPopularCars = (config?: AxiosRequestConfig) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)

  return API.get(endpoints.popularCars, {
    ...config,
    params,
  })
}

export const getNewFunnelLoanPermutations = (
  { id, monthlyIncome, age }: NewFunnelLoanPermutationsRequest,
  config?: AxiosRequestConfig,
) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)
  return API.post(
    endpoints.newFunnelLoanPermutations.replace(':id', id),
    {
      monthlyIncome,
      age,
    },
    {
      params,
      ...config,
    },
  )
}

export const getSuggestionsCars = (
  config?: AxiosRequestConfig,
  keyword?: string,
  sortBy?: string,
) => {
  const params = new URLSearchParams()
  params.append('city', getCity().cityCode as string)
  params.append('cityId', getCity().id as string)
  sortBy && params.append('sortBy', sortBy as string)
  return API.get(endpoints.variantSuggestions + keyword, { ...config, params })
}
export const getNewFunnelRecommendationsCarModel = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { model }: any,
  config?: AxiosRequestConfig,
) => {
  return API.get(
    `${endpoints.recommendations}/new-funnel?search=${model}`,
    config,
  )
}

export const getNewFunnelLoanSpecialRate = (
  {
    otr,
    dp,
    dpAmount,
    monthlyIncome,
    age,
    city,
    discount,
    rateType,
    angsuranType,
    isFreeInsurance,
  }: SpecialRateRequest,
  config?: AxiosRequestConfig,
) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)
  return API.post(
    endpoints.specialRate,
    {
      otr,
      dp,
      dpAmount,
      monthlyIncome,
      age,
      city,
      discount,
      rateType,
      angsuranType,
      isFreeInsurance,
    },
    {
      params,
      ...config,
    },
  )
}

export const registerCustomerSeva = ({
  phoneNumber,
  fullName,
  dob,
  gender,
  email,
  promoSubscription,
  marital,
  referralCode,
}: CustomerRegister) => {
  return API.post(endpoints.createCustomerSeva, {
    phoneNumber,
    fullName,
    dob,
    gender,
    email,
    promoSubscription,
    marital,
    referralCode,
  })
}

export const getCompanyBranch = (id: string, config?: AxiosRequestConfig) => {
  return API.get(endpoints.companyBranch.replace(':id', id), config)
}

export const getGiiasCarCollection = (
  city: string,
  config?: AxiosRequestConfig,
) => {
  const params = new URLSearchParams()
  params.append('city', city)
  return API.get(endpoints.giiasCarCollection, { ...config, params })
}

export const getGiiasCarBrand = (config?: AxiosRequestConfig) => {
  return API.get(endpoints.giiasCarBrand, config)
}

export const getCarBodyTypes = (config?: AxiosRequestConfig) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)
  return API.get(endpoints.carsBodyTypes, { params, ...config })
}

export const getCompanyList = (config?: AxiosRequestConfig) => {
  return API.get(endpoints.companyList, config)
}

export const getCarCollection = (
  cityId: string,
  config?: AxiosRequestConfig,
) => {
  return API.get(endpoints.carCollection, {
    ...config,
    params: {
      ...config?.params,
      cityId,
    },
  })
}

export const getCarVideoReview = (config?: AxiosRequestConfig) => {
  return API.get(endpoints.carVideoReview, config)
}

export const getMinMaxPrice = (config?: AxiosRequestConfig) => {
  const params = new URLSearchParams()
  getCity().cityCode && params.append('city', getCity().cityCode as string)

  return API.get(endpoints.minMaxPrice, {
    ...config,
    params,
  })
}

export const getLoanCalculatorInsurance = (params: {
  modelId: string
  cityCode: string
  tenure: string | number
}) => {
  return API.get(
    endpoints.loanCalculatorInsurance
      .replace(':modelId', params.modelId)
      .replace(':cityCode', params.cityCode)
      .replace(':tenure', params.tenure.toString()),
  )
}

export const getLoanPermutationIncludePromo = ({
  brand,
  model,
  age,
  angsuranType,
  city,
  discount,
  dp,
  dpAmount,
  monthlyIncome,
  otr,
  isPTBC,
  variantId,
  calculateIncludeSubsidi,
}: LoanCalculatorIncludePromoPayloadType) => {
  return API.post(endpoints.loanPermutationIncludePromo, {
    brand,
    model,
    age,
    angsuranType,
    city,
    discount,
    dp,
    dpAmount,
    monthlyIncome,
    otr,
    isPTBC,
    variantId,
    calculateIncludeSubsidi,
  })
}

export const getLoanPermutationAsuransiKombinasi = ({
  brand,
  model,
  age,
  angsuranType,
  city,
  discount,
  dp,
  dpAmount,
  monthlyIncome,
  otr,
  isPTBC,
  tenure,
  asuransiKombinasi,
  variantId,
}: LoanCalculatorAsuransiKombinasiPayloadType) => {
  return API.post(endpoints.loanPermutationAsuransiKombinasi, {
    brand,
    model,
    age,
    angsuranType,
    city,
    discount,
    dp,
    dpAmount,
    monthlyIncome,
    otr,
    isPTBC,
    tenure,
    asuransiKombinasi,
    variantId,
  })
}

export const getFinalDpRangeValidation = (
  { variantId, cityCode }: FinalDpValidationPayload,
  config?: AxiosRequestConfig,
) => {
  return API.get(
    endpoints.finalDpValidation
      .replace(':variantId', variantId)
      .replace(':cityCode', cityCode),
    { ...config },
  )
}
