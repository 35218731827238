export const provinceEnglishCityMap = {
  Bali: [
    'Badung',
    'Bangli',
    'Buleleng',
    'Denpasar',
    'Gianyar',
    'Jembrana',
    'Karangasem',
    'Klungkung',
    'Tabanan',
  ],
  'Bangka Belitung': [
    'Bangka Barat',
    'Bangka Selatan',
    'Bangka Tengah',
    'Bangka',
    'Belitung Timur',
    'Belitung',
    'Pangkal Pinang',
  ],
  Banten: [
    'Balaraja',
    'Cilegon',
    'Lebak',
    'Pandeglang',
    'Serang',
    'Tangerang Selatan',
    'Tangerang',
  ],
  Bengkulu: [
    'Bengkulu Selatan',
    'Bengkulu Tengah',
    'Bengkulu Utara',
    'Bengkulu',
    'Kaur',
    'Kepahiang',
    'Lebong',
    'Muko Muko',
    'Rejang Lebong',
    'Seluma',
  ],
  'DI Yogyakarta': [
    'Bantul',
    'Gunung Kidul',
    'Kulon Progo',
    'Sleman',
    'Yogyakarta',
  ],
  'DKI Jakarta': [
    'Jakarta Barat',
    'Jakarta Pusat',
    'Jakarta Selatan',
    'Jakarta Timur',
    'Jakarta Utara',
    'Kepulauan Seribu',
  ],
  Gorontalo: [
    'Boalemo',
    'Bone Bolango',
    'Gorontalo Utara',
    'Gorontalo',
    'Pohuwato',
  ],
  Jambi: [
    'Batang Hari',
    'Bungo',
    'Jambi',
    'Kerinci',
    'Merangin',
    'Muaro Jambi',
    'Sarolangun',
    'Sungaipenuh',
    'Tanjung Jabung Barat',
    'Tanjung Jabung Timur',
    'Tebo',
  ],
  'Jawa Barat': [
    'Bandung Barat',
    'Bandung',
    'Banjar',
    'Bekasi',
    'Bogor',
    'Ciamis',
    'Cianjur',
    'Cimahi',
    'Cirebon',
    'Depok',
    'Garut',
    'Indramayu',
    'Karawang',
    'Kuningan',
    'Majalengka',
    'Pangandaran',
    'Purwakarta',
    'Subang',
    'Sukabumi',
    'Sumedang',
    'Tasikmalaya',
  ],
  'Jawa Tengah': [
    'Banjarnegara',
    'Banyumas',
    'Batang',
    'Blora',
    'Boyolali',
    'Brebes',
    'Cilacap',
    'Demak',
    'Grobogan',
    'Jepara',
    'Karanganyar',
    'Kebumen',
    'Kendal',
    'Klaten',
    'Kudus',
    'Magelang',
    'Pati',
    'Pekalongan',
    'Pemalang',
    'Purbalingga',
    'Purwokerto',
    'Purworejo',
    'Rembang',
    'Salatiga',
    'Semarang',
    'Solo',
    'Sragen',
    'Sukoharjo',
    'Surakarta',
    'Tegal',
    'Temanggung',
    'Wonogiri',
    'Wonosobo',
  ],
  'Jawa Timur': [
    'Bangkalan',
    'Banyuwangi',
    'Batu',
    'Blitar',
    'Bojonegoro',
    'Bondowoso',
    'Gresik',
    'Jember',
    'Jombang',
    'Kediri',
    'Lamongan',
    'Lumajang',
    'Madiun',
    'Madura',
    'Magetan',
    'Malang',
    'Mojokerto',
    'Nganjuk',
    'Ngawi',
    'Pacitan',
    'Pamekasan',
    'Pasuruan',
    'Ponorogo',
    'Probolinggo',
    'Sampang',
    'Sidoarjo',
    'Situbondo',
    'Sumenep',
    'Surabaya',
    'Trenggalek',
    'Tuban',
    'Tulungagung',
  ],
  'Kalimantan Barat': [
    'Bengkayang',
    'Kapuas Hulu',
    'Kayong Utara',
    'Ketapang',
    'Kubu Raya',
    'Landak',
    'Melawi',
    'Mempawah',
    'Nanga Pinoh',
    'Pontianak',
    'Putusibau',
    'Sambas',
    'Sanggau',
    'Sekadau',
    'Singkawang',
    'Sintang',
  ],
  'Kalimantan Selatan': [
    'Balangan',
    'Banjar',
    'Banjarbaru',
    'Banjarmasin',
    'Barito Kuala',
    'Hulu Sungai Selatan',
    'Hulu Sungai Tengah',
    'Hulu Sungai Utara',
    'Kotabaru',
    'Tabalong',
    'Tanah Bumbu',
    'Tanah Laut',
    'Tapin',
  ],
  'Kalimantan Tengah': [
    'Barito Selatan',
    'Barito Timur',
    'Barito Utara',
    'Gunung Mas',
    'Kapuas',
    'Katingan',
    'Kotawaringin Barat',
    'Kotawaringin Timur',
    'Lamandau',
    'Murung Raya',
    'Palangka Raya',
    'Pulang Pisau',
    'Seruyan',
    'Sukamara',
  ],
  'Kalimantan Timur': [
    'Balikpapan',
    'Berau',
    'Bontang',
    'Kutai Barat',
    'Kutai Kartanegara',
    'Kutai Timur',
    'Paser',
    'Pasir Tanah Grogot',
    'Penajam Paser Utara',
    'Samarinda',
    'Sangatta',
    'Tarakan',
  ],
  'Kalimantan Utara': [
    'Bulungan (Bulongan)',
    'Malinau',
    'Nunukan',
    'Tana Tidung',
    'Tarakan',
  ],
  'Kepulauan Riau': [
    'Batam',
    'Bintan',
    'Karimun',
    'Kepulauan Anambas',
    'Lingga',
    'Natuna',
    'Tanjung Pinang',
  ],
  Lampung: [
    'Bandar Lampung',
    'Lampung Barat',
    'Lampung Selatan',
    'Lampung Tengah',
    'Lampung Timur',
    'Lampung Utara',
    'Mesuji',
    'Metro',
    'Pesawaran',
    'Pesisir Barat',
    'Pringsewu',
    'Tanggamus',
    'Tanjung Karang',
    'Tulang Bawang Barat',
    'Tulang Bawang',
    'Way Kanan',
  ],
  'Maluku Utara': [
    'Halmahera Barat',
    'Halmahera Selatan',
    'Halmahera Tengah',
    'Halmahera Timur',
    'Halmahera Utara',
    'Kepulauan Sula',
    'Pulau Morotai',
    'Ternate',
    'Tidore Kepulauan',
  ],
  Maluku: [
    'Ambon',
    'Buru Selatan',
    'Buru',
    'Kepulauan Aru',
    'Maluku Barat Daya',
    'Maluku Tengah',
    'Maluku Tenggara Barat',
    'Maluku Tenggara',
    'Seram Bagian Barat',
    'Seram Bagian Timur',
    'Tual',
  ],
  'Nanggroe Aceh Darussalam': [
    'Aceh Barat Daya',
    'Aceh Barat',
    'Aceh Besar',
    'Aceh Jaya',
    'Aceh Selatan',
    'Aceh Singkil',
    'Aceh Tamiang',
    'Aceh Tengah',
    'Aceh Tenggara',
    'Aceh Timur',
    'Aceh Utara',
    'Banda Aceh',
    'Bener Meriah',
    'Bireuen',
    'Gayo Lues',
    'Langsa',
    'Lhokseumawe',
    'Nagan Raya',
    'Pidie Jaya',
    'Pidie',
    'Sabang',
    'Simeulue',
    'Subulussalam',
  ],
  'Nusa Tenggara Barat': [
    'Bima',
    'Dompu',
    'Lombok Barat',
    'Lombok Tengah',
    'Lombok Timur',
    'Lombok Utara',
    'Mataram',
    'Sumbawa Barat',
    'Sumbawa',
  ],
  'Nusa Tenggara Timur': [
    'Alor',
    'Belu',
    'Ende',
    'Flores Timur',
    'Kupang',
    'Lembata',
    'Manggarai Barat',
    'Manggarai Timur',
    'Manggarai',
    'Nagekeo',
    'Ngada',
    'Rote Ndao',
    'Sabu Raijua',
    'Sikka',
    'Sumba Barat Daya',
    'Sumba Barat',
    'Sumba Tengah',
    'Sumba Timur',
    'Timor Tengah Selatan',
    'Timor Tengah Utara',
  ],
  'Papua Barat': [
    'Fakfak',
    'Kaimana',
    'Manokwari Selatan',
    'Manokwari',
    'Maybrat',
    'Pegunungan Arfak',
    'Raja Ampat',
    'Sorong Selatan',
    'Sorong',
    'Tambrauw',
    'Teluk Bintuni',
    'Teluk Wondama',
  ],
  Papua: [
    'Asmat',
    'Biak Numfor',
    'Boven Digoel',
    'Deiyai (Deliyai)',
    'Dogiyai',
    'Intan Jaya',
    'Jayapura',
    'Jayawijaya',
    'Keerom',
    'Kepulauan Yapen (Yapen Waropen)',
    'Lanny Jaya',
    'Mamberamo Raya',
    'Mamberamo Tengah',
    'Mappi',
    'Merauke',
    'Mimika',
    'Nabire',
    'Nduga',
    'Paniai',
    'Pegunungan Bintang',
    'Puncak Jaya',
    'Puncak',
    'Sarmi',
    'Supiori',
    'Tolikara',
    'Waropen',
    'Yahukimo',
    'Yalimo',
  ],
  Riau: [
    'Bengkalis',
    'Dumai',
    'Indragiri Hilir',
    'Indragiri Hulu',
    'Kampar',
    'Kepulauan Meranti',
    'Kuantan Singingi',
    'Pekanbaru',
    'Pelalawan',
    'Rokan Hilir',
    'Rokan Hulu',
    'Siak',
  ],
  'Sulawesi Barat': [
    'Majene',
    'Mamasa',
    'Mamuju Utara',
    'Mamuju',
    'Polewali Mandar',
  ],
  'Sulawesi Selatan': [
    'Bantaeng',
    'Barru',
    'Bone',
    'Bulukumba',
    'Enrekang',
    'Gowa',
    'Jeneponto',
    'Luwu Timur',
    'Luwu Utara',
    'Luwu',
    'Makassar',
    'Maros',
    'Palopo',
    'Pangkajene Kepulauan',
    'Parepare',
    'Pinrang',
    'Selayar (Kepulauan Selayar)',
    'Sidenreng Rappang',
    'Sinjai',
    'Soppeng',
    'Takalar',
    'Tana Toraja',
    'Toraja Utara',
    'Wajo',
  ],
  'Sulawesi Tengah': [
    'Banggai Kepulauan',
    'Banggai',
    'Buol',
    'Donggala',
    'Morowali',
    'Palu',
    'Parigi Moutong',
    'Poso',
    'Sigi',
    'Tojo Una',
    'Toli',
  ],
  'Sulawesi Tenggara': [
    'Bau',
    'Bombana',
    'Buton Utara',
    'Buton',
    'Kendari',
    'Kolaka Utara',
    'Kolaka',
    'Konawe Selatan',
    'Konawe Utara',
    'Konawe',
    'Muna',
    'Wakatobi',
  ],
  'Sulawesi Utara': [
    'Bitung',
    'Bolaang Mongondow (Bolmong)',
    'Bolaang Mongondow Selatan',
    'Bolaang Mongondow Timur',
    'Bolaang Mongondow Utara',
    'Kepulauan Sangihe',
    'Kepulauan Siau Tagulandang Biaro (Sitaro)',
    'Kepulauan Talaud',
    'Kotamobagu',
    'Manado',
    'Minahasa Selatan',
    'Minahasa Tenggara',
    'Minahasa Utara',
    'Minahasa',
    'Tomohon',
  ],
  'Sumatera Barat': [
    'Agam',
    'Bukittinggi',
    'Dharmasraya',
    'Kepulauan Mentawai',
    'Lima Puluh Koto',
    'Padang Panjang',
    'Padang Pariaman',
    'Padang',
    'Pariaman',
    'Pasaman Barat',
    'Pasaman',
    'Payakumbuh',
    'Pesisir Selatan',
    'Sawah Lunto',
    'Sijunjung (Sawah Lunto Sijunjung)',
    'Solok Selatan',
    'Solok',
    'Tanah Datar',
  ],
  'Sumatera Selatan': [
    'Banyuasin',
    'Empat Lawang',
    'Lahat',
    'Lubuk Linggau',
    'Muara Enim',
    'Musi Banyuasin',
    'Musi Rawas',
    'Ogan Ilir',
    'Ogan Komering Ilir',
    'Ogan Komering Ulu Selatan',
    'Ogan Komering Ulu Timur',
    'Ogan Komering Ulu',
    'Pagar Alam',
    'Palembang',
    'Prabumulih',
  ],
  'Sumatera Utara': [
    'Asahan',
    'Batu Bara',
    'Binjai',
    'Dairi',
    'Deli Serdang',
    'Gunungsitoli',
    'Humbang Hasundutan',
    'Karo',
    'Labuhan Batu Selatan',
    'Labuhan Batu Utara',
    'Labuhan Batu',
    'Langkat',
    'Mandailing Natal',
    'Medan',
    'Nias Barat',
    'Nias Selatan',
    'Nias Utara',
    'Nias',
    'Padang Lawas Utara',
    'Padang Lawas',
    'Padang Sidempuan',
    'Pakpak Bharat',
    'Pematang Siantar',
    'Samosir',
    'Serdang Bedagai',
    'Sibolga',
    'Simalungun',
    'Tanjung Balai',
    'Tapanuli Selatan',
    'Tapanuli Tengah',
    'Tapanuli Utara',
    'Tebing Tinggi',
    'Toba Samosir',
  ],
}
