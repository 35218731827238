import clsx from 'clsx'
import elementId from 'helpers/elementIds'
import React, { useContext } from 'react'
import { SearchWidgetContext } from '_revamp/components/organism/searchWidget'
import styles from '_revamp/styles/molecules/searchWidget/tenureOptionWidget.module.scss'

interface TenureOptionWidgetProps {
  onClose: () => void
}

export default ({ onClose }: TenureOptionWidgetProps) => {
  const { state: funnelWidget, setState: setFunnelWidget } = useContext(
    SearchWidgetContext,
  )
  const onChoose = (value: number) => {
    setFunnelWidget((prev) => ({ ...prev, tenure: String(value) }))
    onClose()
  }

  return (
    <div className={styles.containerTenure}>
      {[1, 2, 3, 4, 5].map((item, index) => (
        <div
          className={clsx({
            [styles.box]: true,
            [styles.active]: String(funnelWidget.tenure) === String(item),
          })}
          key={index}
          onClick={() => onChoose(item)}
          data-testid={elementId.Field.TenurePopup + item + '-th'}
        >
          <span>{item}</span>
        </div>
      ))}
    </div>
  )
}
