import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconApplication: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3163 4.05151C22.8402 4.22616 23.1234 4.79248 22.9487 5.31642L22.0507 8.01046C21.9212 8.39898 22.2104 8.8002 22.6199 8.8002H26.8C27.3523 8.8002 27.8 9.24791 27.8 9.8002C27.8 10.3525 27.3523 10.8002 26.8 10.8002H22.6199C20.8453 10.8002 19.5921 9.06158 20.1533 7.378L21.0513 4.68397C21.226 4.16002 21.7923 3.87686 22.3163 4.05151Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.60003 6.6002C8.60003 5.827 9.22683 5.2002 10 5.2002H21.9086C22.3187 5.2002 22.7082 5.38004 22.9742 5.69223L26.2657 9.55533C26.4815 9.80863 26.6 10.1305 26.6 10.4633V25.8002C26.6 26.5734 25.9732 27.2002 25.2 27.2002H10C9.22683 27.2002 8.60003 26.5734 8.60003 25.8002V25.778C7.90487 25.7265 7.23443 25.5861 6.60003 25.3682V25.8002C6.60003 27.678 8.12226 29.2002 10 29.2002H25.2C27.0778 29.2002 28.6 27.678 28.6 25.8002V10.4633C28.6 9.65513 28.3122 8.8734 27.788 8.25824L24.4966 4.39514C23.8506 3.63697 22.9046 3.2002 21.9086 3.2002H10C8.12226 3.2002 6.60003 4.72243 6.60003 6.6002V10.2322C7.23443 10.0143 7.90487 9.8739 8.60003 9.82236V6.6002Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44324 11.0424C9.84785 10.8851 11.2666 11.1694 12.5021 11.8558C12.9849 12.124 13.1589 12.7328 12.8907 13.2156C12.6225 13.6984 12.0137 13.8723 11.5309 13.6041C10.6588 13.1197 9.65731 12.919 8.66582 13.03C7.67433 13.141 6.74208 13.5583 5.99869 14.2237C5.25531 14.8891 4.73774 15.7696 4.51795 16.7428C4.29816 17.716 4.38708 18.7334 4.77235 19.6537C5.15761 20.574 5.82007 21.3514 6.66761 21.8778C7.51514 22.4042 8.50564 22.6534 9.50136 22.5907C10.4971 22.5281 11.4485 22.1567 12.2234 21.5283C12.9983 20.8999 13.5582 20.0456 13.8251 19.0843C13.9728 18.5522 14.524 18.2405 15.0561 18.3883C15.5883 18.536 15.8999 19.0872 15.7522 19.6194C15.3741 20.9812 14.581 22.1914 13.4832 23.0817C12.3854 23.972 11.0375 24.4981 9.62692 24.5868C8.21631 24.6755 6.81311 24.3225 5.61243 23.5768C4.41175 22.8311 3.47327 21.7298 2.92748 20.4261C2.38169 19.1223 2.25572 17.6809 2.56708 16.3022C2.87845 14.9235 3.61167 13.6761 4.6648 12.7335C5.71793 11.7908 7.03862 11.1997 8.44324 11.0424Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5863 13.9824C15.9276 14.4166 15.8521 15.0453 15.4178 15.3865L9.81785 19.7865C9.38627 20.1256 8.76203 20.0535 8.41916 19.6249L6.81916 17.6249C6.47415 17.1936 6.54407 16.5643 6.97533 16.2193C7.40659 15.8743 8.03589 15.9442 8.3809 16.3755L9.36166 17.6015L14.1822 13.8139C14.6165 13.4727 15.2451 13.5481 15.5863 13.9824Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 23.4002C17 22.8479 17.4477 22.4002 18 22.4002H24.4C24.9523 22.4002 25.4 22.8479 25.4 23.4002C25.4 23.9525 24.9523 24.4002 24.4 24.4002H18C17.4477 24.4002 17 23.9525 17 23.4002Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 18.6002C17 18.0479 17.4477 17.6002 18 17.6002H24.4C24.9523 17.6002 25.4 18.0479 25.4 18.6002C25.4 19.1525 24.9523 19.6002 24.4 19.6002H18C17.4477 19.6002 17 19.1525 17 18.6002Z"
        fill="#246ED4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 13.8002C17 13.2479 17.4477 12.8002 18 12.8002H24.4C24.9523 12.8002 25.4 13.2479 25.4 13.8002C25.4 14.3525 24.9523 14.8002 24.4 14.8002H18C17.4477 14.8002 17 14.3525 17 13.8002Z"
        fill="#246ED4"
      />
    </svg>
  )
}
