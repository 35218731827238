import React, { useEffect } from 'react'
import styles from '_revamp/styles/atoms/menuItem.module.scss'
import { IconChevronDown } from '_revamp/components/atoms'
import { trackBurgerMenuClick } from 'helpers/amplitude/seva20Tracking'
import { MobileWebTopMenuType } from 'types/types'
import { trackEventCountly } from 'helpers/countly/countly'
import { CountlyEventNames } from 'helpers/countly/eventNames'
import { useHistory } from 'react-router-dom'
import { PreviousButton, navigateToPLP } from '_revamp/utils/navigate'
type MenuItemProps = {
  item?: MobileWebTopMenuType
  pageOrigination?: string
}

export const MenuItem: React.FC<MenuItemProps> = ({
  item,
  pageOrigination,
}): JSX.Element => {
  const [state, setState] = React.useState(false)
  const history = useHistory()

  const handleClickMenu = (menuUrl: string, menuName: string) => {
    trackBurgerMenuClick({
      Page_Origination_URL: window.location.href,
      Menu: menuName,
    })

    trackEventCountly(CountlyEventNames.WEB_HAMBURGER_MENU_CLICK, {
      PAGE_ORIGINATION: pageOrigination,
      PAGE_DIRECTION_URL: menuUrl.includes('blog')
        ? menuUrl
        : 'https://' + window.location.hostname + menuUrl,
    })
    if (menuName === 'Mobil Baru') {
      return navigateToPLP(PreviousButton.HamburgerMenu, history, null, menuUrl)
    }
    window.location.href = menuUrl
  }

  useEffect(() => {
    if (state) {
      trackEventCountly(CountlyEventNames.WEB_HAMBURGER_MENU_EXPAND, {
        MENU: item?.menuName,
      })
    }
  }, [state])

  return (
    <>
      <div className={styles.parentMenu} onClick={() => setState(() => !state)}>
        <p className={`${styles.menu} ${state ? styles.isActive : ''}`}>
          {item?.menuName}
        </p>
        <IconChevronDown
          height={25}
          width={25}
          color="#13131B"
          className={state ? styles.rotateUp : styles.rotateDown}
        />
      </div>

      <div
        className={`${styles.submenuContainer} ${
          state ? styles.slideDown : styles.slideUp
        }`}
      >
        {item?.subMenu.map((child) => (
          <div
            onClick={() =>
              handleClickMenu(child.menuUrl as string, child.menuName)
            }
            className={styles.submenu}
            key={child.menuCode}
          >
            {child.menuName}
          </div>
        ))}
      </div>
    </>
  )
}
