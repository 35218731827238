import React, { HTMLAttributes } from 'react'
import { IconWarningCircle } from '_revamp/components/atoms/icon'
import styles from '_revamp/styles/molecules/labelCard/sulit/sulit.module.scss'
import { colors } from '../../../../../styles/colors'

interface Props extends HTMLAttributes<HTMLDivElement> {
  additionalClassname?: string
  prefixComponent?: () => JSX.Element
  labelText?: string
}

export default ({
  additionalClassname,
  prefixComponent,
  labelText = 'Sulit disetujui',
  ...props
}: Props) => {
  return (
    <div
      className={`${styles.container} ${styles.labelResult} ${additionalClassname}`}
      {...props}
    >
      {prefixComponent ? (
        prefixComponent()
      ) : (
        <IconWarningCircle
          height={16}
          width={16}
          color={colors.white}
          fillColor="#B4231E"
        />
      )}
      <span className={styles.microBold}>{labelText}</span>
    </div>
  )
}
