import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconCamera: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1408 9.25C17.2213 9.25 16.3627 9.70953 15.8527 10.4746L15.5609 10.9122C14.5872 12.3727 12.948 13.25 11.1927 13.25H10.6667C9.14797 13.25 7.91675 14.4812 7.91675 16V28C7.91675 29.5188 9.14796 30.75 10.6667 30.75H29.3334C30.8522 30.75 32.0834 29.5188 32.0834 28V16C32.0834 14.4812 30.8522 13.25 29.3334 13.25H28.8075C27.0521 13.25 25.4129 12.3727 24.4392 10.9122L24.1475 10.4746C23.6375 9.70953 22.7788 9.25 21.8593 9.25H18.1408ZM13.7726 9.08782C14.7462 7.62728 16.3855 6.75 18.1408 6.75H21.8593C23.6147 6.75 25.2539 7.62728 26.2276 9.08782L26.5193 9.52543C27.0294 10.2905 27.888 10.75 28.8075 10.75H29.3334C32.2329 10.75 34.5834 13.1005 34.5834 16V28C34.5834 30.8995 32.2329 33.25 29.3334 33.25H10.6667C7.76725 33.25 5.41675 30.8995 5.41675 28V16C5.41675 13.1005 7.76725 10.75 10.6667 10.75H11.1927C12.1122 10.75 12.9708 10.2905 13.4808 9.52543L13.7726 9.08782Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0001 17.25C17.7449 17.25 15.9167 19.0782 15.9167 21.3333C15.9167 23.5885 17.7449 25.4167 20.0001 25.4167C22.2552 25.4167 24.0834 23.5885 24.0834 21.3333C24.0834 19.0782 22.2552 17.25 20.0001 17.25ZM13.4167 21.3333C13.4167 17.6975 16.3642 14.75 20.0001 14.75C23.636 14.75 26.5834 17.6975 26.5834 21.3333C26.5834 24.9692 23.636 27.9167 20.0001 27.9167C16.3642 27.9167 13.4167 24.9692 13.4167 21.3333Z"
        fill={color}
      />
    </svg>
  )
}
