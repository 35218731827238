import React, { useState } from 'react'
import styles from '_revamp/styles/atoms/inputPhone.module.scss'
import { PropsInput } from '_revamp/utils/types'
import { Separator } from '../../separator'
import clsx from 'clsx'

const InputPhone = ({
  value,
  placeholder,
  onChange,
  isError,
  message,
  disabled,
  title,
  dataTestId,
  className,
  ...props
}: PropsInput): JSX.Element => {
  const [isFocus, setIsFocus] = useState(false)

  const setFocus = () => {
    setIsFocus(true)
  }

  const setBlur = () => {
    setIsFocus(false)
  }

  return (
    <div className={clsx(styles.wrapper, className)}>
      {title && <p className={styles.titleText}>{title}</p>}
      <div
        className={clsx(
          styles.wrapperInput,
          isFocus ? styles.focus : styles.default,
          isError && styles.error,
          disabled && styles.disabled,
        )}
        onFocus={setFocus}
      >
        <p className={clsx(styles.textRegion, disabled && styles.disabled)}>
          +62
        </p>
        <Separator width={1} height={16} />
        <input
          type={'tel'}
          data-test-id={dataTestId}
          id={dataTestId}
          value={value}
          maxLength={13}
          onChange={onChange}
          disabled={disabled}
          className={clsx(styles.input, disabled && styles.disabled)}
          placeholder={placeholder}
          {...props}
          onBlur={setBlur}
        />
      </div>

      {isError && <p className={styles.errorText}>{message}</p>}
    </div>
  )
}

export default InputPhone
