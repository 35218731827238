import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { googleAnalyticsPageView } from './googleAnalytics'

export const useGoogleAnalyticsPageView = () => {
  const location = useLocation()
  useEffect(() => {
    googleAnalyticsPageView()
  }, [location])
}
