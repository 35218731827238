import React from 'react'
import { FloatButton, FloatButtonProps } from 'antd'
import { IconWhatsapp } from '_revamp/components/atoms'
import '_revamp/styles/global.scss'

type Props = Omit<FloatButtonProps, 'icon'> & {
  additionalStyle?: string
}

export const WhatsappButton = ({ additionalStyle, ...props }: Props) => {
  return (
    <FloatButton
      className={`whatsapp-floating-button ${additionalStyle}`}
      icon={<IconWhatsapp width={32} height={32} />}
      {...props}
    />
  )
}
