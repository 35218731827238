import amplitude from 'amplitude-js'

import currentEnvironment from 'helpers/environments'
import { TrackingEvent } from './trackingEvents'

const {
  amplitude: { apiKey },
} = currentEnvironment

const instance = amplitude.getInstance()
instance.init(apiKey)

const enableAmplitudeTracker =
  currentEnvironment.featureToggles.enableAmplitudeTracker
export const setAmplitudeUserId = (id: string | null) => {
  if (enableAmplitudeTracker) instance.setUserId(id)
}

export const setAmplitudeUserProperties = (
  properties: Record<string, unknown>,
): void => {
  if (enableAmplitudeTracker) instance.setUserProperties(properties)
}

const { innerWidth: width, innerHeight: height } = window

setAmplitudeUserProperties({ screen_resolution: `${width}x${height}` })

export const logAmplitudeEvent = (event: TrackingEvent): void => {
  if (enableAmplitudeTracker) instance.logEvent(event.name, event.data)
}

// One Time User Properties
// type OneTimeUserProperty =
//   // Delete once real properties are added
//   | {
//       key: 'test'
//       value: string
//     }
//   // Delete once real properties are added
//   | {
//       key: 'test2'
//       value: number
//     }

// export const logAmplitudeOneTimeUserProperty = ({
//   key,
//   value,
// }: OneTimeUserProperty): void => {
//   const identify = new Identify()
//   identify.setOnce(key, value)
//   instance.identify(identify)
// }

// User Properties
// type UserProperty =
//   // Delete once real properties are added
//   | {
//       key: 'identifier'
//       value: string
//     }
//   // Delete once real properties are added
//   | {
//       key: 'test2'
//       value: number
//     }

// export const logAmplitudeUserProperty = ({
//   key,
//   value,
// }: UserProperty): void => {
//   const identify = new Identify()
//   instance.identify(identify.set(key, value))
// }

// export const unsetAmplitudeUserProperty = (key: string): void => {
//   const identify = new Identify()
//   instance.identify(identify.unset(key))
// }
