import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { clientInteractionNavigateToErrorPage } from './navigateToErrorPage'

export const customAxiosGet = (path: string, config?: AxiosRequestConfig) => {
  const promise: Promise<any> = new Promise((resolve, reject) => {
    axios.get(`${path}`, config).then(
      (result: AxiosResponse) => {
        resolve(result)
      },
      (error: AxiosError) => {
        reject(error)
        clientInteractionNavigateToErrorPage(error?.response?.status)
      },
    )
  })
  return promise
}

export const customAxiosPost = (
  path: string,
  data: any,
  config?: AxiosRequestConfig,
) => {
  const promise: Promise<any> = new Promise((resolve, reject) => {
    axios.post(`${path}`, data, config).then(
      (result: AxiosResponse) => {
        resolve(result)
      },
      (error: AxiosError) => {
        reject(error)
        clientInteractionNavigateToErrorPage(error?.response?.status)
      },
    )
  })
  return promise
}
