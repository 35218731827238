import { useContext } from 'react'
import patchDataContext from '../patchDataContext/patchDataContext'
import { FormControlValue } from '../../types/types'

export interface FinancialForm {
  paymentType?: FormControlValue
  monthlyInstallment?: FormControlValue
  downPaymentAmount?: FormControlValue
  carModel?: FormControlValue
}

const initData = {
  paymentType: 'downPayment',
  monthlyInstallment: '',
  downPaymentAmount: '',
  carModel: '',
}
const { Context, Provider } = patchDataContext<FinancialForm>(initData)

export const FinancialFormContextProvider = Provider

export const useFinancialFormData = () => {
  const { state, setState, patchState, clearState } = useContext(Context)
  return {
    financialForm: state,
    setFinancialForm: setState,
    patchFinancialForm: patchState,
    clearFinancialForm: clearState,
  }
}
