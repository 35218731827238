import React, { createContext, useContext } from 'react'
import { useLocalStorage } from '../../hooks/useLocalStorage/useLocalStorage'
import { LocalStorageKey } from '../../models/models'

interface ActionContextType {
  currentCityOtr: string
  setCurrentCityOtr: (value: string) => void
}
const defaultContextValue: ActionContextType = {
  currentCityOtr: 'jakarta',
  setCurrentCityOtr: (value: string) => {
    console.log('context', value)
  },
}
const CurrenCityOtrContext = createContext(defaultContextValue)

export const CurrenCityOtrContextProvider = ({ children }: HTMLElement) => {
  const [currentCityOtr, setCurrentCityOtr] = useLocalStorage<string>(
    LocalStorageKey.CityOtr,
    'jakarta',
  )

  return (
    <CurrenCityOtrContext.Provider
      value={{ currentCityOtr, setCurrentCityOtr }}
    >
      <>{children}</>
    </CurrenCityOtrContext.Provider>
  )
}

export const useCurrentCityOtrFromContext = () =>
  useContext(CurrenCityOtrContext)
