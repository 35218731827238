export enum ButtonVersion {
  Secondary = 'Secondary',
  SecondaryDark = 'SecondaryDark',
  PrimaryDarkBlue = 'PrimaryDarkBlue',
  Disable = 'Disable',
  Default = 'Default',
}

export enum ButtonSize {
  Big = 'Big',
  Small = 'Small',
}

export enum InputVersion {
  Default = 'Default',
  Mobile = 'Mobile',
}

export enum MinAmount {
  downPaymentAmount = 20000000,
  monthlyIncome = 3000000,
}

export enum TemanSeva {
  PTBC = 'SEVAPTBC',
}
