import { LocalStorageKey } from 'models/models'
import { CityOtrOption } from 'pages/component/CityOtrDropDown/CityOtrDropDown'
import React, { useEffect, useState } from 'react'
import { getLocalStorage, saveLocalStorage } from 'utils/localstorageUtils'
import { CitySelectorModal } from '../citySelectorModal'
import {
  TemanSevaOnboardingUrl,
  carResultsUrl,
  loanCalculatorDefaultUrl,
  refinancingUrl,
} from 'routes/routes'
import { useLocation } from 'react-router-dom'
import { countDaysDifference } from '_revamp/utils/handler/countDaysDifference'
import { getCities } from 'services/cities'

export const CityFirst = () => {
  const location = useLocation()
  const currentCity = getLocalStorage<CityOtrOption>(LocalStorageKey.CityOtr)
  const cityFirstRoute = [
    carResultsUrl,
    loanCalculatorDefaultUrl,
    refinancingUrl,
    TemanSevaOnboardingUrl,
  ]

  const isIn30DaysInterval = () => {
    const lastTimeSelectCity = getLocalStorage<string>(
      LocalStorageKey.LastTimeSelectCity,
    )
    if (!lastTimeSelectCity) {
      return false
    } else if (
      countDaysDifference(lastTimeSelectCity, new Date().toISOString()) <= 30
    ) {
      return true
    } else {
      return false
    }
  }

  const filterCity = cityFirstRoute.filter(
    (x) =>
      location.pathname.includes(x) &&
      !location.pathname.includes('form') &&
      !location.pathname.includes('mobil-baru-olx') &&
      !location.pathname.includes('landing-promo') &&
      !location.pathname.includes('ridwan-hanif'),
  )
  const showCondition =
    filterCity.length > 0 && !currentCity && !isIn30DaysInterval()
  const [showCity, setShowCity] = useState(showCondition)
  const [cityListApi, setCityListApi] = useState<Array<CityOtrOption>>([])

  const checkCitiesData = () => {
    if (cityListApi.length === 0 && showCity) {
      getCities().then((res) => {
        setCityListApi(res.data)
      })
    }
  }

  useEffect(() => {
    checkCitiesData()
    if (showCity) {
      saveLocalStorage(
        LocalStorageKey.LastTimeSelectCity,
        new Date().toISOString(),
      )
    }
  }, [])

  useEffect(() => {
    setShowCity(showCondition)
  }, [location.pathname])

  return (
    <CitySelectorModal
      isOpen={showCity}
      onClickCloseButton={() => setShowCity(false)}
      cityListFromApi={cityListApi}
    />
  )
}
