import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconTiktok: React.FC<PropsIcon> = ({
  width,
  height,
  color = 'white',
}): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 13 15"
      fill="none"
    >
      <path
        d="M12.8884 5.83992C12.7741 5.85165 12.6597 5.85751 12.5454 5.85751C11.2905 5.85751 10.1235 5.22713 9.43451 4.17747V9.90076C9.43451 12.2376 7.54043 14.1317 5.20361 14.1317C2.8668 14.1317 0.972717 12.2376 0.972717 9.90076C0.972717 7.56394 2.8668 5.66986 5.20361 5.66986C5.29157 5.66986 5.37953 5.67866 5.46456 5.68452V7.76918C5.3766 7.75745 5.29157 7.74279 5.20361 7.74279C4.01028 7.74279 3.04565 8.71036 3.04565 9.90076C3.04565 11.0912 4.01322 12.0587 5.20361 12.0587C6.39401 12.0587 7.44954 11.1205 7.44954 9.92715L7.47006 0.20752H9.46383C9.65148 1.99605 11.094 3.39169 12.8884 3.52363V5.83992Z"
        fill={color}
      />
    </svg>
  )
}
