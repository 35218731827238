import { logoutModalId } from '../const/const'
import { LocalStorageKey, SessionStorageKey } from '../models/models'
import { setAmplitudeUserId } from 'helpers/amplitude'
import { destroySessionMoEngage } from 'helpers/moengage'
import { isGoingToRestrictedRoutes } from './loginUtils'
import { rootUrl } from 'routes/routes'
// import MoEngage from 'react-moengage'

export const validateAutoLoginRoute = (): boolean => {
  const autoLoginPath = [
    '/akun',
    '/kualifikasi-kredit',
    '/teman-seva/dashboard',
    '/sales-dashboard',
    '/ktp',
    '/instant-approval',
  ]

  const checkRoute = autoLoginPath.some((x) =>
    window.location.pathname.includes(x),
  )
  if (checkRoute) return true
  return false
}

export const showLogoutModal = () => {
  const logoutDom = document.getElementById(logoutModalId)
  if (logoutDom) {
    logoutDom.style.display = 'flex'
  }
}

export const hideLogout = () => {
  const logoutDom = document.getElementById(logoutModalId)
  if (logoutDom) {
    logoutDom.style.display = 'none'
  }
}

export const removeInformationWhenLogout = () => {
  localStorage.removeItem(LocalStorageKey.Token)
  localStorage.removeItem(LocalStorageKey.CustomerId)
  localStorage.removeItem(LocalStorageKey.sevaCust)
  sessionStorage.removeItem(SessionStorageKey.CustomerId)
  sessionStorage.removeItem(SessionStorageKey.prevLoginPath)

  // MoEngage.destroySession()
  destroySessionMoEngage()
  setAmplitudeUserId(null)
}

export const getPageBeforeProfile = () => {
  const destinationRoute = localStorage.getItem(
    LocalStorageKey.PageBeforeProfile,
  )
  if (destinationRoute && !isGoingToRestrictedRoutes(destinationRoute)) {
    return destinationRoute
  } else {
    return rootUrl
  }
}

export const savePageBeforeProfile = (page: string) => {
  localStorage.setItem(LocalStorageKey.PageBeforeProfile, page)
}
