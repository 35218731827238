import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconShare: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8383 4C25.0015 4 22.6765 6.40222 22.6765 9.33326C22.6765 9.90055 22.7634 10.4598 22.9247 10.9743L14.4002 15.8718C13.5115 15.1267 12.387 14.6667 11.1618 14.6667C8.32497 14.6667 6 17.0689 6 20C6 22.931 8.32497 25.3332 11.1618 25.3332C12.387 25.3332 13.5115 24.8861 14.4002 24.141L22.937 29.0385C22.7741 29.5546 22.6764 30.0978 22.6764 30.6667C22.6764 33.5978 25.0014 36 27.8382 36C30.6749 36 32.9999 33.5978 32.9999 30.6667C32.9999 27.7357 30.6749 25.3335 27.8382 25.3335C26.4128 25.3335 25.1146 25.9489 24.1778 26.9232L15.8769 22.154C16.1607 21.4954 16.3236 20.763 16.3236 20.0002C16.3236 19.2326 16.1638 18.5083 15.8769 17.8464L24.1655 13.0772C25.1038 14.0596 26.4067 14.667 27.8382 14.667C30.675 14.667 33 12.2647 33 9.33371C33 6.40267 30.675 4 27.8383 4ZM27.8383 6.4615C29.3877 6.4615 30.6177 7.73234 30.6177 9.33326C30.6177 10.9342 29.3877 12.205 27.8383 12.205C26.2888 12.205 25.0589 10.9342 25.0589 9.33326C25.0589 7.73234 26.2888 6.4615 27.8383 6.4615ZM11.1618 17.128C12.7113 17.128 13.9412 18.3989 13.9412 19.9998C13.9412 21.6007 12.7113 22.8715 11.1618 22.8715C9.61239 22.8715 8.38243 21.6007 8.38243 19.9998C8.38243 18.3989 9.61239 17.128 11.1618 17.128ZM27.8383 27.7945C29.3877 27.7945 30.6177 29.0654 30.6177 30.6663C30.6177 32.2672 29.3877 33.538 27.8383 33.538C26.2888 33.538 25.0589 32.2672 25.0589 30.6663C25.0589 29.0654 26.2888 27.7945 27.8383 27.7945Z"
        fill={color}
      />
    </svg>
  )
}
