import React from 'react'
import styles from '_revamp/styles/atoms/errorMessage.module.scss'

interface ErrorMessageProps {
  children: React.ReactNode
}

export default ({ children }: ErrorMessageProps) => {
  return <p className={styles.text}>{children}</p>
}
