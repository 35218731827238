export const questions = [
  {
    title: 'What is SEVA?',
    content:
      'SEVA is an application that is ready to help you get the dream car that fits your budget. Through SEVA, you can submit a budget for your dream car in a fast, easy, and safe process.',
  },
  {
    title: 'How will SEVA help me?',
    content:
      'We will help you find the best car and the right car loan to buy that car given your monthly income and other requirements.',
  },
  {
    title: 'Is SEVA a sales agent?',
    content:
      'No,SEVA is a company that works with sales agents and loan officers to help you get the best car and car loan you want. Think of us as the only reference for the whole process of buying your dream car.',
  },
  {
    title: 'Is SEVA a Financial company?',
    content:
      'SEVA is not a financial company, but we work with partner finance companies to provide the best loans for you. SEVA will help in every loan application process until you finally get the best loan for your dream car.',
  },
  {
    title: 'Is my data safe?',
    content:
      'yes, fully secure and will not be shared with companies outside our group companies',
  },
  {
    title: 'How much does it cost?',
    content:
      "It's free, no additional charge to use SEVA to get the best car & car loan.",
  },
  {
    title: 'How to apply for a loan through SEVA?',
    content:
      '"1. Fill out a short survey about yourself\n' +
      '(survey image screenshot (2 page random))\n' +
      '2. Click “start loan application”\n' +
      '(Congratulations - normal state screenshot) \n' +
      '3. Upload the required documents \n' +
      '(welcome screen and document upload screenshots)\n' +
      '4. Track your loan application\n' +
      '(tracking page screenshot)\n' +
      '"',
  },
]
