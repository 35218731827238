import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconHistory: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4182 25.6985C18.0236 25.3667 18.7749 25.5931 19.0962 26.2041L19.9559 27.8389L21.5448 26.9681C22.1503 26.6363 22.9015 26.8627 23.2228 27.4737C23.5441 28.0847 23.3138 28.849 22.7084 29.1808L20.0233 30.6523C19.4179 30.984 18.6666 30.7577 18.3453 30.1466L16.9038 27.4055C16.5825 26.7945 16.8128 26.0302 17.4182 25.6985Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 22.75C28.6904 22.75 29.25 23.3096 29.25 24V27.6716C29.25 27.8705 29.329 28.0613 29.4697 28.2019L31.3839 30.1161C31.872 30.6043 31.872 31.3957 31.3839 31.8839C30.8957 32.372 30.1043 32.372 29.6161 31.8839L27.7019 29.9697C27.0924 29.3602 26.75 28.5335 26.75 27.6716V24C26.75 23.3096 27.3096 22.75 28 22.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9255 18.7706C27.9758 18.2629 30.1332 18.474 32.0482 19.3669C33.9622 20.2593 35.5158 21.7771 36.4696 23.666C37.4229 25.554 37.7253 27.7097 37.3325 29.7893C36.9397 31.8693 35.8718 33.7636 34.289 35.1634C32.7053 36.564 30.6986 37.3869 28.5895 37.4892C26.4803 37.5915 24.4039 36.9666 22.6944 35.7235C22.136 35.3175 22.0125 34.5357 22.4185 33.9774C22.8245 33.419 23.6063 33.2955 24.1646 33.7015C25.4169 34.6121 26.9326 35.0666 28.4684 34.9921C30.0041 34.9176 31.4708 34.3184 32.6328 33.2907C33.7949 32.263 34.5849 30.8665 34.876 29.3253C35.1671 27.7842 34.9424 26.1879 34.2379 24.7928C33.5335 23.3977 32.3902 22.2848 30.9917 21.6327C29.5932 20.9806 28.0206 20.8273 26.5264 21.1973C25.0323 21.5672 23.7033 22.4391 22.7528 23.6728C21.8024 24.9065 21.2857 26.4305 21.2857 28C21.2857 28.6904 20.7261 29.25 20.0357 29.25C19.3454 29.25 18.7857 28.6904 18.7857 28C18.7857 25.8832 19.4822 23.8218 20.7724 22.1471C22.0632 20.4715 23.8759 19.2781 25.9255 18.7706Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 9C3.75 6.10051 6.10051 3.75 9 3.75H25C27.8995 3.75 30.25 6.10051 30.25 9V16C30.25 16.6904 29.6904 17.25 29 17.25C28.3096 17.25 27.75 16.6904 27.75 16V9C27.75 7.48122 26.5188 6.25 25 6.25H9C7.48122 6.25 6.25 7.48122 6.25 9V31C6.25 32.5188 7.48122 33.75 9 33.75H18C18.6904 33.75 19.25 34.3096 19.25 35C19.25 35.6904 18.6904 36.25 18 36.25H9C6.10051 36.25 3.75 33.8995 3.75 31V9Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 11C8.75 10.3096 9.30964 9.75 10 9.75H24C24.6904 9.75 25.25 10.3096 25.25 11C25.25 11.6904 24.6904 12.25 24 12.25H10C9.30964 12.25 8.75 11.6904 8.75 11Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 16C8.75 15.3096 9.30964 14.75 10 14.75H24C24.6904 14.75 25.25 15.3096 25.25 16C25.25 16.6904 24.6904 17.25 24 17.25H10C9.30964 17.25 8.75 16.6904 8.75 16Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 21C8.75 20.3096 9.30964 19.75 10 19.75H16C16.6904 19.75 17.25 20.3096 17.25 21C17.25 21.6904 16.6904 22.25 16 22.25H10C9.30964 22.25 8.75 21.6904 8.75 21Z"
        fill={color}
      />
    </svg>
  )
}
