import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconChevronUp: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  alt,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.4692 25.4788C30.0873 25.8537 29.4682 25.8537 29.0864 25.4788L20 16.5576L10.9136 25.4788C10.5318 25.8537 9.91268 25.8537 9.53083 25.4788C9.14898 25.1039 9.14898 24.4961 9.53083 24.1212L19.3086 14.5212C19.6905 14.1463 20.3095 14.1463 20.6914 14.5212L30.4692 24.1212C30.851 24.4961 30.851 25.1039 30.4692 25.4788Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.642 25.6485C30.1647 26.1172 29.3908 26.1172 28.9135 25.6485L20 16.8971L11.0865 25.6485C10.6092 26.1172 9.83529 26.1172 9.35798 25.6485C8.88067 25.1799 8.88067 24.4201 9.35798 23.9515L19.1358 14.3515C19.6131 13.8828 20.3869 13.8828 20.8642 14.3515L30.642 23.9515C31.1193 24.4201 31.1193 25.1799 30.642 25.6485ZM29.2592 25.3091C29.5456 25.5903 30.0099 25.5903 30.2963 25.3091C30.5827 25.0279 30.5827 24.5721 30.2963 24.2909L20.5185 14.6909C20.2322 14.4097 19.7678 14.4097 19.4815 14.6909L9.70368 24.2909C9.41729 24.5721 9.41729 25.0279 9.70368 25.3091C9.99006 25.5903 10.4544 25.5903 10.7408 25.3091L19.8272 16.3879C19.873 16.3429 19.9352 16.3176 20 16.3176C20.0648 16.3176 20.127 16.3429 20.1728 16.3879L29.2592 25.3091Z"
        fill={color}
      />
    </svg>
  )
}
