import React from 'react'
import styles from '_revamp/styles/molecules/labelCard/common/common.module.scss'

type TCommonLabelProps = {
  title: string
  className?: string
}

export default function CommonLabel({ title, className }: TCommonLabelProps) {
  return (
    <div className={`${styles.container} ${styles.labelResult} ${className}`}>
      <span className={styles.microBold}>{title}</span>
    </div>
  )
}
