import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconFuelTank: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7132 6.78109C15.7024 5.70855 17.0843 5.10003 18.5307 5.10003H27.7673C30.6572 5.10003 33 7.48135 33 10.4189V30.6812C33 33.6187 30.6572 36 27.7673 36H13.315C10.425 36 8.08222 33.6187 8.08222 30.6812V16.0739C8.08222 14.7226 8.58823 13.4219 9.49748 12.4361L14.7132 6.78109ZM18.5307 7.63281C17.773 7.63281 17.0492 7.95156 16.531 8.51337L11.3153 14.1684C10.839 14.6847 10.574 15.366 10.574 16.0739V30.6812C10.574 32.2199 11.8012 33.4672 13.315 33.4672H27.7673C29.2811 33.4672 30.5082 32.2199 30.5082 30.6812V10.4189C30.5082 8.88017 29.2811 7.63281 27.7673 7.63281H18.5307Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4208 5.9775C16.9074 5.48294 17.6962 5.48294 18.1828 5.9775L22.497 10.3627C23.011 10.8852 23.7082 11.1787 24.4351 11.1787H31.7541C32.4422 11.1787 33 11.7457 33 12.4451C33 13.1445 32.4422 13.7115 31.7541 13.7115H24.4351C23.0473 13.7115 21.7163 13.1511 20.735 12.1536L16.4208 7.76845C15.9343 7.27389 15.9343 6.47206 16.4208 5.9775Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3215 6.6377L13.929 9.28812C14.4156 9.78268 15.2044 9.78268 15.691 9.28812C16.1775 8.79356 16.1775 7.99173 15.691 7.49717L12.9073 4.66765C12.0315 3.77745 10.6116 3.77745 9.73577 4.66765L7.65684 6.78079C6.78105 7.67099 6.78105 9.1143 7.65684 10.0045L10.4406 12.834C10.9271 13.3286 11.716 13.3286 12.2025 12.834C12.6891 12.3395 12.6891 11.5376 12.2025 11.0431L9.59499 8.39265L11.3215 6.6377Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8707 20.8489L19.1517 23.7611C19.1119 23.8286 19.0943 23.911 19.1041 23.9924C19.2469 25.1746 20.1168 25.7692 21.0766 25.7771C22.0279 25.785 22.8571 25.2207 22.9773 24.0594C22.9861 23.9745 22.9644 23.8891 22.9162 23.8191L20.8707 20.8489ZM25.4554 24.3246C25.5271 23.6323 25.3503 22.937 24.9576 22.3667L21.9871 18.0534C21.3869 17.1817 20.1024 17.2277 19.5639 18.14L17.015 22.458C16.6878 23.0124 16.5538 23.6626 16.6309 24.3011C16.9506 26.9473 19.0565 28.2933 21.0563 28.3098C23.0644 28.3265 25.1784 26.9993 25.4554 24.3246Z"
        fill={color}
      />
    </svg>
  )
}
