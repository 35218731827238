export const questions = [
  {
    title: 'Apa itu SEVA?',
    content:
      'SEVA adalah aplikasi yang siap membantu kamu dapatkan mobil idaman yang sesuai dengan anggaranmu. Melalui SEVA, kamu bisa mencari mobil yang cocok dengan anggaran kamu dengan proses yang cepat, mudah, dan aman. ',
  },
  {
    title: 'Bagaimana cara SEVA bantu kamu?',
    content:
      'SEVA dapat membantu kamu menemukan mobil terbaik dan pinjaman mobil yang tepat untuk membeli mobil tersebut dengan mempertimbangkan pendapatan bulanan kamu dan persyaratan pengajuan lainnya. ',
  },
  {
    title: 'Apakah SEVA adalah sales agent / agen penjualan mobil?',
    content:
      'SEVA adalah perusahaan yang bekerja sama dengan dealer-dealer resmi mobil dan perusahaan leasing / kredit untuk bantu kamu mendapatkan pinjaman/ kredit mobil dan mobil terbaik. Kami dapat membantu kamu selama proses pembelian mobil dari pencarian mobil yang tepat untukmu hingga pengajuan kredit mobil. ',
  },
  {
    title: 'Apakah SEVA adalah perusahaan keuangan?',
    content:
      'SEVA bukan merupakan perusahaan keuangan, tetapi kami bekerja sama dengan perusahaan leasing / kredit untuk mencari dan memberikan pinjaman terbaik untuk kamu. SEVA akan membantu kamu di setiap proses pengajuan pinjaman sampai akhirnya kamu mendapatkan pinjaman terbaik untuk mobil impianmu.',
  },
  {
    title: 'Apakah data yang saya berikan kepada SEVA aman?',
    content:
      'Ya, data kamu sepenuhnya aman dan tidak akan dibagikan dengan perusahaan di luar perusahaan grup kami.',
  },
  {
    title: 'Apakah SEVA merupakan servis / layanan berbayar?',
    content:
      'Seluruh layanan SEVA gratis! Tidak ada biaya tambahan untuk menggunakan SEVA dalam mendapatkan pinjaman mobil & mobil terbaik buat kamu. ',
  },
  {
    title: 'Bagaimana cara pengajuan pinjaman melalui SEVA?',
    content:
      '1. Isi suvei singkat tentang diri kamu \n' +
      '(survey image screenshot (2 page random))\n' +
      '2. Click ajukan pinjaman\n' +
      '(Congratulations - normal state screenshot) \n' +
      '3. Foto document yang diperlukan \n' +
      '(welcome screen and document upload screenshots)\n' +
      '4. Lacak pengajuan pinjaman kamu\n' +
      '(tracking page screenshot)\n',
  },
]
