import React from 'react'
import SevaStoryImage from '_revamp/assets/icon/seva-story.png'
import styles from '_revamp/styles/molecules/instagramPost.module.scss'
import VerifiedIcon from '_revamp/assets/icon/verified.png'

interface InstagramPostProps {
  image?: string
  url?: string
}

const InstagramPost: React.FC<InstagramPostProps> = ({ image, url }) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      className={styles.container}
    >
      <div className={styles.header}>
        <img src={SevaStoryImage} alt="seva" width="31px" />
        <p className={styles.instagramName}>sevabyastra</p>
        <img src={VerifiedIcon} alt="verified account icon" height={13} />
      </div>

      <div>
        <img
          src={image}
          alt="instagram post"
          className={`${styles.image}  instagramPostImage`}
        />
      </div>
    </a>
  )
}

export default InstagramPost
