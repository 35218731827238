import { removePageFromQuery } from '../../utils/urlUtils'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { QueryKeys, RedirectedPage } from '../../models/models'
import { useQuery } from '../../hooks/useQuery'

export const usePageRedirect = () => {
  const location = useLocation()
  const history = useHistory()

  const {
    page,
  }: {
    [QueryKeys.page]: RedirectedPage
  } = useQuery([QueryKeys.page])

  useEffect(() => {
    if (!page || !location.search.startsWith('?')) {
      return
    }
    history.replace(`${page}?${removePageFromQuery(location.search)}`)
  }, [location])
}
