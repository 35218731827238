import React from 'react'
import styles from '_revamp/styles/atoms/toast.module.scss'
import { IconChecked, IconWarningCircle } from '../icon'
import { Modal } from 'antd'
import { PropsToast } from '_revamp/utils/types'
import { colors } from 'styles/colors'
import { InfoCircleOutlined } from 'components/icon/InfoCircleOutlined/InfoCircleOutlined'

export const Toast: React.FC<PropsToast> = ({
  text,
  closeOnToastClick = false,
  onCancel,
  width,
  typeToast,
  ...props
}): JSX.Element => {
  const renderIcon = () => {
    if (typeToast === 'error' || typeToast === 'warning') {
      return (
        <IconWarningCircle
          width={32}
          height={32}
          color={
            typeToast === 'warning'
              ? colors.primaryDarkBlue
              : colors.secondaryBrickRed
          }
        />
      )
    } else if (typeToast === 'error2') {
      return (
        <div className={styles.icon}>
          <InfoCircleOutlined width={32} height={32} color={'#B4231E'} />
        </div>
      )
    } else {
      return (
        <div className={styles.icon}>
          <IconChecked width={32} height={32} color="#1AA674" />
        </div>
      )
    }
  }

  return (
    <Modal
      closable={false}
      centered
      className="toast-custom-modal"
      footer={null}
      width={width}
      maskStyle={{ background: 'rgba(19, 19, 27, 0.5)' }}
      {...props}
    >
      <div className={styles.bgModal} />
      <button
        onClick={(e) => closeOnToastClick && onCancel && onCancel(e)}
        className={styles.content}
      >
        {renderIcon()}
        <p className={styles.textToast}>{text}</p>
      </button>
    </Modal>
  )
}
