import React from 'react'
import { FloatButton, FloatButtonProps } from 'antd'
import { IconCSA } from '../../icon'
import { colors } from 'styles/colors'
import '../../../../styles/global.scss'

type CSAButtonProps = Omit<FloatButtonProps, 'icon'> & {
  additionalStyle?: string
}

export default (props: CSAButtonProps) => {
  return (
    <FloatButton
      className={`csa-button ${props.additionalStyle}`}
      icon={<IconCSA width={32} height={32} color={colors.primaryBlue} />}
      {...props}
    />
  )
}
