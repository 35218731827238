export enum ZIndex {
  'Overlay' = 10,
  'Appbar' = 20,
  'Menubar' = 30,
  'PageHeader' = 30,
  'WebAnnouncementBox' = 31,
  'FloatingIcon' = 35,
  'Modal' = 40,
  'Notification' = 50,
}
