import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconLink: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1479 7.79089C29.3216 4.96456 24.7392 4.96456 21.9129 7.79089L17.2168 12.487C16.9637 12.7401 16.7333 13.0072 16.5256 13.2859L20.1071 13.1322L23.6807 9.55865C25.5307 7.70864 28.5302 7.70864 30.3802 9.55866C32.2302 11.4087 32.2302 14.4081 30.3802 16.2582L25.684 20.9543C23.834 22.8043 20.8346 22.8043 18.9845 20.9543C18.7164 20.6861 18.4871 20.3938 18.2967 20.0843L15.5769 20.201C15.9294 21.1198 16.476 21.9813 17.2168 22.722C20.0431 25.5484 24.6255 25.5484 27.4518 22.722L32.1479 18.0259C34.9743 15.1996 34.9743 10.6172 32.1479 7.79089Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7871 17.1406C19.9558 14.3093 15.3653 14.3093 12.534 17.1406L7.8559 21.8187C5.0246 24.65 5.0246 29.2404 7.8559 32.0717L7.9895 32.2053C10.8208 35.0366 15.4112 35.0366 18.2425 32.2053L22.9207 27.5272C23.0819 27.3659 23.234 27.199 23.3769 27.0269L19.7624 27.15L16.4748 30.4376C14.6198 32.2925 11.6123 32.2925 9.75727 30.4376L9.62367 30.304C7.76867 28.449 7.76867 25.4414 9.62366 23.5864L14.3018 18.9083C16.1568 17.0533 19.1643 17.0533 21.0193 18.9083L21.1529 19.0419C21.3048 19.1939 21.4443 19.3535 21.5713 19.5196L24.4066 19.4231C24.0559 18.644 23.5606 17.9141 22.9207 17.2742L22.7871 17.1406Z"
        fill={color}
      />
    </svg>
  )
}
