import { PropsIcon } from '_revamp/utils/types'
import React from 'react'

const IconBackArrow = ({
  height = 24,
  width = 24,
  color = '#13131B',
  className,
}: PropsIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.71729 5L3.00021 12L9.71729 19"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="16.7331"
        y2="-1"
        transform="matrix(1 0 0 -1 3.26709 11.0317)"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconBackArrow
