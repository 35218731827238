import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import * as retryAxios from 'retry-axios'
import endpoints, { shouldCheckAuth } from '../helpers/endpoints'
import { Token, UTMTagsData } from '../types/types'
import {
  ErrorCode,
  HTTPResponseStatusCode,
  LocalStorageKey,
} from '../models/models'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import {
  removeInformationWhenLogout,
  validateAutoLoginRoute,
} from './logoutUtils'
import { getLocalStorage, saveLocalStorage } from './localstorageUtils'
import environments from '../helpers/environments'
import { clientInteractionNavigateToErrorPage } from './navigateToErrorPage'
import { LoginSevaUrl } from 'routes/routes'
import { savePageBeforeLogin } from './loginUtils'

const TIMEOUT_IN_MILLISECONDS = 60 * 1000

export const getToken = (): Token | null => {
  return getLocalStorage<Token>(LocalStorageKey.Token)
}

class APIService {
  constructor() {
    this.init()
  }
  init() {
    axios.defaults.baseURL = environments.apiBaseUrl
    axios.defaults.timeout = TIMEOUT_IN_MILLISECONDS

    axios.defaults.raxConfig = {
      retry: 3,
      statusCodesToRetry: [[500]],
      httpMethodsToRetry: ['GET', 'POST'],
      onRetryAttempt: (err: AxiosError) => {
        const cfg = retryAxios.getConfig(err)
        throw new Error(`Retry attempt #${cfg?.currentRetryAttempt}`)
      },
    }

    axios.interceptors.request.use(
      (request) => {
        const url = request.url
        const idToken = getToken()?.idToken
        if (url && shouldCheckAuth(url) && idToken) {
          request.headers.Authorization = idToken
        }
        return request
      },
      (error) => Promise.reject(error),
    )
    axios.interceptors.request.use(
      (request) => {
        const headers = request.headers
        const utmTags = getLocalStorage<UTMTagsData>(LocalStorageKey.UtmTags)
        request.headers = { ...headers, ...utmTags }
        return request
      },
      (error) => Promise.reject(error),
    )

    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error?.response?.config?.url !== endpoints.verifyCrmReferralCode) {
          clientInteractionNavigateToErrorPage(error?.response?.status)
        }
        return Promise.reject(error)
      },
    )

    axios.interceptors.response.use(
      (res) => {
        return res
      },
      async (err) => {
        const originalConfig = err.config
        const statusCode = err.response.status
        const refreshToken = getToken()?.refreshToken ?? ''

        if (statusCode === 401) {
          try {
            const response: any = await this.post(
              endpoints.refreshToken,
              { refreshToken },
              {
                skipAuthRefresh: true,
              } as AxiosAuthRefreshRequestConfig,
            )
            saveLocalStorage(
              LocalStorageKey.Token,
              JSON.stringify(response.data.data),
            )
            originalConfig._retry = true
            originalConfig.headers.Authorization = response.data.data.idToken
            return axios(originalConfig)
          } catch (e) {
            removeInformationWhenLogout()
            if (validateAutoLoginRoute()) {
              savePageBeforeLogin(window.location.pathname)
              return window.location.replace(LoginSevaUrl + '?source=loggedout')
            }
          }
        }
        return Promise.reject(err)
      },
    )

    retryAxios.attach()
  }

  refreshAuthLogic = async () => {
    try {
      const refreshToken = getToken()?.refreshToken ?? ''
      const response = await this.post(
        endpoints.refreshToken,
        { refreshToken },
        {
          skipAuthRefresh: true,
        } as AxiosAuthRefreshRequestConfig,
      )
      saveLocalStorage(LocalStorageKey.Token, JSON.stringify(response.data))
      return Promise.resolve()
    } catch (error: any) {
      removeInformationWhenLogout()
      if (validateAutoLoginRoute()) {
        Promise.reject({
          ...error,
          response: {
            status: HTTPResponseStatusCode.Unauthorized,
            data: {
              code: ErrorCode.AUTHENTICATION_FAILED,
              message: 'Failed to refresh token',
            },
          },
        })
        savePageBeforeLogin(window.location.pathname)
        return window.location.replace(LoginSevaUrl + '?source=loggedout')
      }
    }
  }

  get(url: string, config?: AxiosRequestConfig) {
    return axios.get(url, config)
  }

  post(
    url: string,
    data: Record<string, unknown> | string,
    config?: AxiosRequestConfig,
  ) {
    return axios.post(url, data, config)
  }

  put(url: string, data: Record<string, unknown>, config?: AxiosRequestConfig) {
    return axios.put(url, data, config)
  }
}

export const API = new APIService()
