import ReactGA from 'react-ga'

export const initGoogleAnalytics = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    ReactGA.initialize('UA-205000389-2')
  }
}
export const googleAnalyticsPageView = () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}
