import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconAgeRange: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41675 11C5.41675 8.65283 7.31954 6.75004 9.66675 6.75004H30.3334C32.6806 6.75004 34.5834 8.65283 34.5834 11V30.3334C34.5834 32.6806 32.6806 34.5834 30.3334 34.5834H9.66675C7.31953 34.5834 5.41675 32.6806 5.41675 30.3334V11ZM9.66675 9.25004C8.70025 9.25004 7.91675 10.0335 7.91675 11V30.3334C7.91675 31.2999 8.70025 32.0834 9.66675 32.0834H30.3334C31.2999 32.0834 32.0834 31.2999 32.0834 30.3334V11C32.0834 10.0335 31.2999 9.25004 30.3334 9.25004H9.66675Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3334 4.08337C14.0238 4.08337 14.5834 4.64302 14.5834 5.33337V8.00004C14.5834 8.6904 14.0238 9.25004 13.3334 9.25004C12.6431 9.25004 12.0834 8.6904 12.0834 8.00004V5.33337C12.0834 4.64302 12.6431 4.08337 13.3334 4.08337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6667 4.08337C27.3571 4.08337 27.9167 4.64302 27.9167 5.33337V8.00004C27.9167 8.6904 27.3571 9.25004 26.6667 9.25004C25.9764 9.25004 25.4167 8.6904 25.4167 8.00004V5.33337C25.4167 4.64302 25.9764 4.08337 26.6667 4.08337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.41675 14.6667C5.41675 13.9764 5.97639 13.4167 6.66675 13.4167H33.3334C34.0238 13.4167 34.5834 13.9764 34.5834 14.6667C34.5834 15.3571 34.0238 15.9167 33.3334 15.9167H6.66675C5.97639 15.9167 5.41675 15.3571 5.41675 14.6667Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1162 22.8839C15.628 22.3958 15.628 21.6043 16.1162 21.1162L19.1162 18.1162C19.6044 17.628 20.3958 17.628 20.884 18.1162L23.884 21.1162C24.3721 21.6043 24.3721 22.3958 23.884 22.8839C23.3958 23.3721 22.6044 23.3721 22.1162 22.8839L20.0001 20.7678L17.884 22.8839C17.3958 23.3721 16.6044 23.3721 16.1162 22.8839Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1162 25.1162C15.628 25.6043 15.628 26.3958 16.1162 26.8839L19.1162 29.8839C19.6044 30.3721 20.3958 30.3721 20.884 29.8839L23.884 26.8839C24.3721 26.3958 24.3721 25.6043 23.884 25.1162C23.3958 24.628 22.6044 24.628 22.1162 25.1162L20.0001 27.2323L17.884 25.1162C17.3958 24.628 16.6044 24.628 16.1162 25.1162Z"
        fill={color}
      />
    </svg>
  )
}
