import { useContext } from 'react'
import createDataContext from '../createDataContext'

interface PreApprovalButtonType {
  less: boolean
  pass: string
  recommendationInstallment: number
}

const { Context, Provider } = createDataContext<PreApprovalButtonType>({
  less: false,
  pass: 'less', //'less', 'not_pass', 'pass';
  recommendationInstallment: 0,
})

export const PreApprovalButtonContextProvider = Provider

export const useContextPreApprovalButton = () => {
  const { state, setState } = useContext(Context)

  const patchButtonType = (value: Partial<PreApprovalButtonType>) => {
    setState((preValue) => {
      return { ...preValue, ...value }
    })
  }
  return {
    buttonType: state,
    patchButtonType,
    setButtonType: setState,
  }
}
