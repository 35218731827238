import { useContext } from 'react'
import createDataContext from '../createDataContext'
import {
  MultKKCarRecommendation,
  SendMultiKualifikasiKreditResponse,
} from 'services/KK'

export const initEmptyData = {
  priceRangeGroup: '',
  downPaymentAmount: '',
  tenure: '',
  monthlyIncome: '',
  transmission: [] as string[],
  occupation: '',
  cityName: '',
  dob: '',
  multikkResponse: {} as SendMultiKualifikasiKreditResponse,
  filteredCarList: [] as MultKKCarRecommendation[],
  trxCode: '',
}

const { Context, Provider } = createDataContext<typeof initEmptyData>(
  initEmptyData,
)

export const MultiUnitQueryContextProvider = Provider

export const useMultiUnitQueryContext = () => {
  const { state, setState } = useContext(Context)
  return {
    multiUnitQuery: state,
    setMultiUnitQuery: setState,
  }
}
