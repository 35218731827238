import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconWhatsapp: React.FC<PropsIcon> = ({
  width,
  height,
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_100_13009)">
        <path
          d="M4.05664 36.0858L6.30164 27.89C4.91664 25.4916 4.18831 22.77 4.18914 19.9825C4.19331 11.2616 11.29 4.16663 20.0116 4.16663C24.2441 4.16829 28.2158 5.81579 31.2033 8.80496C34.19 11.795 35.835 15.7683 35.8333 19.995C35.83 28.7158 28.7316 35.8116 20.0116 35.8116C20.0108 35.8116 20.0116 35.8116 20.0116 35.8116H20.005C17.3575 35.8108 14.755 35.1466 12.4441 33.8858L4.05664 36.0858Z"
          fill="white"
        />
        <path
          d="M4.05668 36.5025C3.94668 36.5025 3.84002 36.4592 3.76085 36.3792C3.65668 36.2733 3.61585 36.1192 3.65502 35.9767L5.85418 27.9467C4.49085 25.525 3.77168 22.775 3.77335 19.9833C3.77668 11.0317 11.0608 3.75 20.0117 3.75C24.3533 3.75167 28.4325 5.4425 31.4983 8.51083C34.5642 11.58 36.2517 15.6583 36.25 19.995C36.2467 28.9458 28.9617 36.2283 20.0117 36.2283C17.3542 36.2275 14.725 35.5717 12.3917 34.3308L4.16252 36.4883C4.12752 36.4983 4.09252 36.5025 4.05668 36.5025Z"
          fill="white"
        />
        <path
          d="M20.0116 4.16671C24.2441 4.16837 28.2157 5.81587 31.2032 8.80504C34.1899 11.795 35.8349 15.7684 35.8332 19.995C35.8299 28.7159 28.7316 35.8117 20.0116 35.8117H20.0049C17.3574 35.8109 14.7549 35.1467 12.4441 33.8859L4.05658 36.0859L6.30158 27.89C4.91658 25.4917 4.18824 22.77 4.18908 19.9825C4.19324 11.2617 11.2899 4.16671 20.0116 4.16671ZM20.0116 3.33337C10.8316 3.33337 3.35991 10.8017 3.35574 19.9825C3.35491 22.7884 4.06324 25.5534 5.40658 28.0009L3.25241 35.8667C3.17408 36.1542 3.25408 36.4609 3.46408 36.6725C3.62241 36.8325 3.83658 36.92 4.05658 36.92C4.12741 36.92 4.19824 36.9109 4.26824 36.8925L12.3407 34.7759C14.6974 35.9992 17.3391 36.645 20.0049 36.6459C29.1916 36.6459 36.6632 29.1767 36.6674 19.9959C36.6691 15.5467 34.9382 11.3634 31.7941 8.21671C28.6482 5.06921 24.4641 3.33504 20.0116 3.33337Z"
          fill="#CFD8DC"
        />
        <path
          d="M29.3134 10.6934C26.8301 8.20838 23.5293 6.83921 20.0159 6.83838C12.7626 6.83838 6.86344 12.735 6.86011 19.9834C6.85927 22.4675 7.55427 24.8859 8.87094 26.98L9.18427 27.4775L7.85511 32.3284L12.8326 31.0234L13.3134 31.3084C15.3318 32.5067 17.6468 33.14 20.0068 33.1409H20.0118C27.2601 33.1409 33.1593 27.2434 33.1618 19.9942C33.1626 16.4817 31.7968 13.1784 29.3134 10.6934Z"
          fill="#40C351"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0568 13.3709C15.7609 12.7125 15.4493 12.6992 15.1668 12.6875C14.9359 12.6775 14.6726 12.6784 14.4093 12.6784C14.1459 12.6784 13.7176 12.7775 13.3551 13.1734C12.9926 13.5692 11.9709 14.525 11.9709 16.47C11.9709 18.415 13.3876 20.295 13.5851 20.5584C13.7826 20.8217 16.3201 24.9409 20.3384 26.5259C23.6776 27.8425 24.3576 27.5809 25.0826 27.515C25.8076 27.4492 27.4218 26.5592 27.7509 25.6359C28.0801 24.7125 28.0801 23.9217 27.9818 23.7567C27.8826 23.5917 27.6193 23.4934 27.2243 23.295C26.8293 23.0967 24.8851 22.1409 24.5226 22.0092C24.1601 21.8775 23.8968 21.8117 23.6326 22.2075C23.3693 22.6025 22.6118 23.4934 22.3809 23.7567C22.1501 24.0209 21.9193 24.0542 21.5243 23.8559C21.1293 23.6575 19.8559 23.2409 18.3451 21.8942C17.1701 20.8467 16.3768 19.5525 16.1459 19.1567C15.9151 18.7617 16.1209 18.5475 16.3193 18.35C16.4968 18.1725 16.7143 17.8884 16.9126 17.6575C17.1101 17.4267 17.1759 17.2617 17.3076 16.9984C17.4393 16.7342 17.3734 16.5034 17.2743 16.3059C17.1768 16.1075 16.4084 14.1525 16.0568 13.3709Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_100_13009">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
