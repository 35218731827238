import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconHome: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#B4231E',
}): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color}
    >
      <path
        d="M12.5 4.5667L12.8683 4.13604C12.7657 4.04824 12.6351 4 12.5 4C12.3649 4 12.2343 4.04824 12.1317 4.13604L12.5 4.5667ZM4.56667 11.3667L4.19833 10.936L4 11.106V11.3667H4.56667ZM10.2333 20.4334V21C10.3836 21 10.5278 20.9403 10.634 20.8341C10.7403 20.7278 10.8 20.5837 10.8 20.4334H10.2333ZM14.7667 20.4334H14.2C14.2 20.5837 14.2597 20.7278 14.366 20.8341C14.4722 20.9403 14.6164 21 14.7667 21V20.4334ZM20.4333 11.3667H21V11.106L20.8017 10.936L20.4333 11.3667ZM5.7 21H10.2333V19.8667H5.7V21ZM20.8017 10.936L12.8683 4.13604L12.1317 4.99737L20.065 11.7974L20.8017 10.936ZM12.1317 4.13604L4.19833 10.936L4.935 11.7974L12.8683 4.99737L12.1317 4.13604ZM10.8 20.4334V17.0334H9.66667V20.4334H10.8ZM14.2 17.0334V20.4334H15.3333V17.0334H14.2ZM14.7667 21H19.3V19.8667H14.7667V21ZM21 19.3V11.3667H19.8667V19.3H21ZM4 11.3667V19.3H5.13333V11.3667H4ZM12.5 15.3334C12.9509 15.3334 13.3833 15.5125 13.7021 15.8313C14.0209 16.1501 14.2 16.5825 14.2 17.0334H15.3333C15.3333 16.2819 15.0348 15.5613 14.5035 15.0299C13.9721 14.4985 13.2514 14.2 12.5 14.2V15.3334ZM12.5 14.2C11.7486 14.2 11.0279 14.4985 10.4965 15.0299C9.96518 15.5613 9.66667 16.2819 9.66667 17.0334H10.8C10.8 16.5825 10.9791 16.1501 11.2979 15.8313C11.6167 15.5125 12.0491 15.3334 12.5 15.3334V14.2ZM19.3 21C19.7509 21 20.1833 20.8209 20.5021 20.5021C20.8209 20.1833 21 19.7509 21 19.3H19.8667C19.8667 19.4503 19.807 19.5945 19.7007 19.7007C19.5944 19.807 19.4503 19.8667 19.3 19.8667V21ZM5.7 19.8667C5.54971 19.8667 5.40558 19.807 5.29931 19.7007C5.19304 19.5945 5.13333 19.4503 5.13333 19.3H4C4 19.7509 4.17911 20.1833 4.49792 20.5021C4.81673 20.8209 5.24913 21 5.7 21V19.8667Z"
        fill="#B4231E"
      />
    </svg>
  )
}
