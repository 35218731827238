const endpoints = {
  recommendations: '/recommendations',
  modelDetails: '/models/:id',
  variantDetails: '/variants/:id',
  loanPermutations: '/recommendations/variants/:id/loanPermutations',
  newFunnelLoanPermutations:
    '/recommendations/new-funnel/variants/:id/loanPermutations',
  sendSMS: '/auth/otp',
  otpFromWhatsapp: '/auth/otp/whatsapp',
  otpWhatsappCheckStatus: '/auth/otp/whatsapp/check-status/:id',
  otpFromSms: '/auth/otp/messages',
  verifyOTP: '/auth/verification',
  createCustomer: '/customers',
  customers: '/customers/me',
  unverifiedLead: '/unverifiedLeads',
  unverifiedLeadNew: '/unverifiedLeads/new',
  customerAssistantDetails: '/unverifiedLeads/csaDetails',
  newFunnelRecommendation: '/recommendations/new-funnel',
  newFunnelCityRecommendation: 'recommendations/get-city-by-model-name',
  startEkyc: '/ekyc/start',
  startEkycV2: '/ekyc/startV2',
  startEkycNew: '/ekyc/start/new/:leadId',
  ocr: '/ekyc/ocr',
  ocrNew: '/ekyc/ocr/new',
  ekycStatus: '/ekyc/status',
  ekycStatusV2: '/ekyc/statusV2',
  ekycStatusNew: '/ekyc/status/new/:leadId',
  uploadFile: '/customers/me/loan-documents',
  uploadFileNew: '/customers/me/loan-documents/new',
  preApproval: '/pre-approval',
  linkBrick: '/brick/authenticate',
  linkBrickRedirect: '/brick/redirect',
  popularCars: '/recommendations/popular-cars',
  submitPreApprovalCalcRequest: '/pre-approval/stage-three',
  submitPreApprovalCalcRequestNew: '/pre-approval/stage-three/new',
  preApprovalStageOneCheck: '/pre-approval/stage-one-check',
  preApprovalStageOneCheckNew: '/pre-approval/stage-one-check/new/',
  preApprovalStageTwoCheck: '/pre-approval/stage-two-check',
  preApprovalResult: '/customer-pre-approval/:id',
  preApprovalResultNew: '/customer-pre-approval/new/:leadId',
  refreshToken: '/auth/refresh-token',
  carModel: '/cars?query=',
  variantSuggestions: '/variants?query=',
  cities: '/city/fe-selector',
  loanCalculatorIncomeAdjustment: '/loan-calculator/check-dp-capacity',
  specialRate: '/loan-calculator/calculate',
  menu: '/menu',
  supportedBrowser: '/web-browser/check',
  checkRegistered: '/customers/check-customer-register',
  createCustomerSeva: '/customers/register',
  customersInfo: '/customers/info',
  testimonials: '/testimonials',
  checkPromoCodeGias: '/promo-code/status',
  crmCustomerRegistration: '/crm-customer/register',
  verifyCrmReferralCode: '/crm-customer/verify',
  companyBranch: '/branch/:id',
  crmCustomerQr: '/crm-customer/qr/:checkInCode',
  salesmanRegistration: '/salesman/register',
  crmCustomerSelfCheckin: '/crm-customer/self-checkin',
  crmCustomerNonSelfCheckin: '/crm-customer/non-self-checkin',
  salesmanQr: '/salesman/qr-detail/:salesQrcode',
  checkinVerify: '/crm-customer/checkin/verify',
  spkVerify: '/spk/verify',
  spkSubmit: '/spk/submit',
  giiasCarCollection: '/cars/giias-collection?city=jakarta',
  giiasCarBrand: '/cars/giias-collection/brand',
  carCollection: '/cars/collection',
  salesInfo: '/salesman/info',
  salesSpk: '/spk/get?limit=9999',
  carsBodyTypes: '/cars/body-type-data',
  bannerImage: '/banner-image',
  companyList: '/dealer/list',
  incomeList: '/recommendations/get-income-list',
  eventActive: '/event/active',
  updateProfile: '/customers/update-profile',
  preApprovalExternal: '/pre-approval/external',
  announcementBox: '/announcement-box',
  refinancingCarsBrand: '/refinancing-car/brand',
  refinancingCarsModel: '/refinancing-car/models/:model',
  refinancingCarsYear: '/refinancing-car/years',
  refinancingSecondLeadsForm: '/refinancing/product-choice',
  sendRefiContact: '/refinancing/contact',
  sendRefiQuestion: '/refinancing/question',
  carOfMonth: '/car-of-the-month',
  bannerHomepage: '/banner-image/homepage',
  paaSubmit: '/pa-ambassador/create',
  paaIAList: '/pa-ambassador/sales',
  paaIADetail: '/pa-ambassador/detail/',
  paaIADetailUpdate: '/pa-ambassador/update',
  carVideoReview: '/car-video-review',
  paaIAInfo: '/pa-ambassador/info/',
  paaIAInfoPreLogin: '/pa-ambassador/info/pre-login/',
  leadPtbc: '/unverifiedLeads/ptbc',
  preApprovalPtbc: '/pre-approval/ptbc/stage-one',
  preApprovalPaa: '/pa-ambassador/stage-one/',
  carsSearchBar: '/cars/search-bar',
  mobileWebTopMenu: '/menu/mobile-web-top-menu',
  bottomMenu: '/menu/desktop-web-bottom-menu',
  mobileBottomMenu: '/menu/mobile-web-bottom-menu',
  minMaxPrice: '/recommendations/get-price-min-max-by-city',
  deleteAccount: '/api/v1/archive-customer',
  uploadKTPFile: '/customers/ocr-ktp-new',
  saveKtp: '/customers/submit-ktp-new',
  qualificationCredit: '/pre-approval/new',
  customersKtp: '/customers/get-ktp-existing',
  customerSpouseKtp: '/customers/get-ktp-spouse-existing',
  loanCalculatorInsurance:
    '/loan-calculator-v2/insurance/:modelId/:cityCode/:tenure',
  kualiifikasiKredit: '/kualifikasi-kredit',
  checkNikAvailable: '/customers/check-ktp-availability',
  instantApproval: '/kualifikasi-kredit/ia',
  saveKtpSpouse: '/customers/submit-ktp-spouse-only',
  multiKualifikasiKredit: '/kualifikasi-kredit/multi',
  crmCustomerCheckin: '/crm-customer/checkin/:checkInCode',
  loanPermutationIncludePromo: '/loan-calculator-v2/calculate-included-promo',
  loanPermutationAsuransiKombinasi:
    '/loan-calculator-v2/calculate-asuransi-kombinasi',
  crmCustomerBuIntersectionOtr:
    '/crm-customer/bu-intersection-otr/:checkInCode',
  updateKtpCity: '/customers/update-ktp-city-existing',
  finalDpValidation:
    '/loan-calculator-v2/final-dp-validation/:variantId/:cityCode',
}

const protectedEndpointsPrefixes = [
  '/customers',
  '/ekyc',
  '/pre-approval',
  '/brick/authenticate',
  '/api/v1/archive-customer',
  '/customer-pre-approval',
]

export const shouldCheckAuth = (url: string): boolean =>
  protectedEndpointsPrefixes.some((prefix) => url.startsWith(prefix))

export default endpoints
