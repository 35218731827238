import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconX: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#fff',
  alt,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8621 8.6425L19.2908 0H17.5914L16.1366 1.68429L11.1045 7.54138L5.91357 0H0.023109L7.83342 11.3484L0 20.4659H1.69944L8.59098 12.4483L14.1097 20.466H20.0002L11.8621 8.6425ZM5.21336 1.33241H2.55762L14.8098 19.1334L17.4655 19.1333L5.21336 1.33241Z"
        fill={color}
      />
    </svg>
  )
}
