import { AxiosResponse } from 'axios'
import clsx from 'clsx'
import createDataContext from 'context/createDataContext'
import { useFunnelQueryData } from 'context/funnelQueryContext/funnelQueryContext'
import { TrackingEventName } from 'helpers/amplitude/eventTypes'
import {
  trackLandingPageSearchWidgetSubmit,
  trackSearchWidgetExpand,
} from 'helpers/amplitude/seva20Tracking'
import elementId from 'helpers/elementIds'
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage'
import { LocalStorageKey, SessionStorageKey } from 'models/models'
import React, {
  isValidElement,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { getMinMaxPrice } from 'services/newFunnel'
import { colors } from 'styles/colors'
import { MinMaxPrice } from 'types/types'
import { Currency } from 'utils/numberUtils/numberUtils'
import {
  Button,
  CardShadow,
  IconBrand,
  IconCar,
} from '_revamp/components/atoms'
import {
  IconAgeRange,
  IconDownPayment,
  IconIncome,
  IconMoney,
  IconPlus,
  IconTenure,
} from '_revamp/components/atoms/icon'
import { SelectWidget } from '_revamp/components/molecules'
import InputWidget from '_revamp/components/molecules/searchWidget/InputWidget'
import GridOptionWidget from '_revamp/components/molecules/searchWidget/option/gridOptionWidget'
import PriceRangeWidget from '_revamp/components/molecules/searchWidget/option/priceRangeWidget'
import TenureOptionWidget from '_revamp/components/molecules/searchWidget/option/tenureOptionWidget'
import {
  ageOptions,
  MinAmountMessage,
  RequiredFunnelErrorMessage,
} from '_revamp/config/funnel.config'
import styles from '_revamp/styles/organism/searchWidget.module.scss'
import { ButtonSize, ButtonVersion, MinAmount } from '_revamp/utils/enum'
import {
  FinancialFunnelWidgetError,
  FunnelWidget,
} from '_revamp/utils/types/widget'
import { useFinancialQueryData } from '../../../../context/financialQueryContext/financialQueryContext'
import { trackEventCountly } from 'helpers/countly/countly'
import { CountlyEventNames } from 'helpers/countly/eventNames'
import { PreviousButton, navigateToPLP } from '_revamp/utils/navigate'
import { saveSessionStorage } from 'utils/sessionstorageUtils'

export const initDataWidget = {
  downPaymentAmount: '',
  brand: ['Toyota', 'Daihatsu', 'Isuzu', 'BMW', 'Peugeot'],
  bodyType: ['MPV', 'SUV', 'Sedan', 'Hatchback', 'Sport'],
  priceRangeGroup: '',
  tenure: '5',
  age: '',
  monthlyIncome: '',
}

const initErrorFinancial = {
  downPaymentAmount: '',
  tenure: '',
  age: '',
  monthlyIncome: '',
}

const initEmptyDataWidget = {
  downPaymentAmount: '',
  brand: [],
  bodyType: [],
  priceRangeGroup: '',
  tenure: '',
  age: '',
  monthlyIncome: '',
}

const { Context } = createDataContext<FunnelWidget>(initEmptyDataWidget)
export const SearchWidgetContext = Context

export const SearchWidget = () => {
  const history = useHistory()
  const { financialQuery, patchFinancialQuery } = useFinancialQueryData()
  const [state, setState] = useState<FunnelWidget>(initEmptyDataWidget) // assume this state as Context widget, mind about re-render
  const contextValue = useMemo(() => ({ state, setState }), [state])
  const priceRangeRef = useRef() as MutableRefObject<HTMLDivElement>
  const { patchFunnelQuery } = useFunnelQueryData()
  const [storedFilter] = useLocalStorage<FunnelWidget>(
    LocalStorageKey.CarFilter,
    initEmptyDataWidget,
  )
  const [limitPrice, setLimitPrice] = useState({ min: 0, max: 0 })
  const [expandFinancial, setExpandFinancial] = useState(false)
  const [errorFinance, setErrorFinance] = useState<FinancialFunnelWidgetError>(
    initErrorFinancial,
  )

  const fetchMinMaxPrice = () => {
    getMinMaxPrice().then((response: AxiosResponse<MinMaxPrice>) => {
      setLimitPrice({
        min: response.data.minPriceValue,
        max: response.data.maxPriceValue,
      })
    })
  }

  const formatPriceRangePlaceholder = useMemo(() => {
    if (!state.priceRangeGroup)
      return `Rp${Currency(limitPrice.min)} - ${Currency(limitPrice.max)}`

    const splitPriceRange = state.priceRangeGroup.split('-')
    return `Rp${Currency(splitPriceRange[0])} - ${Currency(splitPriceRange[1])}`
  }, [state.priceRangeGroup, limitPrice])

  const limitMinimumDp = useMemo(() => {
    if (!state.priceRangeGroup) return limitPrice.min * 0.2

    const currentMinimumPrice = state.priceRangeGroup.split('-')[0]
    return Number(currentMinimumPrice) * (20 / 100)
  }, [state.priceRangeGroup, limitPrice.min])

  const limitMaximumDp = useMemo(() => {
    if (!state.priceRangeGroup) return limitPrice.max * (90 / 100)

    const currentMaximumPrice = state.priceRangeGroup.split('-')[1]
    return Number(currentMaximumPrice) * 0.9
  }, [state.priceRangeGroup, limitPrice.max])

  const errorLimitDP = ({
    text,
    gotoPriceRange,
  }: {
    text: string
    gotoPriceRange: () => void
  }) => (
    <div className={styles.dpError}>
      {text}
      <div className={styles.clickable} onClick={gotoPriceRange}>
        Atau sesuaikan harga mobil
      </div>
    </div>
  )

  const gotoPriceRange = () => {
    trackEventCountly(
      CountlyEventNames.WEB_HOMEPAGE_FILTER_DP_ADJUST_PRICE_CLICK,
      {
        DP_INPUT: `Rp${Currency(state.downPaymentAmount)}`,
        DP_SUGGESTION: `Rp${Currency(limitMinimumDp)}`,
      },
    )

    priceRangeRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
    setTimeout(() => {
      priceRangeRef.current.click()
    }, 400)
  }

  const errorDownPayment = (): JSX.Element | string => {
    if (!state.downPaymentAmount)
      return RequiredFunnelErrorMessage.downPaymentAmount

    if (Number(state.downPaymentAmount) < MinAmount.downPaymentAmount)
      return MinAmountMessage.downPayemntAmount

    if (Number(state.downPaymentAmount) < limitMinimumDp)
      return errorLimitDP({
        text: `Berdasarkan harga yang Anda pilih, min. DP 
      Rp${Currency(limitMinimumDp)}. `,
        gotoPriceRange,
      })

    if (Number(state.downPaymentAmount) > limitMaximumDp)
      return errorLimitDP({
        text: `Berdasarkan harga yang Anda pilih, maks. DP 
      Rp${Currency(limitMaximumDp)}. `,
        gotoPriceRange,
      })

    return ''
  }

  const errorIncome = () => {
    if (!state.monthlyIncome) return RequiredFunnelErrorMessage.monthlyIncome

    if (Number(state.monthlyIncome) < MinAmount.monthlyIncome)
      return MinAmountMessage.monthlyIncome

    return ''
  }

  const brandTypePlaceholder = (type: 'brand' | 'bodyType') => {
    if (state[type].length > 0) {
      return state[type].join(', ')
    }

    return initDataWidget[type].join(', ')
  }

  const checkFinancialEmpty = () => {
    //check if user not open/expand financial form
    if (!expandFinancial) return true

    const { age, downPaymentAmount, monthlyIncome, tenure } = state

    // check if user not fill all financial form
    if (!age && !downPaymentAmount && !monthlyIncome && !tenure) return true

    // check if user fill all financial form
    if (age && !errorDownPayment() && !errorIncome() && tenure) return true

    setErrorFinance((prev) => ({
      ...prev,
      downPaymentAmount: errorDownPayment(),
      monthlyIncome: errorIncome(),
      ...(!age && { age: RequiredFunnelErrorMessage.age }),
      ...(!tenure && { tenure: RequiredFunnelErrorMessage.tenure }),
    }))

    return false
  }

  const dataForCountlyTrackerOnClick = () => {
    const tempPriceRange = formatPriceRangePlaceholder
      .replaceAll(' ', '')
      .replaceAll('Rp', '')

    return {
      CAR_BRAND: brandTypePlaceholder('brand'),
      CAR_TYPE: brandTypePlaceholder('bodyType'),
      MIN_PRICE: !!tempPriceRange
        ? `Rp${Currency(tempPriceRange.split('-')[0])}`
        : 'Null',
      MAX_PRICE: !!tempPriceRange
        ? `Rp${Currency(tempPriceRange.split('-')[1])}`
        : 'Null',
      DP_AMOUNT:
        expandFinancial && !!state.downPaymentAmount
          ? `Rp${Currency(state.downPaymentAmount)}`
          : 'Null',
      TENOR_OPTION:
        expandFinancial && !!state.tenure ? `${state.tenure} tahun` : 'Null',
      INCOME_AMOUNT:
        expandFinancial && !!state.monthlyIncome
          ? `Rp${Currency(state.monthlyIncome)}`
          : 'Null',
      AGE_RANGE: expandFinancial && !!state.age ? `${state.age} Tahun` : 'Null',
    }
  }

  const trackCountlyClickCta = () => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_CAR_SEARCH_BUTTON_CLICK, {
      SOURCE_SECTION: 'Smart search',
      ...dataForCountlyTrackerOnClick(),
    })
  }

  const submit = () => {
    if (!checkFinancialEmpty()) return
    const {
      age,
      downPaymentAmount,
      monthlyIncome,
      priceRangeGroup,
      bodyType,
      brand,
      tenure,
    } = state
    const splitPriceRange = priceRangeGroup.split('-')
    const maxPrice = priceRangeGroup ? splitPriceRange[1] : 0
    const sortHighToLow = maxPrice && Number(maxPrice) < limitPrice.max
    const urlParam = new URLSearchParams({
      ...(expandFinancial && age && { age }),
      ...(expandFinancial && downPaymentAmount && { downPaymentAmount }),
      ...(expandFinancial && monthlyIncome && { monthlyIncome }),
      ...(priceRangeGroup && { priceRangeGroup }),
      ...(bodyType &&
        bodyType.length > 0 && {
          bodyType: bodyType.map((item) => item.toUpperCase()).join(','),
        }),
      ...(brand && brand.length > 0 && { brand: brand.join(',') }),
      ...(expandFinancial && tenure && { tenure }),
      ...(sortHighToLow && { sortBy: 'highToLow' }),
    }).toString()

    const dataFinancial = {
      ...financialQuery,
      ...(expandFinancial && age && { age }),
      ...(expandFinancial && downPaymentAmount && { downPaymentAmount }),
      ...(expandFinancial && monthlyIncome && { monthlyIncome }),
      ...(expandFinancial && tenure && { tenure }),
    }

    if (expandFinancial) {
      patchFinancialQuery(dataFinancial)
      patchFunnelQuery({ ...state, filterFincap: true })
      saveSessionStorage(SessionStorageKey.IsShowBadgeCreditOpportunity, 'true')
    } else {
      patchFunnelQuery({
        brand,
        bodyType,
        priceRangeGroup,
        downPaymentAmount: '',
        tenure: 5,
        age: '',
        monthlyIncome: '',
        filterFincap: false,
      })
      saveSessionStorage(
        SessionStorageKey.IsShowBadgeCreditOpportunity,
        'false',
      )
    }

    trackLandingPageSearchWidgetSubmit({
      ...(brand && brand.length > 0 && { Car_Brand: brand.join(', ') }),
      ...(bodyType &&
        bodyType.length > 0 && {
          Car_Body_Type: bodyType.map((item) => item.toUpperCase()).join(','),
        }),
      ...(priceRangeGroup && {
        Price_Range: `Rp${Currency(splitPriceRange[0])} - Rp${Currency(
          splitPriceRange[1],
        )}`,
      }),
      ...(expandFinancial &&
        downPaymentAmount && { DP: `Rp${Currency(downPaymentAmount)}` }),
      ...(expandFinancial && tenure && { Tenure: tenure }),
      ...(expandFinancial &&
        monthlyIncome && { Income: `Rp${Currency(monthlyIncome)}` }),
      ...(expandFinancial && age && { Age: age }),
    })

    trackCountlyClickCta()

    navigateToPLP(PreviousButton.SmartSearch, history, { search: urlParam })
  }

  useEffect(() => {
    const currentFinancial: { [key: string]: string } = {}

    if (storedFilter.monthlyIncome)
      currentFinancial.monthlyIncome = storedFilter.monthlyIncome
    if (storedFilter.age) currentFinancial.age = storedFilter.age
    if (storedFilter.tenure) currentFinancial.tenure = storedFilter.tenure
    if (storedFilter.downPaymentAmount)
      currentFinancial.downPaymentAmount = storedFilter.downPaymentAmount

    setState((prev) => ({ ...prev, ...currentFinancial }))
    patchFunnelQuery({ filterFincap: false })

    fetchMinMaxPrice()
  }, [])

  useEffect(() => {
    const changeFinanceState: { [key: string]: string } = {}
    if (state.age) changeFinanceState.age = ''
    if (state.tenure) changeFinanceState.tenure = ''

    setErrorFinance((prev) => ({ ...prev, ...changeFinanceState }))
  }, [state.age, state.tenure])

  useEffect(() => {
    let downPaymentAmount = ''
    if (state.downPaymentAmount) {
      if (Number(state.downPaymentAmount) < MinAmount.downPaymentAmount) {
        downPaymentAmount = MinAmountMessage.downPayemntAmount
      } else {
        if (!isValidElement(errorFinance.downPaymentAmount))
          downPaymentAmount = ''
      }
    }

    setErrorFinance((prev) => ({ ...prev, downPaymentAmount }))
  }, [state.downPaymentAmount])

  useEffect(() => {
    let monthlyIncome = ''
    if (state.monthlyIncome) {
      monthlyIncome = errorIncome()
    }

    setErrorFinance((prev) => ({ ...prev, monthlyIncome }))
  }, [state.monthlyIncome])

  const FinancialEntry = () => {
    if (expandFinancial)
      return (
        <>
          <div className={styles.expandFinancialClose}>
            <h3 className={styles.title}>Filter Finansial</h3>
            <span
              className={styles.closeText}
              onClick={() => {
                trackSearchWidgetExpand(
                  TrackingEventName.WEB_LP_SEARCHWIDGET_FILTER_FINANSIAL_COLLAPSE,
                )
                trackEventCountly(
                  CountlyEventNames.WEB_HOMEPAGE_ADD_FINANCIAL_FILTER_CLOSE,
                )
                setErrorFinance(initErrorFinancial)
                setExpandFinancial(false)
              }}
            >
              Tutup
            </span>
          </div>
          <span
            className={`${styles.expandFinancialInfo} ${styles.expandFinancialInfoClose}`}
          >
            Isi semua data di bawah ini untuk melihat peluang
            <br />
            pinjamanmu akan disetujui.
          </span>
        </>
      )

    return (
      <>
        <Button
          version={ButtonVersion.Secondary}
          size={ButtonSize.Small}
          onClick={() => {
            trackEventCountly(
              CountlyEventNames.WEB_HOMEPAGE_ADD_FINANCIAL_FILTER_CLICK,
            )
            trackSearchWidgetExpand(
              TrackingEventName.WEB_LP_SEARCHWIDGET_FILTER_FINANSIAL_EXPAND,
            )
            setExpandFinancial(true)
          }}
          data-testid={elementId.Homepage.Button.TambahFilterFinansial}
        >
          <div className={styles.expandFinancialButton}>
            <IconPlus width={16} height={16} />
            <span>Tambah Filter Finansial</span>
          </div>
        </Button>
        <span
          className={`${styles.expandFinancialInfo} ${styles.expandFinancialInfoOpen}`}
        >
          Isi data tambahan untuk melihat peluang pinjamanmu.
        </span>
      </>
    )
  }

  const trackCountlyOnSubmitCarBrand = (checkedOption: string[]) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_FILTER_APPLY_CLICK, {
      FILTER_TYPE: 'Car Brand',
      ...dataForCountlyTrackerOnClick(),
      CAR_BRAND: checkedOption.join(', '),
      AGE_RANGE: expandFinancial && !!state.age ? `${state.age}` : 'Null',
    })
  }

  const trackCountlyOnSubmitCarBodyType = (checkedOption: string[]) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_FILTER_APPLY_CLICK, {
      FILTER_TYPE: 'Car Type',
      ...dataForCountlyTrackerOnClick(),
      CAR_TYPE: checkedOption.join(', '),
      AGE_RANGE: expandFinancial && !!state.age ? `${state.age}` : 'Null',
    })
  }

  const trackCountlyOnSubmitPriceRange = (min: number, max: number) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_FILTER_APPLY_CLICK, {
      FILTER_TYPE: 'Price Range',
      ...dataForCountlyTrackerOnClick(),
      MIN_PRICE: `Rp${Currency(min)}`,
      MAX_PRICE: `Rp${Currency(max)}`,
      AGE_RANGE: expandFinancial && !!state.age ? `${state.age}` : 'Null',
    })
  }

  const trackCountlyOnReset = (type: string) => {
    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_FILTER_BRAND_RESET_CLICK, {
      FILTER_TYPE: type,
    })
  }

  return (
    <SearchWidgetContext.Provider value={contextValue}>
      <div className={styles.container}>
        <CardShadow
          className={clsx({
            [styles.cardContainer]: true,
            [styles.expandCard]: expandFinancial,
          })}
        >
          <SelectWidget
            title="Merek"
            placeholder={brandTypePlaceholder('brand')}
            icon={
              <IconBrand
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
                alt={'SEVA Brand Icon Merek'}
              />
            }
            sheetOption={({ onClose }) => (
              <GridOptionWidget
                onClose={onClose}
                type="brand"
                errorToastMessage="Silahkan pilih salah satu merk mobil"
                trackCountlyOnSubmit={trackCountlyOnSubmitCarBrand}
                trackCountlyOnReset={() => trackCountlyOnReset('Car Brand')}
              />
            )}
            datatestid={elementId.FilterMerek}
          />
          <SelectWidget
            title="Tipe"
            placeholder={brandTypePlaceholder('bodyType')}
            icon={
              <IconCar
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
                alt={'SEVA Car Type Icon'}
              />
            }
            sheetOption={({ onClose }) => (
              <GridOptionWidget
                onClose={onClose}
                type="bodyType"
                errorToastMessage="Silahkan pilih salah satu tipe mobil"
                trackCountlyOnSubmit={trackCountlyOnSubmitCarBodyType}
                trackCountlyOnReset={() => trackCountlyOnReset('Car Type')}
              />
            )}
            datatestid={elementId.FilterType}
          />
          <SelectWidget
            ref={priceRangeRef}
            title="Estimasi Harga"
            placeholder={formatPriceRangePlaceholder}
            icon={
              <IconMoney
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
                alt={'SEVA Money Icon Estimasi Harga'}
              />
            }
            sheetOption={({ onClose }) => (
              <PriceRangeWidget
                onClose={onClose}
                limitPrice={limitPrice}
                trackCountlyOnSubmit={trackCountlyOnSubmitPriceRange}
                trackCountlyOnReset={() => trackCountlyOnReset('Price Range')}
              />
            )}
            datatestid={elementId.FilterHarga}
          />
          {FinancialEntry()}
          <InputWidget
            name="downPaymentAmount"
            title="Maksimum DP"
            value={state.downPaymentAmount}
            icon={
              <IconDownPayment
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
              />
            }
            placeholder="Masukkan DP"
            errorText={errorFinance.downPaymentAmount}
            datatestid={elementId.Field.DP}
          />
          <SelectWidget
            title="Tenor (tahun)"
            placeholder={state.tenure ? state.tenure + ' Tahun' : 'Pilih Tenor'}
            icon={
              <IconTenure
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
              />
            }
            sheetOption={({ onClose }) => (
              <TenureOptionWidget onClose={onClose} />
            )}
            errorText={errorFinance.tenure}
            datatestid={elementId.Field.Tenure}
          />
          <InputWidget
            name="monthlyIncome"
            title="Pendapatan Bulanan"
            value={state.monthlyIncome}
            icon={
              <IconIncome
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
              />
            }
            placeholder="Masukkan Pendapatan"
            errorText={errorFinance.monthlyIncome}
            datatestid={elementId.Field.Income}
          />
          <SelectWidget
            title="Kategori Umur"
            placeholder={state.age || 'Pilih Kategori Umur'}
            value={state.age}
            name="age"
            icon={
              <IconAgeRange
                width={32}
                height={32}
                color={colors.secondaryBrickRed}
              />
            }
            sheetList={ageOptions}
            errorText={errorFinance.age}
            datatestid={elementId.Homepage.Dropdown.KategoriUmur}
            optionDatatestId={elementId.Field.Age}
          />
        </CardShadow>
        <div className={styles.buttonWrapper}>
          <Button
            version={ButtonVersion.PrimaryDarkBlue}
            size={ButtonSize.Big}
            onClick={submit}
            data-testid={elementId.Homepage.Button.CariMobil}
          >
            Cari Mobil
          </Button>
        </div>
      </div>
    </SearchWidgetContext.Provider>
  )
}
