import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconVoucher: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  className,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2687 24.5407C20.7805 24.0526 20.7805 23.2611 21.2687 22.773L22.6829 21.3588C23.171 20.8706 23.9625 20.8706 24.4506 21.3588C24.9388 21.8469 24.9388 22.6384 24.4506 23.1265L23.0364 24.5407C22.5483 25.0289 21.7568 25.0289 21.2687 24.5407Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71231 21.7123C3.66206 19.6621 3.66206 16.3379 5.71231 14.2877L7.48008 12.5199C8.55402 11.446 10.2952 11.446 11.3692 12.5199C11.4668 12.6176 11.6251 12.6176 11.7227 12.5199L12.4298 11.8128C12.5275 11.7152 12.5275 11.5569 12.4298 11.4593C11.3559 10.3853 11.3559 8.64412 12.4298 7.57018L14.1976 5.80241C16.2478 3.75216 19.572 3.75216 21.6222 5.80241L28.163 12.3431L26.3952 14.1109L19.8544 7.57018C18.7805 6.49623 17.0393 6.49623 15.9654 7.57018L14.1976 9.33794C14.1 9.43557 14.1 9.59386 14.1976 9.6915C15.2715 10.7654 15.2715 12.5066 14.1976 13.5806L13.4905 14.2877C12.4165 15.3616 10.6753 15.3616 9.6014 14.2877C9.50377 14.1901 9.34548 14.1901 9.24784 14.2877L7.48008 16.0555C6.40614 17.1294 6.40614 18.8706 7.48008 19.9445L14.0208 26.4853L12.253 28.253L5.71231 21.7123ZM17.5563 33.5563L18.4402 34.4402C20.4905 36.4905 23.8146 36.4905 25.8649 34.4402L27.6326 32.6725C28.7066 31.5985 28.7066 29.8573 27.6326 28.7834C27.535 28.6857 27.535 28.5275 27.6326 28.4298L28.3397 27.7227C28.4374 27.6251 28.5956 27.6251 28.6933 27.7227C29.7672 28.7967 31.5084 28.7967 32.5824 27.7227L34.3501 25.955C36.4004 23.9047 36.4004 20.5806 34.3501 18.5303L33.4663 17.6464L31.6985 19.4142L32.5824 20.2981C33.6563 21.372 33.6563 23.1132 32.5824 24.1872L30.8146 25.955C30.717 26.0526 30.5587 26.0526 30.461 25.955C29.3871 24.881 27.6459 24.881 26.572 25.955L25.8649 26.6621C24.7909 27.736 24.7909 29.4772 25.8649 30.5511C25.9625 30.6488 25.9625 30.8071 25.8649 30.9047L24.0971 32.6725C23.0231 33.7464 21.2819 33.7464 20.208 32.6725L19.3241 31.7886L17.5563 33.5563Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2087 19.5812C25.726 19.0877 25.7347 18.2963 26.2282 17.8135L27.6578 16.4151C28.1513 15.9323 28.9427 15.941 29.4255 16.4345C29.9082 16.928 29.8995 17.7194 29.406 18.2022L27.9764 19.6007C27.4829 20.0834 26.6915 20.0747 26.2087 19.5812Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3189 29.4905C15.8308 29.0023 15.8308 28.2109 16.3189 27.7227L17.7331 26.3085C18.2213 25.8203 19.0127 25.8203 19.5009 26.3085C19.989 26.7967 19.989 27.5881 19.5009 28.0763L18.0867 29.4905C17.5985 29.9786 16.8071 29.9786 16.3189 29.4905Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3692 25.6014C11.8573 25.1132 12.6488 25.1132 13.1369 25.6014L14.5511 27.0156C15.0393 27.5038 15.0393 28.2952 14.5511 28.7834C14.063 29.2715 13.2715 29.2715 12.7834 28.7834L11.3692 27.3692C10.881 26.881 10.881 26.0896 11.3692 25.6014Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.026 31.2583C17.5142 30.7701 18.3056 30.7701 18.7938 31.2583L20.208 32.6725C20.6962 33.1606 20.6962 33.9521 20.208 34.4402C19.7198 34.9284 18.9284 34.9284 18.4402 34.4402L17.026 33.026C16.5379 32.5379 16.5379 31.7464 17.026 31.2583Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5113 11.4593C25.9995 10.9711 26.7909 10.9711 27.2791 11.4593L28.6933 12.8735C29.1814 13.3616 29.1814 14.1531 28.6933 14.6412C28.2051 15.1294 27.4137 15.1294 26.9255 14.6412L25.5113 13.227C25.0231 12.7389 25.0231 11.9474 25.5113 11.4593Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1682 17.1161C31.6563 16.628 32.4478 16.628 32.9359 17.1161L34.3501 18.5303C34.8383 19.0185 34.8383 19.8099 34.3501 20.2981C33.862 20.7863 33.0705 20.7863 32.5824 20.2981L31.1682 18.8839C30.68 18.3957 30.68 17.6043 31.1682 17.1161Z"
        fill={color}
      />
    </svg>
  )
}
