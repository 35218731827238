import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const Star: React.FC<PropsIcon> = ({
  width = 17,
  height = 18,
  color = '#F1C551',
}): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.80622 18H8.69378C8.69378 13.3044 12.1957 9.48412 16.5 9.48412V8.51587C12.1957 8.51587 8.69378 4.69558 8.69378 0H7.80622C7.80622 4.69558 4.30429 8.51587 0 8.51587V9.48412C4.30429 9.48412 7.80622 13.3044 7.80622 18Z"
        fill={color}
      />
    </svg>
  )
}
