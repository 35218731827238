import { LocalStorageKey } from 'models/models'
import { rootUrl, waitingCreditQualificationUrl } from 'routes/routes'

export const restrictedAutoNavigationRoutes = [waitingCreditQualificationUrl]

export const isGoingToRestrictedRoutes = (route: string) => {
  return restrictedAutoNavigationRoutes.includes(route)
}

export const getPageBeforeLogin = () => {
  const destinationRoute = localStorage.getItem(LocalStorageKey.PageBeforeLogin)
  if (destinationRoute && !isGoingToRestrictedRoutes(destinationRoute)) {
    return destinationRoute
  } else {
    return rootUrl
  }
}

export const savePageBeforeLogin = (page: string) => {
  localStorage.setItem(LocalStorageKey.PageBeforeLogin, page)
}

export const getPageBeforeLoginExternal = () => {
  const destinationRoute = localStorage.getItem(
    LocalStorageKey.PageBeforeLoginExternal,
  )
  if (destinationRoute && !isGoingToRestrictedRoutes(destinationRoute)) {
    return destinationRoute
  } else {
    return ''
  }
}

export const savePageBeforeLoginExternal = (page: string) => {
  localStorage.setItem(LocalStorageKey.PageBeforeLoginExternal, page)
}
