import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconWishlist: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 15.0149L10.8 17.3212C11.0857 17.4493 11.3571 17.4212 11.6142 17.2366C11.8714 17.0528 12 16.7847 12 16.4323V2.52196C12 1.99341 11.8323 1.54079 11.4969 1.16408C11.1608 0.788009 10.7571 0.599976 10.2857 0.599976H1.71428C1.24286 0.599976 0.839428 0.788009 0.503995 1.16408C0.168001 1.54079 0 1.99341 0 2.52196V16.4323C0 16.7847 0.128567 17.0528 0.385712 17.2366C0.642856 17.4212 0.914285 17.4493 1.2 17.3212L6 15.0149ZM1.5 15.5129L6 13.3507L10.5 15.5129V2.52196C10.5 2.34266 10.4553 2.25026 10.3775 2.1626C10.3401 2.12093 10.317 2.10551 10.3101 2.10151C10.3072 2.10101 10.2995 2.09998 10.2857 2.09998H1.71428C1.70101 2.09998 1.69338 2.10094 1.69028 2.10144C1.68311 2.10571 1.66017 2.12132 1.62343 2.16251M1.5 15.5129V2.52196C1.5 2.3427 1.54489 2.25057 1.62343 2.16251"
        fill={color}
      />
    </svg>
  )
}
