import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './localization/i18n'
import './index.css'
import { ResetStyle } from './styles/ResetStyle'
import { GlobalStyle } from './styles/GlobalStyle'
import { BrowserRouter } from 'react-router-dom'

import { App } from './App'

import * as smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)
root.render(
  <BrowserRouter>
    <ResetStyle />
    <GlobalStyle />
    <App />
  </BrowserRouter>,
)
