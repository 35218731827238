import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconTenure: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8556 21.7705C29.0376 21.5268 28.1795 21.4467 27.3305 21.5346C26.4814 21.6225 25.658 21.8768 24.9072 22.2829C24.1564 22.6891 23.493 23.2391 22.9548 23.9017C22.4166 24.5642 22.0142 25.3263 21.7705 26.1444C21.5268 26.9624 21.4467 27.8205 21.5346 28.6695C21.6225 29.5186 21.8768 30.342 22.2829 31.0928C22.6891 31.8436 23.2392 32.507 23.9017 33.0452C24.5642 33.5834 25.3263 33.9858 26.1444 34.2295C26.9624 34.4732 27.8205 34.5533 28.6695 34.4654C29.5186 34.3775 30.342 34.1232 31.0928 33.7171C31.8436 33.3109 32.507 32.7609 33.0452 32.0983C33.5834 31.4358 33.9858 30.6737 34.2295 29.8556C34.4732 29.0376 34.5534 28.1795 34.4654 27.3305C34.3775 26.4814 34.1232 25.658 33.7171 24.9072C33.3109 24.1564 32.7609 23.493 32.0983 22.9548C31.4358 22.4166 30.6737 22.0142 29.8556 21.7705ZM27.0729 19.0479C28.2486 18.9261 29.4366 19.0371 30.5693 19.3745C31.7021 19.712 32.7572 20.2692 33.6746 21.0144C34.5919 21.7596 35.3536 22.6781 35.9159 23.7177C36.4783 24.7572 36.8304 25.8973 36.9521 27.0729C37.0739 28.2486 36.9629 29.4366 36.6255 30.5693C36.288 31.7021 35.7308 32.7572 34.9856 33.6746C34.2404 34.5919 33.3218 35.3536 32.2823 35.9159C31.2428 36.4783 30.1027 36.8304 28.9271 36.9521C27.7514 37.0739 26.5634 36.9629 25.4307 36.6255C24.2979 36.288 23.2428 35.7308 22.3254 34.9856C21.4081 34.2404 20.6464 33.3219 20.0841 32.2823C19.5217 31.2428 19.1696 30.1027 19.0479 28.9271C18.9261 27.7514 19.0371 26.5634 19.3745 25.4307C19.712 24.2979 20.2692 23.2428 21.0144 22.3254C21.7596 21.4081 22.6781 20.6464 23.7177 20.0841C24.7572 19.5217 25.8973 19.1696 27.0729 19.0479Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 23.75C28.6904 23.75 29.25 24.3096 29.25 25V28.4822L30.8839 30.1161C31.372 30.6043 31.372 31.3957 30.8839 31.8839C30.3957 32.372 29.6043 32.372 29.1161 31.8839L27.1161 29.8839C26.8817 29.6495 26.75 29.3315 26.75 29V25C26.75 24.3096 27.3096 23.75 28 23.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10.25C8.0335 10.25 7.25 11.0335 7.25 12V28C7.25 28.9665 8.0335 29.75 9 29.75H17.3898C18.0802 29.75 18.6398 30.3096 18.6398 31C18.6398 31.6904 18.0802 32.25 17.3898 32.25H9C6.65279 32.25 4.75 30.3472 4.75 28V12C4.75 9.65279 6.65279 7.75 9 7.75H31C33.3472 7.75 35.25 9.65279 35.25 12V18.5C35.25 19.1904 34.6904 19.75 34 19.75C33.3096 19.75 32.75 19.1904 32.75 18.5V12C32.75 11.0335 31.9665 10.25 31 10.25H9Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.75C11.6904 3.75 12.25 4.30964 12.25 5V9C12.25 9.69036 11.6904 10.25 11 10.25C10.3096 10.25 9.75 9.69036 9.75 9V5C9.75 4.30964 10.3096 3.75 11 3.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 3.75C29.6904 3.75 30.25 4.30964 30.25 5V9C30.25 9.69036 29.6904 10.25 29 10.25C28.3096 10.25 27.75 9.69036 27.75 9V5C27.75 4.30964 28.3096 3.75 29 3.75Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 16C5.25 15.3096 5.80964 14.75 6.5 14.75H33.5C34.1904 14.75 34.75 15.3096 34.75 16C34.75 16.6904 34.1904 17.25 33.5 17.25H6.5C5.80964 17.25 5.25 16.6904 5.25 16Z"
        fill={color}
      />
    </svg>
  )
}
