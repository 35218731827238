import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconInfo: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
  onClick,
  alt,
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9999 6.58331C12.5901 6.58331 6.58325 12.5902 6.58325 20C6.58325 27.4098 12.5901 33.4166 19.9999 33.4166C27.4097 33.4166 33.4166 27.4098 33.4166 20C33.4166 12.5902 27.4097 6.58331 19.9999 6.58331ZM4.08325 20C4.08325 11.2094 11.2094 4.08331 19.9999 4.08331C28.7904 4.08331 35.9166 11.2094 35.9166 20C35.9166 28.7905 28.7904 35.9166 19.9999 35.9166C11.2094 35.9166 4.08325 28.7905 4.08325 20Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.194 27.2475C19.5039 27.2652 18.9301 26.72 18.9124 26.0299L18.7418 19.3654C18.7241 18.6753 19.2693 18.1015 19.9594 18.0838C20.6495 18.0662 21.2233 18.6113 21.241 19.3014L21.4116 25.9659C21.4293 26.656 20.8842 27.2298 20.194 27.2475Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8869 15.2514C19.1967 15.2691 18.6229 14.724 18.6053 14.0338L18.5882 13.3674C18.5705 12.6773 19.1157 12.1035 19.8058 12.0858C20.4959 12.0681 21.0697 12.6133 21.0874 13.3034L21.1045 13.9698C21.1221 14.66 20.577 15.2338 19.8869 15.2514Z"
        fill={color}
      />
    </svg>
  )
}
