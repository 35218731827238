import React from 'react'
import { PropsIcon } from '../../../utils/types'

export const IconCSA: React.FC<PropsIcon> = ({
  width,
  height,
  color = '#05256E',
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.989 9.49051C14.6188 7.66931 17.0776 6.67487 19.6238 6.60938C22.1699 6.54389 24.6775 7.41061 26.4 9.14499C27.9303 10.6859 29.1249 13.1098 29.2991 17.0001H29C28.4477 17.0001 28 17.4478 28 18.0001V28.0001C28 28.5523 28.4477 29.0001 29 29.0001H29.5522C29.2381 29.4387 28.8432 29.8812 28.3679 30.2624C27.5092 30.9511 26.3904 31.4431 24.9488 31.3583C24.6417 29.4542 22.9907 28.0001 21 28.0001C18.7909 28.0001 17 29.7909 17 32.0001C17 34.2092 18.7909 36.0001 21 36.0001C22.5474 36.0001 23.8896 35.1214 24.5548 33.8358C26.7937 34.0362 28.5977 33.2829 29.9321 32.2127C31.1416 31.2426 31.9662 30.0164 32.4372 29.0001H33C34.1046 29.0001 35 28.1046 35 27.0001V19.0001C35 17.8955 34.1046 17.0001 33 17.0001H31.8013C31.6229 12.5651 30.2494 9.47317 28.1738 7.3833C25.8909 5.08467 22.679 4.02997 19.5595 4.11021C16.4399 4.19045 13.2867 5.40891 11.1261 7.82337C8.58579 10.662 7.71502 14.3378 7.75151 17.0001H7C5.89543 17.0001 5 17.8955 5 19.0001V27.0001C5 28.1046 5.89543 29.0001 7 29.0001H11C11.5523 29.0001 12 28.5523 12 28.0001V18.0001C12 17.4478 11.5523 17.0001 11 17.0001H10.2511C10.2122 14.8818 10.9148 11.8084 12.989 9.49051ZM7.5 26.5001V19.5001H9.5V26.5001H7.5ZM21 33.5001C21.8284 33.5001 22.5 32.8285 22.5 32.0001C22.5 31.1716 21.8284 30.5001 21 30.5001C20.1716 30.5001 19.5 31.1716 19.5 32.0001C19.5 32.8285 20.1716 33.5001 21 33.5001ZM32.5 19.5001V26.5001H30.5V19.5001H32.5Z"
        fill={color}
      />
    </svg>
  )
}
