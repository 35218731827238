import React, { forwardRef, ForwardedRef } from 'react'
import styles from '_revamp/styles/atoms/input.module.scss'
import { PropsInput } from '_revamp/utils/types'
import clsx from 'clsx'

const forwardInput = (
  {
    value,
    placeholder,
    onChange,
    isError,
    message,
    disabled,
    title,
    dataTestId,
    className,
    ...props
  }: PropsInput,
  ref?: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.titleText}>{title}</p>
      <input
        ref={ref}
        data-test-id={dataTestId}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={clsx({
          [styles.input]: true,
          [styles.error]: isError,
          ['shake-animation-X']: isError,
          ...(className && [className]),
        })}
        placeholder={placeholder}
        autoComplete="off"
        {...props}
      />
      {isError && message && (
        <span className={styles.errorText}>{message}</span>
      )}
    </div>
  )
}

export default forwardRef(forwardInput)
